import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import AssassinCard from "../components/AssassinCard"
import Coffee from "../components/Coffee"

const TierList = () => {

  // Let's hook our filters up to the state
  const [classFilter, setClassFilter] = useState("All");

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );
  assassins.sort(function (a, b) {
    return b.score - a.score || b.assassin_name - a.assassin_name;
  });

  // Define our gear filter categories
  const filterClassValues = [
    "1", "1-1", "1-2", "1-3",
    "2", "2-1", "2-2", "2-3",
    "3", "3-1", "3-2", "3-3"
  ]
  const filterClassNames = [
    "class1", "subclass1", "subclass2", "subclass3",
    "class2", "subclass4", "subclass5", "subclass6",
    "class3", "subclass7", "subclass8", "subclass9"
  ]

  // Create our class filter tabs
  var filterTabs = []
  for (let i = 0; i < filterClassValues.length; i++) {
    filterTabs.push(
      <div key={"tab." + i}
        onClick={() => setClassFilter(filterClassValues[i])}
        className={"subclass "+filterClassNames[i]+(classFilter === filterClassValues[i] ? " on" : "")}>
      </div>
    )
  }

  const tiers = ['S', 'A', 'B', 'C', 'D', 'TBC']
  var theTierList = []

  var filterPrimaryClass = classFilter.substring(0, 1)
  var filterSubClass = classFilter.substring(2, 3)

  for (let t = 0; t < 6; t++) {

    var tierCount = 0
    var tierContent = []

    theTierList.push(
      <div className="tierHeader" key={"tier." + tiers[t]}>
        {tiers[t]}
      </div>
    )

    for (let a = 0; a < assassins.length; a++) {

      // Apply our filters
      if (
        (assassins[a].available === 1 && assassins[a].tier === tiers[t]) &&
        (classFilter === "All" ||
          (parseInt(filterPrimaryClass) === assassins[a].primaryclass &&
            (filterSubClass === "" || parseInt(filterSubClass) === assassins[a].subclass)
          )
        )
      ) {

        tierContent.push(
          <AssassinCard id={assassins[a].id} tier={assassins[a].tier} key={assassins[a].id} />
        );
        tierCount++;
      }
    }

    if (tierCount > 0) {
      theTierList.push(
        <div key={"d." + t}>
          {tierContent}
        </div>
      )
    }

    if (tierCount === 0) {
      theTierList.push(
        <div className="emptyTier" data-empty={tiers[t]} key={"tier.empty." + tiers[t]}>
          None
        </div>
      );
    }
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Assassin Tier List</title>
      </Helmet>

      <h1>Assassin Tier List</h1>

      <div className="filter">
        <div data-filter="All" 
          onClick={() => setClassFilter("All")}
          className={(classFilter === "All" ? "on" : "")}>All</div>
        {filterTabs}
      </div>

      <div className="directory tierList">
        {theTierList}
      </div>

      <Coffee />
      
    </div>
  )
}

export default TierList;