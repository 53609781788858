import {
    ts, friendlyViewtime
} from "../Utils.js"

const RUN_CLOCK = "RUN_CLOCK"
const CALC_GAUNTLET_OUTCOMES = "CALC_GAUNTLET_OUTCOMES"

export function runClock(startTime, endTime) {

    var now = new Date();
    var nowTime = Math.round(now.getTime() / 1000);
    var secondsRemaining = endTime - startTime;
    var displayTime = "Event Inactive";

    if (nowTime >= startTime && nowTime <= endTime) {
        secondsRemaining = endTime - nowTime;
        displayTime = friendlyViewtime(secondsRemaining) + " remaining";
    }

    var clock = {
        secondsRemaining: secondsRemaining,
        displayTime: displayTime
    }
    return { type: RUN_CLOCK, payload: clock }
}

export function gauntletCalculate(event, assassins, gauntlet) {

    const endTime = ts(event.end_date);

    const difficulties = ['Novice', 'Expert', 'Grandmaster'];

    // Get the goal
    var selectedGoal = document.querySelector(".goal.selected")
    if (selectedGoal) {
        var goal = selectedGoal.getAttribute("data-goal")
    }

    // Get the number of sync heroes
    var selectedHeroes = document.querySelectorAll(".gauntletSyncHero.active")
    var numSyncHeroes = selectedHeroes.length
    if (numSyncHeroes > 3) {
        numSyncHeroes = 3
    }

    // Get the mission goals
    var missionGoal = {
        'Novice': parseInt(document.querySelector("input[name=noviceMissions]").value),
        'Expert': parseInt(document.querySelector("input[name=expertMissions]").value),
        'Grandmaster': parseInt(document.querySelector("input[name=gmMissions]").value)
    }
    
    // Premium?
    var premiumToggle = document.querySelector(".premiumSwitch");
    var premium = false;
    if (premiumToggle.checked) {
        premium = true;
    }

    // Already Joined?
    var joinedToggle = document.querySelector(".joinedSwitch");
    var alreadyJoined = false;
    if (joinedToggle.checked) {
        alreadyJoined = true;
    }

    // Tokens in hand
    var tokensInHandInput = document.querySelector("input[name=tokens]");
    var tokensInHand = parseInt(tokensInHandInput.value);

    // Current score
    var currentScoreInput = document.querySelector("input[name=nodes]");
    var currentScore = parseInt(currentScoreInput.value);

    // Join time
    var joinTimeInput = document.querySelector("input[name=joinTime]");
    var hoursRemaining = parseInt(joinTimeInput.value);
    var joinTime = endTime - (3600 * hoursRemaining) + 60;

    var milliseconds = joinTime * 1000;
    var dateObject = new Date(milliseconds);

    var joinDateTime = dateObject.toLocaleString("en-US", { weekday: "long" }) +
        " at " + dateObject.toLocaleString("en-US", { hour: "numeric" });
    var hours = "hours";
    if (hoursRemaining === 1) {
        hours = 'hour';
    }
    var joinHoursRemaining = hoursRemaining + " " + hours + " remaining";

    // Plan our missions
    var outcomes = {
        nodes: {
            numDailyRewards: 0,
            dailyRewardNodes: 0,
            refills: [{
                refillNum: 0,
                farmMission: 0,
                farmRuns: 0,
                farmTokens: 0,
                farmNodes: 0,
                farmCubeTokens: 0,
                remainderMission: 0,
                remainderMissionTokens: 0,
                remainderMissionNodes: 0,
                remainderMissionCubeTokens: 0,
                totalNodes: 0,
                totalCubeTokens: 0
            }]
        }
    }

    var efficiency = {
        'Novice': {
            cost: 50,
            totalNodes: 0,
            totalCubeTokens: 0,
            nodesPerToken: 0,
            cubeTokensPerToken: 0
        },
        'Expert': {
            cost: 75,
            totalNodes: 0,
            totalCubeTokens: 0,
            nodesPerToken: 0,
            cubeTokensPerToken: 0
        },
        'Grandmaster': {
            cost: 100,
            totalNodes: 0,
            totalCubeTokens: 0,
            nodesPerToken: 0,
            cubeTokensPerToken: 0
        }
    }

    // For each of our difficulties
    for (var d = 0; d < 3; d++) {

        var thisDifficulty = difficulties[d]
        var totalScore = 0
        var totalCubeTokens = 0

        // For each of our missions
        for (var i = 0; i < missionGoal[thisDifficulty]; i++) {
            totalScore += gauntlet[thisDifficulty][i].nodes + (gauntlet[thisDifficulty][i].nodes * (numSyncHeroes * 0.4))
            totalCubeTokens += gauntlet[thisDifficulty][i].cubetokens
        }

        efficiency[thisDifficulty]['totalNodes'] = totalScore
        efficiency[thisDifficulty]['totalCubeTokens'] = totalCubeTokens
        efficiency[thisDifficulty]['nodesPerToken'] = totalScore / efficiency[thisDifficulty]['cost']
        efficiency[thisDifficulty]['cubeTokensPerToken'] = totalCubeTokens / efficiency[thisDifficulty]['cost']
    }

    // Outcomes! (calculateOutcomes)
    var remainingTime = parseInt(endTime) - parseInt(joinTime);
    if (alreadyJoined === true) {
        remainingTime = parseInt(endTime) - Math.floor(Date.now() / 1000);
    }

    // Figure out recharge rate
    var rechargeRatePerSec = 100 / 86400;
    if (premium === true) {
        rechargeRatePerSec = 120 / 86400;
    }

    // Let's figure out which difficulty is best for nodes
    var bestTier = 'Novice'
    if (efficiency['Expert']['nodesPerToken'] > efficiency[bestTier]['nodesPerToken']) {
        bestTier = 'Expert'
    }
    if (efficiency['Grandmaster']['nodesPerToken'] > efficiency[bestTier]['nodesPerToken']) {
        bestTier = 'Grandmaster'
    }

    if (goal === 'cubeTokens') {
        // Let's figure out which difficulty is best for cube tokens
        bestTier = 'Novice'
        if (efficiency['Expert']['cubeTokensPerToken'] > efficiency[bestTier]['cubeTokensPerToken']) {
            bestTier = 'Expert'
        }
        if (efficiency['Grandmaster']['cubeTokensPerToken'] > efficiency[bestTier]['cubeTokensPerToken']) {
            bestTier = 'Grandmaster'
        }
    }

    // Now for refills!
    for (var r = 0; r <= 5; r++) {

        // Let's figure out how many tokens we have to play with
        var tokensToCome = Math.floor(rechargeRatePerSec * remainingTime);
        var tokensForThisRefill = parseInt(tokensInHand) + parseInt(tokensToCome) + (r * 100);
        var tokensRemainingThisRefill = tokensForThisRefill;

        var farmRuns = Math.floor(tokensRemainingThisRefill / efficiency[bestTier]['cost'])
        var farmTokens = farmRuns * efficiency[bestTier]['cost']
        var farmNodes = efficiency[bestTier]['totalNodes'] * farmRuns
        var farmCubeTokens = efficiency[bestTier]['totalCubeTokens'] * farmRuns
        tokensRemainingThisRefill -= farmTokens

        var remainderMission = '', remainderMissionTokens = 0,
        remainderMissionNodes = 0, remainderMissionCubeTokens = 0

        // Any leftovers?
        if (tokensRemainingThisRefill >= 50) {
            remainderMission = 'Novice'
            if (efficiency['Expert']['nodesPerToken'] > efficiency['Novice']['nodesPerToken'] &&
                efficiency['Expert']['cost'] <= tokensRemainingThisRefill) {
                remainderMission = 'Expert'
            }
            if (goal === 'cubeTokens') {
                remainderMission = 'Novice'
                if (efficiency['Expert']['cubeTokensPerToken'] > efficiency['Novice']['cubeTokensPerToken'] &&
                    efficiency['Expert']['cost'] <= tokensRemainingThisRefill) {
                    remainderMission = 'Expert'
                }
            }
            remainderMissionTokens = efficiency[remainderMission]['cost']
            remainderMissionNodes = efficiency[remainderMission]['totalNodes']
            remainderMissionCubeTokens = efficiency[remainderMission]['totalCubeTokens']
        }
        var totalNodes = currentScore + farmNodes + remainderMissionNodes
        totalCubeTokens = farmCubeTokens + remainderMissionCubeTokens

        remainingTime = parseInt(endTime) - parseInt(joinTime);
        if (alreadyJoined === true) {
            remainingTime = parseInt(endTime) - Math.floor(Date.now() / 1000);
        }

        outcomes.nodes.refills[r] = {
            refillNum: r,
            farmMission: bestTier,
            farmRuns: farmRuns,
            farmTokens: farmTokens,
            farmNodes: farmNodes,
            farmCubeTokens: farmCubeTokens,
            remainderMission: remainderMission,
            remainderMissionTokens: remainderMissionTokens,
            remainderMissionNodes: remainderMissionNodes,
            remainderMissionCubeTokens: remainderMissionCubeTokens,
            totalNodes: totalNodes,
            totalCubeTokens: totalCubeTokens
        }
    }

    var calc = {
        goal: goal,
        bestTier: bestTier,
        alreadyJoined: alreadyJoined,
        joinDateTime: joinDateTime,
        joinHoursRemaining: joinHoursRemaining,
        outcomes: outcomes
    }

    return { type: CALC_GAUNTLET_OUTCOMES, payload: calc }
}