import React from "react"
import { number_format } from "../Utils";

const AssassinReview = (props) => {

  // Grab our stats from our props
  const assassin = props.assassin;
  const review = props.review;

  var pros = [];
  var cons = [];
  var proConBlock = [];

  if (review.pros) {

    for (var r = 0; r < review.pros.length; r++) {
      pros.push(
        <div className="procon pro" key={"pro." + r}>
          <i className="fas fa-plus-circle"></i> {review.pros[r]}
        </div>
      );
    }

    if (review.cons) {
      for (r = 0; r < review.cons.length; r++) {
        cons.push(
          <div className="procon con" key={"con." + r}>
            <i className="fas fa-minus-circle"></i> {review.cons[r]}
          </div>
        );
      }
    }

    proConBlock.push(
      <div key="proConBlock">
        <div className="row">
          <div><h3>Pros</h3></div>
          <div><h3>Cons</h3></div>
        </div>
        <div className="row procons">
          <div className="col pros">
            {pros}
          </div>
          <div className="col cons">
            {cons}
          </div>
        </div>
        <h3>Verdict</h3>
        <div className="verdict">{(!review.verdict ? "" : review.verdict[0])}</div>
      </div>
    );

  }

  return (
    <div className="assassinReview">
      <h2>Assassin Review</h2>
      {proConBlock}
      <div className="row ratings">
        <div className={"judgeScore " + assassin.tier.toLowerCase()}>
          {number_format(assassin.score, 1)}
          <div className="label themeLabelY">Rating</div>
        </div>
        <div className={"tierRating " + assassin.tier.toLowerCase()}>
          {assassin.tier}
          <div className="label themeLabelY">Tier</div>
        </div>
      </div>
    </div>
  )
}

export default AssassinReview