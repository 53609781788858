import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { gd, ts, ordinal, number_format, lookup, apiConfig } from "../Utils.js"
import { runClock, gauntletCalculate } from "../actions/gauntletcalc"
import Coffee from "../components/Coffee"
import Holding from "../components/Holding"

const axios = require('axios');

const GauntletCalculator = () => {

  const dispatch = useDispatch();

  // Grab our calc stuff from the store
  let calc = useSelector(
    state => state.gauntletcalc
  );

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );

  // Get our params
  const params = useParams();
  var eventId = parseInt(params.id);

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  // const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [gauntlet, setGauntlet] = useState({});
  const [noviceMissionGoal, setNoviceMissionGoal] = useState(10);
  const [expertMissionGoal, setExpertMissionGoal] = useState(10);
  const [gmMissionGoal, setGmMissionGoal] = useState(7);

  // Sort out our date range
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Set up our dates
  var startTime = 0;
  var endTime = 0;
  var secondsRemaining = endTime - startTime;
  var dateRange = "";

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_API_URL}api/events`, apiConfig)
      .then(function (response) {
        // setEvents(response.data);

        if (!eventId) {
          for (var e = response.data.length - 1; e > 0; e--) {
            if (ts(response.data[e].end_date) > Math.floor(Date.now() / 1000)) {
              var newEventId = response.data[e].id;
            } else {
              break;
            }
          }
        }
        var lookupEventId = (newEventId ? newEventId : eventId);

        return axios.get(`${process.env.REACT_APP_API_URL}api/event/${lookupEventId}`, apiConfig)
      })
      .then(function (response) {
        setEvent(response.data);
        setLoading(false);
        if (event.id) {
          dispatch(gauntletCalculate(event, assassins, gauntlet))
        }
      });

    axios.get(`${process.env.REACT_APP_API_URL}api/gauntlet`, apiConfig)
      .then(function (response) {
        setGauntlet(response.data);
        if (event.id) {
          dispatch(gauntletCalculate(event, assassins, gauntlet))
        }
      });

    // Start the clock
    if (event.id) {

      var now = new Date();
      var nowTime = Math.round(now.getTime() / 1000);
      if (nowTime < endTime) {
        setTimer()
      }
      dispatch(gauntletCalculate(event, assassins, gauntlet))
    }

  }, [])

  // Figure out our current event
  // var currentEventId = 0;
  // if (events.length > 0) {
  //   for (var e = events.length - 1; e > 0; e--) {
  //     if (ts(events[e].end_date) > Math.floor(Date.now() / 1000)) {
  //       currentEventId = events[e].id;
  //     } else {
  //       break;
  //     }
  //   }
  // }

  // Get our event details
  if (event && typeof event.start_date !== 'undefined') {

    startTime = ts(event.start_date);
    endTime = ts(event.end_date);
    secondsRemaining = endTime - startTime;

    const dStart = gd(event.start_date);
    const dEnd = gd(event.end_date);
    dateRange = monthNames[dStart.getMonth()] + " " +
      dStart.getDate() + ordinal(dStart.getDate()) + " - " +
      monthNames[dEnd.getMonth()] + " " +
      dEnd.getDate() + ordinal(dEnd.getDate());
  }

  // Set up our join time stuff
  if (event) {
    // var milliseconds = startTime * 1000;
    // var dateObject = new Date(milliseconds);
    var now = new Date();
    var nowTime = Math.round(now.getTime() / 1000);
    // var joinTime = startTime;
    if (nowTime > startTime && nowTime < endTime) {
      secondsRemaining = endTime - nowTime;
      var maxHours = Math.ceil(secondsRemaining / 3600);
      // joinTime = endTime - (maxHours * 3600);
      // dateObject = new Date(joinTime * 1000);
    }
  }

  // Tokens in hand
  var tokensInHand = 100;
  if (!event.fhc) {
    tokensInHand = 250;
  }
  if ((endTime - startTime) > (86400 * 3)) {
    tokensInHand = 400;
  }

  // Set the timer
  function setTimer() {
    setInterval(() => {
      dispatch(runClock(startTime, endTime))
    }, 100)
  }

  function changeGoal(goal) {

    // Clear down the selected option
    var allGoals = document.querySelectorAll(".goal");
    if (allGoals) {
      for (var i = 0; i < allGoals.length; i++) {
        allGoals[i].classList.remove("selected");
        var allGoalsRadios = allGoals[i].querySelector(".checked");
        allGoalsRadios.classList.add("hidden");
      }
    }

    // Check our desired goal
    var goalContainer = document.querySelector(".goal." + goal);
    if (goalContainer) {
      goalContainer.classList.add("selected");
      var goalRadio = goalContainer.querySelector(".checked");
      goalRadio.classList.remove("hidden");
    }

    dispatch(gauntletCalculate(event, assassins, gauntlet))
  }

  function changeMissionGoal() {
    var novice = parseInt(document.querySelector("input[name=noviceMissions]").value);
    var expert = parseInt(document.querySelector("input[name=expertMissions]").value);
    var gm = parseInt(document.querySelector("input[name=gmMissions]").value);
    setNoviceMissionGoal(novice);
    setExpertMissionGoal(expert);
    setGmMissionGoal(gm);

    dispatch(gauntletCalculate(event, assassins, gauntlet))
  }

  function updateSyncHeroes(heroId) {
    const clickedHero = document.querySelector(".gauntletSyncHero[data-hero='" + heroId + "']")
    if (clickedHero.classList.contains('active')) {
      clickedHero.classList.remove('active')
    } else {
      clickedHero.classList.add('active')
    }
    dispatch(gauntletCalculate(event, assassins, gauntlet))
  }

  function changeTier(tier) {
    var allTiers = document.querySelectorAll(".tabs .tab");
    if (allTiers) {
      for (var i = 0; i < allTiers.length; i++) {
        allTiers[i].classList.remove("on");
        if (i === parseInt(tier)) {
          allTiers[i].classList.add("on");
        }
      }
    }
    dispatch(gauntletCalculate(event, assassins, gauntlet))
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  // if (eventId > currentEventId) {

  //   return (
  //     <div className="eventContainer">

  //       <Helmet>
  //         <title>Assassin's Creed Rebellion &#47;&#47; Abstergo Gauntlet Calculator</title>
  //       </Helmet>

  //       <h1>Data available soon</h1>

  //       <p>Check back soon for event calculator!</p>

  //     </div>
  //   );

  // } else {

    maxHours = Math.ceil(secondsRemaining / 3600);

    var joinTimeClass = "";
    var initialRunCompletedClass = " hidden";
    if (calc.alreadyJoined === true) {
      joinTimeClass = " hidden";
      initialRunCompletedClass = "";
    }

    // Goals
    var goals = [];
    goals.push(
      <div className="col goal selected nodes gauntlet-nodes" key="goalnodes"
        data-goal="nodes" onClick={() => changeGoal('nodes')}>
        <div className="item gauntlet-nodes"></div>
        <div className="goalName">Nodes</div>
        <div className="radio"><div className="checked"></div></div>
      </div>
    );
    goals.push(
      <div className="col goal cubeTokens" key="goalfhc"
        data-goal="cubeTokens" onClick={() => changeGoal('cubeTokens')}>
        <div className="item gauntlet-cube-tokens"></div>
        <div className="goalName">Cubes</div>
        <div className="radio"><div className="checked hidden"></div></div>
      </div>
    );
    var maximise = 'score';
    if (calc.goal === 'cubeTokens') {
      maximise = 'cube tokens';
    }

    // Heroes
    var syncBonusHeroes = []
    for (let h = 1; h <= 4; h++) {

      let thisSyncHeroNum = 'sync_hero_' + h;

      // Only continue if we have a sync hero (think rift challenges)
      if (parseInt(event[thisSyncHeroNum]) !== 0) {

        let hero = lookup(assassins, 'id', parseInt(event[thisSyncHeroNum]))

        // Class icon
        var subclassName = "subclass class" + hero.primaryclass + "sub" + hero.subclass;

        syncBonusHeroes.push(
          <div className="gauntletSyncHero"
            key={"hero" + hero.id}
            data-hero={hero.id}
            onClick={() => updateSyncHeroes(hero.id)}>
            <div className={"heroContainer " + hero.rarity_name}>
              <div className={"hero " + hero.assassin_name_safe}></div>
              <div className="assassinName themeLabelY">{hero.assassin_name}</div>
              <div className={subclassName}></div>
            </div>
          </div>
        )
      }
    }

    // Mission tier tabs
    var theTabs = [];
    var tierNames = ["Auto", "Novice", "Expert", "Grandmaster"];
    for (let i = 0; i < 6; i++) {
      var theClassName = "levelTab tab";
      if (i === 0) {
        theClassName += " on";
      }
      /*if (i === parseInt(calc.bestTier)) {
          theClassName += " chosen";
      }*/
      theTabs.push(
        <div className={theClassName} key={"tab." + i} data-tier={i}
          onClick={() => changeTier(i)}>{tierNames[i]}</div>
      );
    }

    // Outcome classes
    var nodesClass = "";
    if (calc.goal !== 'nodes') {
      nodesClass = " hidden";
    }
    var cubeTokensClass = "";
    if (calc.goal !== 'cubeTokens') {
      cubeTokensClass = " hidden";
    }

    // Context menu
    const contextMenu = [];
    var eventLink = "/abstergo-gauntlet/" + event.id;
    contextMenu.push(
      <Link to={eventLink} key="calc">
        <div className="calc">
          <i className="fas fa-trophy"></i>
        </div>
      </Link>
    );

    return (
      <div className="calculator directory">

        <Helmet>
          <title>Assassin's Creed Rebellion &#47;&#47; Abstergo Gauntlet Calculator &#47;&#47; {event.event_name}</title>
        </Helmet>

        <h1>Abstergo Gauntlet Calculator</h1>

        <h3>{event.event_name} &#47;&#47; {dateRange}</h3>
        <div className="timeRemaining placeHold">{calc.displayTime}</div>

        <h2>Your Goal</h2>

        <div className="goals row">
          {goals}
        </div>

        <h2>Your Heroes</h2>

        <div className="gauntletSyncHeroes">
          {syncBonusHeroes}
        </div>

        <h2>What Missions Can You Beat?</h2>

        <div className="missionSliders scenario">

          <div className="row">

            <span>Novice: <span className="noviceMissionGoal">{noviceMissionGoal}</span></span>
          
          </div>

          <div className="row">

            <input type="range" name="noviceMissions" min="0" max="10"
              defaultValue="10" onChange={() => changeMissionGoal()} />

          </div>

          <div className="row">

            <span>Expert: <span className="expertMissionGoal">{expertMissionGoal}</span></span>

          </div>

          <div className="row">

            <input type="range" name="expertMissions" min="0" max="10"
              defaultValue="10" onChange={() => changeMissionGoal()} />

          </div>

          <div className="row">

            <span>Grandmaster: <span className="gmMissionGoal">{gmMissionGoal}</span></span>

          </div>

          <div className="row">

            <input type="range" name="gmMissions" min="0" max="10"
              defaultValue="7" onChange={() => changeMissionGoal()} />

          </div>

        </div>

        <div className="optimumDifficulty">

          To maximise your {maximise} you should run

          <div className="bestTier">{calc.bestTier}</div>

        </div>

        <h2>Your Scenario</h2>

        <div className="scenario">

          <div className="row">

            <div className="premium col">

              <div className="toggle">
                <div className="label">Premium?</div>
                <div className="switchBox">
                  <input type="checkbox" className="switch premiumSwitch" value="1"
                    onChange={() => dispatch(gauntletCalculate(event, assassins, gauntlet))} />
                </div>
              </div>

            </div>

            <div className="joined col">

              <div className="toggle">
                <div className="label">Already Joined?</div>
                <div className="switchBox">
                  <input type="checkbox" className="switch joinedSwitch" value="1"
                    onChange={() => dispatch(gauntletCalculate(event, assassins, gauntlet))} />
                </div>
              </div>

            </div>

          </div>

          <div className="row">

            <div className="tokensInHand col">
              <div className="label">Tokens in hand</div>
              <input className="textInput" type="text" name="tokens" size="4"
                defaultValue={tokensInHand}
                onChange={() => dispatch(gauntletCalculate(event, assassins, gauntlet))} />
            </div>

            <div className={"currentScore col" + initialRunCompletedClass}>
              <div className="label">Current Score</div>
              <input className="textInput" type="text" name="nodes"
                defaultValue="0" size="10"
                onChange={() => dispatch(gauntletCalculate(event, assassins, gauntlet))} />
            </div>

          </div>

          <div className={"join" + joinTimeClass}>

            <h2>When do you plan on joining?</h2>

            <input type="range" name="joinTime" min="1" max={maxHours}
              defaultValue={maxHours} onChange={() => dispatch(gauntletCalculate(event, assassins, gauntlet))} />

            <div className="joinTimeDisplay">
              <div className="time">{calc.joinDateTime}</div>
              <div className="rem">{calc.joinHoursRemaining}</div>
            </div>

          </div>

        </div>

        <h2>Outcomes</h2>

        <div id="outcome">

          {calc.outcomes.nodes.refills.map(outcome => {
            var plural = "s";
            if (outcome.refillNum === 1) {
              plural = "";
            }
            var remainderStyle = "";
            if (outcome.remainderMission === '') {
              remainderStyle = " hidden";
            }
            return (
              <div className="outcome" key={"outcome." + outcome.refillNum}>
                <h2>{outcome.refillNum} Refill{plural}</h2>
                <div className={"row"}>
                  <div className="col description">
                    Run {outcome.farmMission} difficulty {outcome.farmRuns}x
                  </div>
                  <div className="break-xs"></div>
                  <div className="col tokens">
                    <div className="label">
                      <div className="item rift-tokens"></div>
                    </div>
                    <div className="value">{outcome.farmTokens}</div>
                  </div>
                  <div className={"col nodes" + nodesClass}>
                    <div className="label">
                      <div className="item gauntlet-nodes"></div>
                    </div>
                    <div className="value">{number_format(outcome.farmNodes)}</div>
                  </div>
                  <div className={"col cubeTokens" + cubeTokensClass}>
                    <div className="label">
                      <div className="item gauntlet-cube-tokens"></div>
                    </div>
                    <div className="value">{number_format(outcome.farmCubeTokens)}</div>
                  </div>
                </div>
                <div className={"row" + remainderStyle}>
                  <div className="break-xs"></div>
                  <div className="col description">
                    Run {outcome.remainderMission} difficulty once
                  </div>
                  <div className="col tokens">
                    <div className="label">
                      <div className="item rift-tokens"></div>
                    </div>
                    <div className="value">{outcome.remainderMissionTokens}</div>
                  </div>
                  <div className={"col nodes" + nodesClass}>
                    <div className="label">
                      <div className="item gauntlet-nodes"></div>
                    </div>
                    <div className="value">{number_format(outcome.remainderMissionNodes)}</div>
                  </div>
                  <div className={"col cubeTokens" + cubeTokensClass}>
                    <div className="label">
                      <div className="item gauntlet-cube-tokens"></div>
                    </div>
                    <div className="value">{number_format(outcome.remainderMissionCubeTokens)}</div>
                  </div>
                </div>
                <div className={"refillScore gauntlet" + nodesClass}>
                  <div className="item gauntlet-nodes"></div>
                  <div className="value">{number_format(outcome.totalNodes)}</div>
                </div>
                <div className={"refillScore gauntlet" + cubeTokensClass}>
                  <div className="item gauntlet-cube-tokens"></div>
                  <div className="value">{number_format(outcome.totalCubeTokens)}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="contextMenu">{contextMenu}</div>

        <Coffee />

      </div>
    )
  // }
}

export default GauntletCalculator;