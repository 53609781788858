import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import Holding from "../components/Holding"
import Coffee from "../components/Coffee"
import { apiConfig } from "../Utils.js"

const axios = require('axios');

const GearIndex = () => {

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [gear, setGear] = useState({});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/gear`, apiConfig)
      .then(function (response) {
        setGear(response.data);
        setLoading(false);
      });
  }, [])

  // Let's hook our filters up to the state
  const [gearTypeFilter, setGearTypeFilter] = useState("All");
  const [gearStarFilter, setGearStarFilter] = useState("All");

  // Define our gear filter categories
  const filterCategories = [
    { displayName: 'Sword', safeName: 'sword' },
    { displayName: 'Short Blade', safeName: 'short-blade' },
    { displayName: 'Hammer', safeName: 'hammer' },
    { displayName: 'Polearm', safeName: 'polearm' },
    { displayName: 'Large Sword', safeName: 'large-sword' },
    { displayName: 'Axe', safeName: 'axe' },
    { displayName: 'Hidden Blades', safeName: 'hidden-blades' },
    { displayName: 'Heavy', safeName: 'heavy' },
    { displayName: 'Medium', safeName: 'medium' },
    { displayName: 'Light', safeName: 'light' },
    { displayName: 'Belt', safeName: 'belt' },
    { displayName: 'Boots', safeName: 'boots' },
    { displayName: 'Pouch', safeName: 'pouch' }
  ]

  // Create our category filter tabs
  var gearCategoryTabs = []
  for (let i = 0; i < filterCategories.length; i++) {

    // Figure out if this is the active tab
    var categoryTabActive = (gearTypeFilter === filterCategories[i].displayName ? " on" : "");

    // Add it to our tab array
    gearCategoryTabs.push(
      <div key={"gc." + i}
        className={"gearCategory " + filterCategories[i].safeName + categoryTabActive}
        onClick={() => setGearTypeFilter(filterCategories[i].displayName)}></div>
    )
  }

  // Create our star filter tabs
  var gearStarTabs = []
  for (let i = 1; i <= 5; i++) {

    // Figure out if this is the active tab
    var starTabActive = (gearStarFilter === i ? " on" : "");

    gearStarTabs.push(
      <div key={"s." + i}
        className={"gearRank" + starTabActive}
        onClick={() => setGearStarFilter(i)}>{i} <i className="fas fa-star"></i></div>
    )
  }

  var theList = [];
  for (var i = 0; i < gear.length; i++) {

    // Apply our filters
    if ((gearTypeFilter === "All" || gearTypeFilter === gear[i].sub_type)
      && (gearStarFilter === "All" || gearStarFilter === gear[i].rank)) {

      // Class icon
      var typeClass = gear[i].type.toLowerCase();
      var stars = [];
      for (var s = 1; s <= parseInt(gear[i].rank); s++) {
        stars.push(<i className="fas fa-star" key={"star" + s}></i>);
      }

      theList.push(
        <Link to={"/gear/" + gear[i].item_id + "/" + gear[i].name_safe} key={i}>
          <div className={"gearContainer"} data-type={gear[i].sub_type} data-rank={gear[i].rank}>
            <div className={typeClass + " " + gear[i].name_safe}></div>
            <div className="stars">{stars}</div>
          </div>
        </Link>
      );
    }
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Gear Directory</title>
      </Helmet>

      <h1>Gear</h1>

      <div className="filter filterCategory">
        <div className={(gearTypeFilter === "All" ? "on" : "")}
          onClick={() => setGearTypeFilter("All")}>All</div>
        {gearCategoryTabs}
      </div>
      <div className="filter filterRank">
        <div className={(gearStarFilter === "All" ? "on" : "")}
          onClick={() => setGearStarFilter("All")}>All</div>
        {gearStarTabs}
      </div>
      <div className="directory">
        {theList}
      </div>
      <Coffee />
    </div>
  )
}

export default GearIndex;