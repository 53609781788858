const RUN_CLOCK = "RUN_CLOCK"
const CALC_GAUNTLET_OUTCOMES = "CALC_GAUNTLET_OUTCOMES"

var outcomes = {
  nodes: {
    numDailyRewards: 0,
    dailyRewardNodes: 0,
    refills: [{
      refillNum: 0,
      farmMission: 0,
      farmRuns: 0,
      farmTokens: 0,
      farmNodes: 0,
      farmCubeTokens: 0,
      remainderMission: 0,
      remainderMissionTokens: 0,
      remainderMissionNodes: 0,
      remainderMissionCubeTokens: 0,
      totalNodes: 0,
      totalCubeTokens: 0
    }]
  }
}

var gauntletcalc = {
  goal: "nodes",
  bestTier: 0,
  alreadyJoined: false,
  secondsRemaining: 0,
  displayTime: "",
  joinDateTime: 0,
  joinHoursRemaining: 0,
  outcomes: outcomes
}

function gauntletcalcReducer(state = gauntletcalc, action) {
  if (action.type === RUN_CLOCK) {
    return {
      ...state,
      secondsRemaining: action.payload.secondsRemaining,
      displayTime: action.payload.displayTime
    }
  }
  if (action.type === CALC_GAUNTLET_OUTCOMES) {
    return {
      ...state,
      goal: action.payload.goal,
      bestTier: action.payload.bestTier,
      alreadyJoined: action.payload.alreadyJoined,
      joinDateTime: action.payload.joinDateTime,
      joinHoursRemaining: action.payload.joinHoursRemaining,
      outcomes: action.payload.outcomes
    }
  }
  return state
}

export default gauntletcalcReducer