const SR_CHANGE_STARS = "SR_CHANGE_STARS"
const SR_CHANGE_LEVEL = "SR_CHANGE_LEVEL"
const SR_CHANGE_STAT = "SR_CHANGE_STAT"

var statrankings = {
  theStat: "health",
  statTitle: "Health",
  stars: 1,
  level: 1,
  theList: []
}

function statrankingsReducer(state = statrankings, action) {
  if (action.type === SR_CHANGE_STARS) {
    return {
      ...state,
      stars: action.stars
    }
  }
  if (action.type === SR_CHANGE_LEVEL) {
    return {
      ...state,
      level: action.level
    }
  }
  if (action.type === SR_CHANGE_STAT) {
    return {
      ...state,
      theStat: action.stat.theStat,
      statTitle: action.stat.statTitle
    }
  }
  return state
}

export default statrankingsReducer