import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { gd, ts, lookup, safe, apiConfig } from "../Utils.js"
import Loading from "../components/Loading"

const axios = require('axios');

const apiEvents = axios.get(`${process.env.REACT_APP_API_URL}api/events/future`, apiConfig);
const apiChallenges = axios.get(`${process.env.REACT_APP_API_URL}api/challenges/future`, apiConfig);

const Calendar = () => {

  // Grab our assassin from our store
  const assassins = useSelector(
    state => state.assassins
  );

  // Set up our 2-second effect token
  const now = new Date()
  var twoSecToken = Math.round(now.getTime() / 1000)
  if (twoSecToken % 2 === 1) {
    twoSecToken++;
  }

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [challenges, setChallenges] = useState([]);

  const fetchData = () => {
    setLoading(true);
    axios.all([apiEvents, apiChallenges])
      .then(axios.spread((...responses) => {
        setEvents(responses[0].data);
        setChallenges(responses[1].data);
        setLoading(false);
      })).catch(errors => {
        // react on errors.
      });
  }

  useEffect(() => {
    fetchData();
  }, [twoSecToken]);

  function scrollCalendar(direction) {

    var container = document.querySelector(".calendarContainer");
    var days = document.querySelector(".days");
    var start = Math.floor(container.scrollLeft / dayWidth) * dayWidth;

    var containerWidth = container.offsetWidth;
    var daysWidth = days.offsetWidth;

    if (direction === "left") {
      var desiredDestination = start - 350;
      var endOfTheLine = 0;
      if (desiredDestination < endOfTheLine) {
        desiredDestination = endOfTheLine;
      }
    }
    if (direction === "right") {
      desiredDestination = start + 350;
      endOfTheLine = daysWidth - containerWidth;
      if (desiredDestination > endOfTheLine) {
        desiredDestination = endOfTheLine;
      }
    }

    container.scrollTo({
      'behavior': 'smooth',
      'left': desiredDestination,
      'top': 0
    });
  }

  const dayWidth = 80;

  // Set up our dates
  var startDate = '2020-01-01';
  var endDate = '2020-02-01';
  if (challenges.length > 0) {
    endDate = challenges[challenges.length - 1].end_date;
    for (var i = challenges.length - 1; i >= 0; i--) {
      if (ts(challenges[i].end_date) > Math.floor(Date.now() / 1000)) {
        startDate = challenges[i].start_date;
      } else {
        break;
      }
    }
  }

  // Establish our naming constants
  const dayNames = ["S", "M", "T", "W", "T", "F", "S"];

  // Print out our grid
  const calendarDays = [];
  const dayStyle = {
    width: dayWidth + "px"
  };
  if (challenges.length > 0) {
    for (var d = gd(startDate); d <= gd(endDate); d.setDate(d.getDate() + 1)) {

      calendarDays.push(
        <div className="dayColumn" key={d.getTime()} style={dayStyle}>
          <div className="date">
            <span className="dayName">{dayNames[d.getDay()]}</span>
            <span className="dayNum">{d.getDate()}</span>
          </div>
          <div className="divider-v"></div>
        </div>
      );
    }
  }

  var lastDay = gd(endDate);
  lastDay.setDate(lastDay.getDate() + 1)
  calendarDays.push(
    <div className="dayColumn" key={new Date(lastDay)}>
      <div className="date">
        <span className="dayName">{dayNames[lastDay.getDay()]}</span>
        <span className="dayNum">{lastDay.getDate()}</span>
      </div>
    </div>
  );

  // Prepare our events
  const calendarEvents = [];
  const calendarFHC = [];
  var thisStartDate = "";
  var thisEndDate = "";
  var daysOffset = 0;
  var leftOffset = 0;
  var timezoneOffset = new Date().getTimezoneOffset();
  var timezoneShift = (timezoneOffset / 1440) * 60;
  var daysLong = 0;
  var divWidth = 0;
  if (events.length > 0) {
    for (i = events.length - 1; i >= 0; i--) {

      if (ts(events[i].end_date) > ts(startDate)) {

        if (!events[i].hidden) {

          // Size and position event
          thisStartDate = ts(events[i].start_date);
          thisEndDate = ts(events[i].end_date);
          daysOffset = Math.ceil((thisStartDate - ts(startDate)) / 86400);
          leftOffset = (dayWidth * daysOffset) + (dayWidth / 2);
          leftOffset -= timezoneShift;
          daysLong = Math.ceil((thisEndDate - thisStartDate) / 86400);
          divWidth = dayWidth * daysLong;
          var divStyle = {
            width: divWidth + 'px',
            left: leftOffset + 'px',
          };

          // Leaderboard & progression heroes
          var leaderboardHero = lookup(assassins, 'id', events[i]['leaderboard_hero']);
          var progressionHero = lookup(assassins, 'id', events[i]['progression_hero']);

          // Unique key events
          var eventClassName = 'event ' + safe(events[i].event_name);
          var eventUrl = "helix-rift-event/" + events[i].id + "/" + safe(events[i].event_name);
          if (safe(events[i].event_name) === 'abstergo-gauntlet') {
            eventUrl = "abstergo-gauntlet/" + events[i].id
          }
          var linkKey = "link." + events[i].event_id;

          calendarEvents.push(
            <Link to={eventUrl} key={linkKey}>
              <div className={eventClassName} style={divStyle} key={events[i].event_id}>
                <div className="eventTitle themeLabelY">
                  {events[i].event_name}
                </div>
                <div className={`leaderboardHero heroContainer ${leaderboardHero.rarity_name}`} key={`lh ${i}`}>
                  <div className={`hero ${leaderboardHero.rarity_name} ${leaderboardHero.assassin_name_safe}`}></div>
                  <div className="label themeLabelX"><i className="fas fa-trophy"></i></div>
                </div>
                <div className={`progressionHero heroContainer ${progressionHero.rarity_name}`} key={`ph ${i}`}>
                  <div className={`hero ${progressionHero.rarity_name} ${progressionHero.assassin_name_safe}`}></div>
                  <div className="label themeLabelX">...</div>
                </div>
                <div className="button themeLabelX">View <i className="fas fa-angle-double-right"></i></div>
              </div>
            </Link>
          );

          // Let's also do FHC if we have them
          if (events[i].fhc) {

            const fhcStyle = {
              width: divWidth + 'px',
              left: leftOffset + 'px',
            };

            let fhcHero = lookup(assassins, 'id', parseInt(events[i].fhc))

            calendarFHC.push(
              <div className="fhc" style={fhcStyle} key={"fhc." + i}>
                {fhcHero.assassin_name}
                <div className="item hero-cube-tokens"></div>
              </div>
            );
          }
        }
      }
    }
  }

  // Prepare our challenges
  const calendarChallenges = [];
  if (challenges.length > 0) {
    for (i = challenges.length - 1; i >= 0; i--) {

      if (ts(challenges[i].end_date) > ts(startDate)) {

        // Size and position event
        thisStartDate = ts(challenges[i].start_date);
        thisEndDate = ts(challenges[i].end_date);
        daysOffset = Math.ceil((thisStartDate - ts(startDate)) / 86400);
        leftOffset = (dayWidth * daysOffset) + (dayWidth / 2);
        leftOffset -= timezoneShift;
        daysLong = Math.ceil((thisEndDate - thisStartDate) / 86400);
        divWidth = dayWidth * daysLong;
        divWidth--;
        divStyle = {
          width: divWidth + 'px',
          left: leftOffset + 'px',
        };

        // Unique key challenges
        var challengeClassName = 'challenge ' + safe(challenges[i].challenge_title);
        linkKey = "link." + challenges[i].challenge_id;

        var challengeUrl = "animus-challenge/" + challenges[i].id + "/" + safe(challenges[i].challenge_title);
        calendarChallenges.push(
          <Link to={challengeUrl} key={linkKey}>
            <div className={challengeClassName} style={divStyle} key={challenges[i].challenge_id}>
              <div className="challengeTitle">{challenges[i].challenge_title}</div>
              <div className="description">{challenges[i].challenge_description}</div>
            </div>
          </Link>
        );
      }
    }
  }

  // Figure out the total width of our calendar
  var numDays = calendarDays.length;
  var totalWidth = numDays * dayWidth;

  // Calculate our offset
  var daysSinceStart = (new Date() - new Date(startDate.substring(0, 10))) / 86400000;
  var nowOffset = daysSinceStart * dayWidth;
  nowOffset -= timezoneShift;

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <>
      <div className="calendarContainer">
        <div className="days" style={{ width: totalWidth + 'px' }}>
          {calendarDays}
        </div>
        <div className="events">
          {calendarEvents}
          {calendarFHC}
        </div>
        <div className="fhcs">
        </div>
        <div className="challenges">
          {calendarChallenges}
        </div>
        <div className="now" style={{ left: nowOffset + 'px' }}></div>
      </div>
      <div className="scroll left" onClick={() => scrollCalendar('left')}><i className="fas fa-angle-double-left"></i></div>
      <div className="scroll right" onClick={() => scrollCalendar('right')}><i className="fas fa-angle-double-right"></i></div>
    </>
  )
}

export default Calendar