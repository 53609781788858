import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const AssassinCard = (props) => {

  // Grab our assassin from our store
  const assassins = useSelector(
    state => state.assassins
  );
  const assassinObj = assassins.filter(obj => {
    return obj.id === props.id
  })
  const assassin = assassinObj[0];

  // Sort out the class/subclass
  var subclassName = "subclass class" + assassin.primaryclass + "sub" + assassin.subclass;
  var tier = "";
  if (typeof props.tier !== undefined) {
    tier = props.tier
  }

  return (
    <Link to={"/assassin/" + assassin.id + "/" + assassin.assassin_name_safe} key={"card" + props.id}>
      <div className={"heroContainer " + assassin.rarity_name}
        data-class={assassin.primaryclass}
        data-subclass={assassin.subclass}
        data-tier={tier}>
        <div className={"hero " + assassin.assassin_name_safe}></div>
        <div className="assassinName themeLabelY">{assassin.assassin_name}</div>
        <div className={subclassName}></div>
      </div>
    </Link>
  )
}

export default AssassinCard