// Basic Stuff
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Helmet } from "react-helmet"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom"
import { apiConfig } from "./js/Utils.js"

// Styles
import "./css/all.min.css"
import "./css/styles.css"

// Layout Stuff
import ScrollToTop from "./js/components/ScrollToTop"
import Header from "./js/components/Header"
import Nav from "./js/components/Nav"
import Theme from "./js/components/Theme"
import Holding from "./js/components/Holding"
//import Background from "./js/components/Background"

// Pages
import Home from "./js/pages/Home"
import Event from "./js/pages/Event"
import Gauntlet from "./js/pages/Gauntlet"
import Challenge from "./js/pages/Challenge"
import EventCalculator from "./js/pages/EventCalculator"
import GauntletCalculator from "./js/pages/GauntletCalculator"
import Archive from "./js/pages/Archive"
import AssassinIndex from "./js/pages/AssassinIndex"
import AssassinDetails from "./js/pages/AssassinDetails"
import GearIndex from "./js/pages/GearIndex"
import GearDetails from "./js/pages/GearDetails"
import SkillsIndex from "./js/pages/SkillsIndex"
import SkillDetails from "./js/pages/SkillDetails"
import TierList from "./js/pages/TierList"
import StatRankings from "./js/pages/StatRankings"
import BeginnersGuide from "./js/pages/BeginnersGuide"
import FAQ from "./js/pages/FAQ"
import EventHeroes from "./js/pages/EventHeroes"
import T5Weapons from "./js/pages/T5Weapons"
import T5Armor from "./js/pages/T5Armor"
import Bounties from "./js/pages/Bounties"
import Maps from "./js/pages/Maps"
import Creators from "./js/pages/Creators.js"
import CommunityRankings from "./js/pages/CommunityRankings.js"
import VersionLog from "./js/pages/VersionLog"
import NotFoundPage from "./js/pages/404"

// Theme
var theme = " DeadOfNight";
let urltheme = (new URL(window.location.href)).searchParams.get('theme');
if (urltheme !== null) {
  theme = " " + urltheme;
}

const axios = require('axios');

const App = () => {

  // Set up our loading
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/assassins`, apiConfig)
      .then(function (response) {
        dispatch({ type: "SET_ASSASSINS", data: response.data })
        setLoading(false);
      });
  }, [dispatch])

  if (isLoading) {
    return (
      <div className="App">
        <Holding />
      </div>
    )
  }

  return (
    <div className={"App" + theme}>
      <Helmet>
          <title>Assassin's Creed Rebellion &#47;&#47; Upcoming Helix Rift Event &amp; Animus Challenge Details</title>
      </Helmet>
      <Router>
        <ScrollToTop />
        <Header />
        <Nav />
        <div className="main">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/helix-rift-event-calculator" component={EventCalculator} />
            <Route path="/helix-rift-event-calculator/:id" component={EventCalculator} />
            <Route exact path="/abstergo-gauntlet-calculator" component={GauntletCalculator} />
            <Route path="/abstergo-gauntlet-calculator/:id" component={GauntletCalculator} />
            <Route exact path="/helix-rift-event-archive" component={Archive} />
            <Route path="/helix-rift-event/:id" component={Event} />
            <Route path="/abstergo-gauntlet/:id" component={Gauntlet} />
            <Route path="/animus-challenge/:id" component={Challenge} />
            <Route exact path="/assassins" component={AssassinIndex} />
            <Route path="/assassin/:id" component={AssassinDetails} />
            <Route exact path="/gear" component={GearIndex} />
            <Route path="/gear/:id" component={GearDetails} />
            <Route exact path="/skills" component={SkillsIndex} />
            <Route path="/skills/:id" component={SkillDetails} />
            <Route exact path="/assassin-tier-list" component={TierList} />
            <Route exact path="/assassin-stat-rankings" component={StatRankings} />
            <Route exact path="/beginners-guide" component={BeginnersGuide} />
            <Route exact path="/faq" component={FAQ} />
            <Route path="/faq/:ref" component={FAQ} />
            <Route path="/info/event-heroes" component={EventHeroes} />
            <Route path="/info/t5-weapons" component={T5Weapons} />
            <Route path="/info/t5-armor" component={T5Armor} />
            <Route path="/info/bounties" component={Bounties} />
            <Route path="/info/maps" component={Maps} />
            <Route path="/community-creators" component={Creators} />
            <Route path="/community-rankings" component={CommunityRankings} />
            <Route exact path="/version-log" component={VersionLog} />
            <Route exact path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </div>
        <Theme />
        <Link to="/version-log" className="versionLog">
          v2.14
        </Link>
      </Router>
      {/*<Background />*/}
    </div>
  )
}

export default App