import React from "react"
import { Link } from "react-router-dom"

const Nav = () => {
    return (
        <div className="menu">
            <div className="navigation">

                <section>
                    &#47;&#47; Intel Room
                    <Link to="/">
                        <i className="fas fa-calendar-day"></i> &nbsp; Calendar
                    </Link>
                    <Link to="/helix-rift-event-calculator">
                        <i className="fas fa-calculator"></i> &nbsp; Calculator
                    </Link>
                    <Link to="/helix-rift-event-archive">
                        <i className="fas fa-archive"></i> &nbsp; Archive
                    </Link>
                    <Link to="/info/maps">
                        <i className="fas fa-map"></i> &nbsp; Maps
                    </Link>
                    <Link to="/info/bounties">
                        <i className="fas fa-chart-bar"></i> &nbsp; Bounty Mission Stats
                    </Link>
                    <Link to="/info/event-heroes">
                        <i className="fas fa-poll-people"></i> &nbsp; Event Heroes
                    </Link>
                    <Link to="/info/t5-weapons">
                        <i className="fas fa-sword"></i> &nbsp; Tier 5 Weapons
                    </Link>
                    <Link to="/info/t5-armor">
                        <i className="fas fa-helmet-battle"></i> &nbsp; Tier 5 Armor
                    </Link>
                </section>
                <section>
                    &#47;&#47; Living Quarters
                    <Link to="/assassins">
                        <i className="fas fa-users"></i> &nbsp; Assassins
                    </Link>
                    <Link to="/gear">
                        <i className="fas fa-dagger"></i> &nbsp; Gear
                    </Link>
                    <Link to="/skills">
                        <i className="fas fa-bahai"></i> &nbsp; Skills
                    </Link>
                    <Link to="/assassin-tier-list">
                        <i className="fas fa-list"></i> &nbsp; Tier List
                    </Link>
                    <Link to="/assassin-stat-rankings">
                        <i className="fas fa-chart-bar"></i> &nbsp; Stat Rankings
                    </Link>
                    <Link to="/community-rankings">
                        <i className="fas fa-list-ol"></i> &nbsp; Community Rankings
                    </Link>
                </section>
                <section>
                    &#47;&#47; Training Room
                    <Link to="/beginners-guide">
                        <i className="fas fa-book-reader"></i> &nbsp; Beginner's Guide
                    </Link>
                    <Link to="/faq">
                        <i className="fas fa-question-circle"></i> &nbsp; FAQ
                    </Link>
                </section>
                <section>
                    &#47;&#47; Courtyard
                    <Link to="/community-creators">
                        <i className="fas fa-paint-brush"></i> &nbsp; Community Creators
                    </Link>
                    <a href="https://discord.gg/acr" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-discord"></i> &nbsp; Discord
                    </a>
                    <a href="http://ko-fi.com/robpoole/commissions" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-coffee"></i> &nbsp; Coffee
                    </a>
                </section>
            </div>
        </div>
    )
}

export default Nav