import React from "react"
import { Helmet } from "react-helmet"

const NotFoundPage = () => {
  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; 404</title>
      </Helmet>

      <h1>404</h1>

      <p>Nothing is true. Everything is permitted.</p>

    </div>
  )
}

export default NotFoundPage