import React, { useState } from "react"
import { Helmet } from "react-helmet"
import bountyData from '../../data/bounties.json'
import Coffee from "../components/Coffee"

const Bounties = () => {

  const [filter, setFilter] = useState("Chests");
  const filters = ["Chests", "Tinkers", "Traps", "Stealth", "Assassinations", "Enemies"];
  const yellow = {
    "Chests": 1,
    "Tinkers": 2,
    "Traps": 2,
    "Stealth": 3,
    "Assassinations": 7,
    "Enemies": 10
  };
  const green = {
    "Chests": 2,
    "Tinkers": 3,
    "Traps": 5,
    "Stealth": 6,
    "Assassinations": 10,
    "Enemies": 15
  };

  // Create our category filter tabs
  var filterTabs = []
  for (let i = 0; i < filters.length; i++) {

    // Figure out if this is the active tab
    var tabActive = (filter === filters[i] ? " on" : "");

    // Add it to our tab array
    filterTabs.push(
      <div key={"gc." + i}
        className={"tab " + tabActive}
        onClick={() => setFilter(filters[i])}>
        {filters[i]}
      </div>
    )
  }

  var theList = [];
  for (var i = 0; i < bountyData.length; i++) {

    var missions = bountyData[i].missions;

    var theCol = [];
    for (var b = 0; b < 10; b++) {

      var classColour = "";
      if (missions[b][`${filter}`] >= yellow[`${filter}`]) {
        classColour = " yellow"
      }
      if (missions[b][`${filter}`] >= green[`${filter}`]) {
        classColour = " green"
      }

      theCol.push(
        <div className={"cell" + classColour}>
          {missions[b][`${filter}`]}
        </div>
      );
    }
    theList.push(
      <div className="col">
        {bountyData[i].bounty}
        <div className="row">
          <div className={"subclass " + bountyData[i].subclass1}></div>
          <div className={"subclass " + bountyData[i].subclass2}></div>
        </div>
        {theCol}
      </div>
    );
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Bounty Mission Stats</title>
      </Helmet>

      <h1>Bounty Mission Stats</h1>

      <div className="tabs">
        {filterTabs}
      </div>

      <div className="bounties flexBox">
        {theList}
      </div>

      <Coffee />

    </div>

  )
}

export default Bounties