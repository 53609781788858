import React from "react"
import { Link } from "react-router-dom"
import Logo from "../../img/logo.svg"

const Header = () => {

  // Handle our menu opening/closing
  const toggleMenu = () => {
    var el = document.querySelector('.App');
    el.classList.toggle('menuOpen');
  }

  // Close the menu on any page load on mobile views
  document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.navigation a')) return;
    // if (document.body.clientWidth <= 480) {
      var menuOpen = document.querySelector('.menuOpen');
      if (menuOpen) {
        menuOpen.classList.remove('menuOpen');
      }
    // }

  });

  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <div className="burger" onClick={() => toggleMenu()}>
        <div className="one"></div>
        <div className="two"></div>
        <div className="three"></div>
      </div>
    </header>
  )
}

export default Header