import React from "react"
import { Helmet } from "react-helmet"
import Calendar from "../components/Calendar"
import HomeCalculator from "../components/HomeCalculator"
import HomeArchive from "../components/HomeArchive"
import HomeTierList from "../components/HomeTierList"
import HomeEventHeroes from "../components/HomeEventHeroes"
import HomeT5Weapons from "../components/HomeT5Weapons"
import HomeT5Armor from "../components/HomeT5Armor"
import HomeMaps from "../components/HomeMaps"
import HomeBounties from "../components/HomeBounties"
import Coffee from "../components/Coffee"

const Home = () => {

  return (
    <div className="calendar">

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Helix Rift Event Calendar</title>
      </Helmet>

      <h1>Helix Rift Event &amp; Animus Challenge Sequence</h1>

      <Calendar />

      <div className="featureGrid">
        <div className="row one">
          <HomeCalculator />
          <HomeArchive />
          <HomeTierList />
        </div>
        <div className="row two">
          <HomeMaps />
          <HomeBounties />
        </div>
        <div className="row one">
          <HomeEventHeroes />
          <HomeT5Weapons />
          <HomeT5Armor />
        </div>
      </div>

      <Coffee />

    </div>
  )
}

export default Home;