import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { gd, ts, safe, apiConfig, lookup } from "../Utils.js"
import Coffee from "../components/Coffee"
import Holding from "../components/Holding"

const axios = require('axios');

const Archive = () => {

  const handleScroll = event => {
    const months = document.querySelectorAll('.month')
    let stuckMonthIndex = -1;
    for (let i = 0; i < months.length; i++) {
      months[i].classList.remove('stuck')
      let rect = months[i].getBoundingClientRect();
      if (rect.top <= 100) {
        stuckMonthIndex = i;
      }
    }
    if (stuckMonthIndex >= 0) {
      months[stuckMonthIndex].classList.add('stuck')
      const monthOne = months[stuckMonthIndex].getBoundingClientRect();
      const monthTwo = months[stuckMonthIndex + 1].getBoundingClientRect();
      const gap = monthTwo.top - monthOne.top
      if (gap < 50) {
        months[stuckMonthIndex].classList.remove('stuck')
      }
    }

    const years = document.querySelectorAll('.year')
    let stuckYearIndex = -1;
    for (let i = 0; i < years.length; i++) {
      years[i].classList.remove('stuck')
      let rect = years[i].getBoundingClientRect();
      if (rect.top <= 100) {
        stuckYearIndex = i;
      }
    }
    if (stuckYearIndex >= 0) {
      years[stuckYearIndex].classList.add('stuck')
      const yearOne = years[stuckYearIndex].getBoundingClientRect();
      const yearTwo = years[stuckYearIndex + 1].getBoundingClientRect();
      const gap = yearTwo.top - yearOne.top
      if (gap < 50) {
        years[stuckYearIndex].classList.remove('stuck')
      }
    }
  };

  // Establish our naming constants
  const dayNames = ["S", "M", "T", "W", "T", "F", "S"];
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/events`, apiConfig)
      .then(function (response) {
        setEvents(response.data);
        setLoading(false);
      });
  }, [])

  // Figure out our current event
  var currentEventId = 0;
  if (typeof events !== 'undefined' && events.length > 0) {
    for (var e = events.length - 1; e > 0; e--) {
      if (ts(events[e].end_date) > Math.floor(Date.now() / 1000)) {
        currentEventId = e;
      } else {
        break;
      }
    }

    // Set up our dates
    var startDate = events[0].start_date;
    var endDate = events[events.length - 1].end_date;
  }

  // Print out our grid
  const archiveDays = [];
  var currentMonth = "";
  var currentYear = "";
  if (typeof events !== 'undefined' && events.length > 0) {
    for (var d = gd(endDate); d >= gd(startDate); d.setDate(d.getDate() - 1)) {

      // Only display month when it changes
      var displayMonth = null;
      if (currentMonth !== monthNames[d.getMonth()]) {
        displayMonth = (<div className="month">{monthNames[d.getMonth()]}</div>)
      }
      currentMonth = monthNames[d.getMonth()];

      // Only display year when it changes
      var displayYear = null;
      if (currentYear !== d.getFullYear()) {
        displayYear = (<div className="year">{d.getFullYear()}</div>)
      }
      currentYear = d.getFullYear();

      archiveDays.push(
        <div className="dayRow" key={new Date(d)}>
          <div className="date">
            <div className="dayName">{dayNames[d.getDay()]}</div>
            <div className="dayNum">{d.getDate()}</div>
            {displayMonth}
            {displayYear}
          </div>
          <div className="divider-h"></div>
        </div>
      );
    }
  }
  
  // Prepare our events
  const archiveEvents = [];
  if (typeof events !== 'undefined' && events.length > 0) {
    for (var i = events.length - 1; i >= 0; i--) {

      // Size and position event
      var thisEndDate = gd(events[i].end_date);
      var thisStartDate = gd(events[i].start_date);
      var daysOffset = Math.floor((gd(endDate) - thisEndDate) / 86400000);
      var topOffset = (50 * daysOffset) + 25;
      var daysLong = Math.ceil((thisEndDate - thisStartDate) / 86400000);
      var divHeight = 50 * daysLong;
      const divStyle = {
        height: divHeight + 'px',
        top: topOffset + 'px',
      };

      // Unique key events
      var eventClassName = 'event ' + safe(events[i].event_name);

      // Leaderboard & progression heroes
      var leaderboardHero = lookup(assassins, 'id', events[i]['leaderboard_hero']);
      var progressionHero = lookup(assassins, 'id', events[i]['progression_hero']);

      // Sync heroes
      var heroClassName = "";
      var heroContainerClassName = "";
      const syncHeroes = [];

      if (i <= currentEventId) {
        for (var h = 1; h <= 6; h++) {

          //var thisSyncHeroNum = 'sync_hero_' + h;

          // Grab this assassin
          var assassin = lookup(assassins, 'id', events[i][`sync_hero_${h}`]);

          if (events[i][`sync_hero_${h}`] !== null && parseInt(events[i][`sync_hero_${h}`]) !== 0) {

            heroClassName = 'hero ' + assassin.rarity_name +
              ' ' + assassin.assassin_name_safe;
            heroContainerClassName = 'heroContainer ' + assassin.rarity_name;
            var thisKey = i + "." + h;

            syncHeroes.push(
              <div className={heroContainerClassName} key={thisKey}>
                <div className={heroClassName}></div>
              </div>
            );
          }
        }
      }

      var eventUrl = "helix-rift-event/" + events[i].id + "/" + safe(events[i].event_name);
      if (safe(events[i].event_name) === 'abstergo-gauntlet') {
        eventUrl = "abstergo-gauntlet/" + events[i].id
      }

      var thisLinkKey = 'link.' + i;

      archiveEvents.push(
        <Link to={eventUrl} key={thisLinkKey}>
          <div className={eventClassName} style={divStyle} key={events[i].event_id}>
            <div className="eventTitle themeLabelX">
              {events[i].event_name}
            </div>
            <div className="heroes">
              {syncHeroes}
            </div>
            <div className={`leaderboardHero heroContainer ${leaderboardHero.rarity_name}`} key={`lh ${i}`}>
              <div className={`hero ${leaderboardHero.rarity_name} ${leaderboardHero.assassin_name_safe}`}></div>
              <div className="label themeLabelX"><i className="fas fa-trophy"></i></div>
            </div>
            <div className={`progressionHero heroContainer ${progressionHero.rarity_name}`} key={`ph ${i}`}>
              <div className={`hero ${progressionHero.rarity_name} ${progressionHero.assassin_name_safe}`}></div>
              <div className="label themeLabelX">...</div>
            </div>
          </div>
        </Link>
      )
    }
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div className="archive" onScroll={handleScroll}>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Helix Rift Event Archive</title>
      </Helmet>

      <h1>Helix Rift Event Archive</h1>

      <div className="archiveContainer">
        <div className="days">
          {archiveDays}
        </div>
        <div className="events">
          {archiveEvents}
        </div>
      </div>

      <Coffee />

    </div>
  )
}

export default Archive;