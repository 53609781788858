import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { calculateStats } from "../actions/assassinstats"
import { number_format } from "../Utils.js"

const AssassinStatsCalculator = (props) => {

  const dispatch = useDispatch();

  const assassin = props.assassin;
  const stats = props.stats;
  const gear = props.gear;

  // Grab our assassin stats stuff from the store
  let assassinstats = useSelector(
    state => state.assassinstats
  );

  function changeStars(stars) {

    // Fill in our stars appropriately
    var allStars = document.querySelectorAll(".stars i");
    if (allStars) {
      for (var i = 0; i < allStars.length; i++) {
        if ((i + 1) <= stars) {
          allStars[i].classList.remove('far');
          allStars[i].classList.add('fas');
        } else {
          allStars[i].classList.remove('fas');
          allStars[i].classList.add('far');
        }
      }
    }

    validateGear()
  }

  function changeWeapon(weaponId) {

    // Unselect
    var selected = document.querySelector('.weapons .gearContainer.selected');
    if (selected) {
      selected.classList.remove('selected');
    }

    // Select this one
    var thisWeapon = document.querySelector(".weapons .gearContainer[data-id='" + weaponId + "']");
    if (thisWeapon) {
      thisWeapon.classList.add('selected');
    }

    dispatch(calculateStats(assassin, stats, gear))
  }

  function changeArmor(armorId) {

    // Unselect
    var selected = document.querySelector('.armors .gearContainer.selected');
    if (selected) {
      selected.classList.remove('selected');
    }

    // Select this one
    var thisArmor = document.querySelector(".armors .gearContainer[data-id='" + armorId + "']");
    if (thisArmor) {
      thisArmor.classList.add('selected');
    }

    dispatch(calculateStats(assassin, stats, gear))
  }

  function changeAccessory(accessoryId) {

    // Unselect
    var selected = document.querySelector('.accessories .gearContainer.selected');
    if (selected) {
      selected.classList.remove('selected');
    }

    // Select this one
    var thisAccessory = document.querySelector(".accessories .gearContainer[data-id='" + accessoryId + "']");
    if (thisAccessory) {
      thisAccessory.classList.add('selected');
    }

    dispatch(calculateStats(assassin, stats, gear))
  }

  function validateGear() {

    // Weapon
    if (assassinstats.weaponLevel > assassinstats.level ||
      (assassinstats.weaponRank === 5 && assassinstats.stars < 5)) {

      var defaultWeapon = document.querySelector('.weapons .gearContainer:first-child');
      if (defaultWeapon) {
        defaultWeapon.classList.add('selected');
      }
    }

    // Armor
    if (assassinstats.armorLevel > assassinstats.level ||
      (assassinstats.armorRank === 5 && assassinstats.stars < 5)) {

      var defaultArmor = document.querySelector('.armors .gearContainer:first-child');
      if (defaultArmor) {
        defaultArmor.classList.add('selected');
      }
    }

    // Accessory
    if (assassinstats.accessoryLevel > assassinstats.level ||
      (assassinstats.accessoryRank === 5 && assassinstats.stars < 5)) {

      var defaultAccessory = document.querySelector('.accessories .gearContainer:first-child');
      if (defaultAccessory) {
        defaultAccessory.classList.add('selected');
      }
    }
    dispatch(calculateStats(assassin, stats, gear))
  }

  // Gear
  var weapons = [];
  var armor = [];
  var accessories = [];
  weapons.push(
    <div className="gearContainer eligible selected"
      data-id="-1"
      data-reqlevel="1"
      data-reqrank="1"
      onClick={() => changeWeapon(-1)}
      key="w.-1">
      <div className="weapon none"><i className="fas fa-times"></i></div>
    </div>
  );
  armor.push(
    <div className="gearContainer eligible selected"
      data-id="-1"
      data-reqlevel="1"
      data-reqrank="1"
      onClick={() => changeArmor(-1)}
      key="a.-1">
      <div className="armor none"><i className="fas fa-times"></i></div>
    </div>
  );
  accessories.push(
    <div className="gearContainer eligible selected"
      data-id="-1"
      data-reqlevel="1"
      data-reqrank="1"
      onClick={() => changeAccessory(-1)}
      key="c.-1">
      <div className="accessory none"><i className="fas fa-times"></i></div>
    </div>
  );

  for (let g = 0; g < gear.length; g++) {

    var typeClass = gear[g].type.toLowerCase();

    // Weapons
    if (gear[g].type === "Weapon" &&
      gear[g].sub_type === assassin.weapon_type) {
      if (gear[g].required_hero === parseInt(assassin.id) ||
        (gear[g].required_class === assassin.primaryclass &&
          gear[g].required_subclass === assassin.subclass) ||
        (gear[g].required_hero === 0 && gear[g].required_subclass === 0)
      ) {

        var stars = [];
        for (var s = 1; s <= parseInt(gear[g].rank); s++) {
          stars.push(<i className="fas fa-star" key={"weapon." + g + "." + s}></i>);
        }

        var reqRank = 1;
        if (gear[g].rank === 5 &&
          gear[g].name_safe.substring(0, 18) !== "ritualistic-xiphos" &&
          gear[g].name_safe.substring(0, 14) !== "myrmidon-spear") {
          reqRank = 5;
        }

        var eligible = " eligible";
        if (reqRank > assassinstats.stars || gear[g].required_level > assassinstats.level) {
          eligible = " ineligible";
        }

        weapons.push(
          <div className={"gearContainer" + eligible}
            data-id={g}
            data-reqlevel={gear[g].required_level}
            data-reqrank={reqRank}
            onClick={() => changeWeapon(g)}
            key={"w." + g}>
            <div className={typeClass + " " + gear[g].name_safe}></div>
            <div className="gearStars">{stars}</div>
          </div>
        );
      }
    }

    // Armor
    if (gear[g].type === "Armor" &&
      gear[g].sub_type === assassin.armor_type) {
      if (gear[g].required_hero === parseInt(assassin.id) ||
        (gear[g].required_class === assassin.primaryclass &&
          gear[g].required_subclass === assassin.subclass) ||
        (gear[g].required_hero === 0 && gear[g].required_subclass === 0)
      ) {

        stars = [];
        for (s = 1; s <= parseInt(gear[g].rank); s++) {
          stars.push(<i className="fas fa-star" key={"armor." + g + "." + s}></i>);
        }

        reqRank = 1;
        if (gear[g].rank === 5) {
          reqRank = 5;
        }

        eligible = " eligible";
        if (reqRank > assassinstats.stars || gear[g].required_level > assassinstats.level) {
          eligible = " ineligible";
        }

        armor.push(
          <div className={"gearContainer" + eligible}
            data-id={g}
            data-type={gear[g].sub_type}
            data-rank={gear[g].rank}
            onClick={() => changeArmor(g)}
            key={"a." + g}>
            <div className={typeClass + " " + gear[g].name_safe}></div>
            <div className="gearStars">{stars}</div>
          </div>
        );
      }
    }

    // Accessories
    if (gear[g].type === "Accessory") {
      if (gear[g].required_hero === parseInt(assassin.id) ||
        (gear[g].required_class === assassin.primaryclass &&
          gear[g].required_subclass === assassin.subclass) ||
        (gear[g].required_hero === 0 && gear[g].required_subclass === 0)
      ) {

        stars = [];
        for (s = 1; s <= parseInt(gear[g].rank); s++) {
          stars.push(<i className="fas fa-star" key={"accessory." + g + "." + s}></i>);
        }

        reqRank = 1;
        if (gear[g].rank === 5) {
          reqRank = 5;
        }

        eligible = " eligible";
        if (reqRank > assassinstats.stars || gear[g].required_level > assassinstats.level) {
          eligible = " ineligible";
        }

        accessories.push(
          <div className={"gearContainer" + eligible}
            data-id={g}
            data-type={gear[g].sub_type}
            data-rank={gear[g].rank}
            onClick={() => changeAccessory(g)}
            key={"c." + g}>
            <div className={typeClass + " " + gear[g].name_safe}></div>
            <div className="gearStars">{stars}</div>
          </div>
        );
      }
    }
  }

  useEffect(() => {
    dispatch(calculateStats(assassin, stats, gear))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assassin, stats, gear])

  return (
    <div className="statsCalculator">
      <div className="row">
        <h2>Stats</h2>
      </div>
      <div className="row heroLevels">
        <div className="col">
          <div className="statLabel">Rank</div>
          <div className="stars">
            <i className="fas fa-star" onClick={() => changeStars(1)}></i>
            <i className="far fa-star" onClick={() => changeStars(2)}></i>
            <i className="far fa-star" onClick={() => changeStars(3)}></i>
            <i className="far fa-star" onClick={() => changeStars(4)}></i>
            <i className="far fa-star" onClick={() => changeStars(5)}></i>
          </div>
        </div>
        <div className="col level">
          <div className="statLabel">Level</div>
          <div className="levelDisplay">{assassinstats.level}</div>
          <div><input type="range" name="level" min="1" max="50"
            defaultValue="1" onChange={() => validateGear()} /></div>
        </div>
        <div className="col power">
          <div className="statLabel">Power</div>
          <div className="powerDisplay">{number_format(assassinstats.power)}</div>
        </div>
      </div>

      <div className="gearSelector weapons">
        <div className="statLabel">Weapon</div>
        <div className="gearBar">
          {weapons}
        </div>
      </div>

      <div className="gearSelector armors">
        <div className="statLabel">Armor</div>
        <div className="gearBar">
          {armor}
        </div>
      </div>

      <div className="gearSelector accessories">
        <div className="statLabel">Accessory</div>
        <div className="gearBar">
          {accessories}
        </div>
      </div>

      <div className="row stats">
        <div className="col">
          <div className="statLabel">Health</div>
          <div className="stat health"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.health)}</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.health}
            <input type="range" name="health" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
        <div className="break-s"></div>
        <div className="col">
          <div className="statLabel">Attack</div>
          <div className="stat attack"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.attack)}</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.attack}
            <input type="range" name="attack" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
        <div className="break-s"></div>
        <div className="col">
          <div className="statLabel">Defense</div>
          <div className="stat defense"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.defense)}</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.defense}
            <input type="range" name="defense" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
      </div>
      <div className="row stats">
        <div className="col">
          <div className="statLabel">Dodge</div>
          <div className="stat dodge"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.dodge)}%</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.dodge}
            <input type="range" name="dodge" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
        <div className="break-s"></div>
        <div className="col">
          <div className="statLabel">Crit Chance</div>
          <div className="stat critChance"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.crit_chance)}%</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.crit_chance}
            <input type="range" name="critChance" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
        <div className="break-s"></div>
        <div className="col">
          <div className="statLabel">Crit Damage</div>
          <div className="stat critDamage"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.crit_damage)}%</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.crit_damage}
            <input type="range" name="critDamage" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
      </div>
      <div className="row stats">
        <div className="col">
          <div className="statLabel">Lethality</div>
          <div className="stat lethality"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.lethality)}</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.lethality}
            <input type="range" name="lethality" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
        <div className="break-s"></div>
        <div className="col">
          <div className="statLabel">Dexterity</div>
          <div className="stat dexterity"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.dexterity)}</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.dexterity}
            <input type="range" name="dexterity" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
        <div className="break-s"></div>
        <div className="col">
          <div className="statLabel">Agility</div>
          <div className="stat agility"></div>
          <div className="statDisplay">{number_format(assassinstats.eliteTraining.agility)}</div>
          <div className="et">
            Elite Training: {assassinstats.eliteTrainingUpgrades.agility}
            <input type="range" name="agility" min="0" max="10"
              defaultValue="0" onChange={() => dispatch(calculateStats(assassin, stats, gear))} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssassinStatsCalculator