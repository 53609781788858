import React from "react"
import { useDispatch } from "react-redux"

const Theme = () => {

  const dispatch = useDispatch();

  // Handles the opening/closing of the colour palette
  function togglePalette() {

    // Figure out our current theme
    const themeContainer = document.querySelector(".theme");
    const themeIcon = document.querySelector(".theme i");
    const palette = document.querySelector(".palette");

    if (themeIcon.classList.contains("fa-palette")) {
      themeContainer.classList.add("open");
      palette.classList.remove("hidden");
      themeIcon.classList.remove("fa-palette");
      themeIcon.classList.add("fa-times");
    } else {
      themeContainer.classList.remove("open");
      palette.classList.add("hidden");
      themeIcon.classList.remove("fa-times");
      themeIcon.classList.add("fa-palette");
    }
  }

  return (
    <div>
      <div className="palette hidden">
        <div className="option oceanVista"
          onClick={() => dispatch({ type: "CHANGE_THEME", theme: "OceanVista" })}>
          Ocean Vista
          <div className="colourBubble"></div>
        </div>
        <div className="option autumnDusk"
          onClick={() => dispatch({ type: "CHANGE_THEME", theme: "AutumnDusk" })}>
          Autumn Dusk
          <div className="colourBubble"></div>
        </div>
        <div className="option deadOfNight"
          onClick={() => dispatch({ type: "CHANGE_THEME", theme: "DeadOfNight" })}>
          Dead of Night
          <div className="colourBubble"></div>
        </div>
        <div className="option hiddenOnes"
          onClick={() => dispatch({ type: "CHANGE_THEME", theme: "HiddenOnes" })}>
          Hidden Ones
          <div className="colourBubble"></div>
        </div>
        <div className="option lateSummer"
          onClick={() => dispatch({ type: "CHANGE_THEME", theme: "LateSummer" })}>
          Late Summer
          <div className="colourBubble"></div>
        </div>
      </div>
      <div className="theme" onClick={() => togglePalette()}>
        <i className="fas fa-palette"></i>
      </div>
    </div>
  );
}

export default Theme;