import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
//import { useSelector } from "react-redux"
//import { number_format } from "../Utils.js"
import AssassinFiveStarGear from "../components/AssassinFiveStarGear"
import AssassinStatBlock from "../components/AssassinStatBlock"
import AssassinReview from "../components/AssassinReview"
import AssassinStatsCalculator from "../components/AssassinStatsCalculator"
import Holding from "../components/Holding"
import Coffee from "../components/Coffee"
import { apiConfig, safe } from "../Utils.js"

const axios = require('axios');

const apiStats = axios.get(`${process.env.REACT_APP_API_URL}api/stats`, apiConfig);
const apiGear = axios.get(`${process.env.REACT_APP_API_URL}api/gear`, apiConfig);

const AssassinDetails = () => {

  // Get our params
  const params = useParams();
  const assassinId = parseInt(params.id);

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [assassin, setAssassin] = useState({});
  const [review, setReview] = useState([]);
  const [stats, setStats] = useState([]);
  const [gear, setGear] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {

    const apiAssassin = axios.get(`${process.env.REACT_APP_API_URL}api/assassin/${assassinId}`, apiConfig);
    const apiReview = axios.get(`${process.env.REACT_APP_API_URL}api/assassin/review/${assassinId}`, apiConfig);
    const apiSkills = axios.get(`${process.env.REACT_APP_API_URL}api/skills/${assassinId}`, apiConfig);

    axios.all([apiAssassin, apiReview, apiStats, apiGear, apiSkills])
      .then(axios.spread((...responses) => {
        setAssassin(responses[0].data);
        setReview(responses[1].data);
        setStats(responses[2].data);
        setGear(responses[3].data);
        setSkills(responses[4].data);
        setLoading(false);
      })).catch(errors => {
        // react on errors.
      });
  }, [assassinId])

  const numerals = ["", "I", "II", "III", "IV", "V"]

  var skillDisplay = []
  for (var i = 0; i < skills.length; i++) {

    var instant = "";
    if (skills[i].skill_type === "combat-instant") {
      instant = <div className="bolt"><i className="fas fa-bolt"></i></div>
    }

    skillDisplay.push(
      <Link to={"/skills/" + skills[i].skill_id + "/" + safe(skills[i].skill_name)} key={"s."+i+"." + safe(skills[i].skill_name)}>
        <div className={"skill " + skills[i].skill_type}>
          <div className={"icon " + safe(skills[i].skill_name)}>
            <div className="grade">{numerals[skills[i].grade]}</div>
          </div>
          {instant}
        </div>
      </Link>
    );
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  // Context menu
  const contextMenu = [];
  contextMenu.push(
    <Link to="/assassins" key="back">
      <div className="prev">
        <i className="fas fa-users"></i>
      </div>
    </Link>
  );

  // Class icon
  var subclassName = "subclass class" + assassin.primaryclass + "sub" + assassin.subclass;

  return (
    <div>
      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; {assassin.assassin_name}</title>
      </Helmet>

      <h1>{assassin.assassin_name_full}</h1>

      <div className="assassinProfile">
        <div className="row">
          <div className="col">
            <div className={"heroContainer " + assassin.rarity_name}>
              <div className={"hero " + assassin.assassin_name_safe}>
                <div className={subclassName}></div>
              </div>
            </div>
          </div>
          <div className="break-s"></div>
          <div className="col">
            <AssassinFiveStarGear assassin={assassin} gear={gear} />
            <div className="row scores">
              <div className="col statRankings">
                <AssassinStatBlock id={assassinId} stats={stats} />
                <div className="label themeLabelY">Stat Rankings</div>
              </div>
            </div>
          </div>
        </div>

        <h2>Skills</h2>

        <div className="skills">
          {skillDisplay}
        </div>

        <AssassinReview assassin={assassin} review={review} />

        <AssassinStatsCalculator assassin={assassin} stats={stats} gear={gear} />

      </div>

      <div className="contextMenu">{contextMenu}</div>

      <Coffee />

    </div>
  )
}

export default AssassinDetails;