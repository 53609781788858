const RUN_CLOCK = "RUN_CLOCK"
const CALC_EVENT_OUTCOMES = "CALC_EVENT_OUTCOMES"

var outcomes = {
  nodes: {
    initialRunTokens: 0,
    initialRunNodes: 0,
    initialRunFHT: 0,
    numDailyRewards: 0,
    dailyRewardNodes: 0,
    refills: [{
      refillNum: 0,
      farmRuns: 0,
      farmTokens: 0,
      farmNodes: 0,
      farmFHT: 0,
      remainderMission: 0,
      remainderMissionTokens: 0,
      remainderMissionNodes: 0,
      remainderMissionFHT: 0,
      stars: 0
    }]
  },
  stars: []
}

var eventcalc = {
  goal: "nodes",
  maxSyncBonus: 0,
  bestMission: 0,
  bestTier: 0,
  rushing: false,
  alreadyJoined: false,
  initialRunCompleted: false,
  secondsRemaining: 0,
  displayTime: "",
  joinDateTime: 0,
  joinHoursRemaining: 0,
  missions: [],
  outcomes: outcomes
}

function eventcalcReducer(state = eventcalc, action) {
  if (action.type === RUN_CLOCK) {
    return {
      ...state,
      secondsRemaining: action.payload.secondsRemaining,
      displayTime: action.payload.displayTime
    }
  }
  if (action.type === CALC_EVENT_OUTCOMES) {
    return {
      ...state,
      goal: action.payload.goal,
      maxSyncBonus: action.payload.maxSyncBonus,
      bestLevel: action.payload.bestLevel,
      bestTier: action.payload.bestTier,
      rushing: action.payload.rushing,
      alreadyJoined: action.payload.alreadyJoined,
      initialRunCompleted: action.payload.initialRunCompleted,
      joinDateTime: action.payload.joinDateTime,
      joinHoursRemaining: action.payload.joinHoursRemaining,
      missions: action.payload.missions,
      outcomes: action.payload.outcomes
    }
  }
  return state
}

export default eventcalcReducer