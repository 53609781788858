import React from "react"
import { Helmet } from "react-helmet"
import Coffee from "../components/Coffee"

const Creators = () => {

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Community Creators</title>
      </Helmet>

      <h1>Community Creators</h1>

      <div className="creators">

        <div className="creator">

          <h2>CharlieB17</h2>

          <p>Charlie has created hundreds of videos on Youtube ranging from
            strategic breakdowns community challenges and also holds giveaways
            for new hero DNA during launch megaevents.</p>

          <a href="https://www.youtube.com/c/charlieb17" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i> &nbsp;
            YouTube
            &nbsp; <i className="fas fa-external-link-alt"></i>
          </a>

        </div>

        <div className="creator">

          <h2>Jemma Jam</h2>

          <p>Jemma is an amazing community artist who creates beautiful images
            featured ACR favourites.</p>

          <a href="https://www.instagram.com/jemmajamart/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i> &nbsp;
            Instagram
            &nbsp; <i className="fas fa-external-link-alt"></i>
          </a>

        </div>

        <div className="creator">

          <h2>Vek Torian</h2>

          <p>Vek is another awesome community artist who has created dozens of
            ACR pieces including comic pages.</p>

          <a href="https://www.instagram.com/vek_torian/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i> &nbsp;
            Instagram
            &nbsp; <i className="fas fa-external-link-alt"></i>
          </a>
        </div>

      </div>

      <Coffee />

    </div>
  );
};

export default Creators;