import React from "react"
import { Link } from "react-router-dom"

const HomeEventHeroes = () => {

  return (
    <div className="col">
      <h3>Event Heroes</h3>
      <div className="eventHeroes">
        <div className="event the-eagles-shadow">
          <div className="heroes">
            <div className="heroContainer legendary">
              <div className="hero altair"></div>
            </div>
            <div className="heroContainer epic">
              <div className="hero malik"></div>
            </div>
            <div className="heroContainer epic">
              <div className="hero maria-thorpe"></div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/info/event-heroes" key="link.calc">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-poll-people"></i>
    </div>
  )
}

export default HomeEventHeroes