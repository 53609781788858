import React from "react"
import { Helmet } from "react-helmet"
import Coffee from "../components/Coffee"

const T5Weapons = () => {

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Tier 5 Weapons</title>
      </Helmet>

      <h1>Tier 5 Weapons</h1>

      <div className="directory info">

        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <td><div className="subclass subclass1"></div></td>
              <td><div className="subclass subclass2"></div></td>
              <td><div className="subclass subclass3"></div></td>
              <td><div className="subclass subclass4"></div></td>
              <td><div className="subclass subclass5"></div></td>
              <td><div className="subclass subclass6"></div></td>
              <td><div className="subclass subclass7"></div></td>
              <td><div className="subclass subclass8"></div></td>
              <td><div className="subclass subclass9"></div></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th><div className="gearCategory sword"></div></th>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><div className="weapon silent-falchion"></div></td>
              <td><div className="weapon hasty-rapier"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon marring-milanese-sword"></div></td>
              <td><div className="weapon branded-kilij"></div></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><div className="weapon il-consigliere"></div></td>
              <td><div className="weapon protective-xiphos"></div></td>
            </tr>
            <tr>
              <th><div className="gearCategory short-blade"></div></th>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon concealed-dirk"></div></td>
              <td><div className="weapon nimble-poignard"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon brisk-cinquedea"></div></td>
            </tr>
            <tr>
              <th><div className="gearCategory hammer"></div></th>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon anguished-hammer"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon engineers-warhammer"></div></td>
              <td><div className="weapon heralds-morning-star"></div></td>
              <td><div className="weapon clerics-battle-mace"></div></td>
            </tr>
            <tr>
              <th><div className="gearCategory polearm"></div></th>
              <td><div className="weapon malevolence"></div></td>
              <td><div className="weapon sly-partisan"></div></td>
              <td><div className="weapon skyhook"></div></td>
              <td><div className="weapon lunging-voulge"></div></td>
              <td><div className="weapon striking-chance"></div></td>
              <td><div className="weapon dogged-halberd"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon banner-of-the-people"></div></td>
              <td><i className="fas fa-times"></i></td>
            </tr>
            <tr>
              <th><div className="gearCategory large-sword"></div></th>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon sweeping-spada-lunga"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon resolute-great-sword"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
            </tr>
            <tr>
              <th><div className="gearCategory axe"></div></th>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><div className="weapon blighted-great-axe"></div></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="weapon axe-of-the-crusader"></div></td>
              <td><i className="fas fa-times"></i></td>
            </tr>
            <tr>
              <th><div className="gearCategory hidden-blades"></div></th>
              <td><div className="weapon ruthless-blades"></div></td>
              <td><div className="weapon extortionate-pair"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><div className="weapon the-creeds-helping-hand"></div></td>
            </tr>
          </tbody>
        </table>

      </div>

      <p><i className="fas fa-exclamation-triangle"></i> Needed &nbsp;
        <i className="fas fa-times"></i> Not needed</p>

      <Coffee />

    </div>

  )
}

export default T5Weapons