import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const HomeTierList = () => {

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );

  var r = [];
  for (var n = 1; n <= 3; n++) {
    var i = Math.floor((Math.random() * (65 - n)) + 1);
    r.push(i);
  }

  return (
    <div className="col">
      <h3>Tier List</h3>
      <div className="tierList">
        <div className="tierContainer">
          <div className="tier">{assassins[r[0]].tier}</div>
          <div className={"heroContainer " + assassins[r[0]].rarity_name}>
            <div className={"hero " + assassins[r[0]].assassin_name_safe}></div>
          </div>
        </div>
        <div className="tierContainer">
          <div className="tier">{assassins[r[1]].tier}</div>
          <div className={"heroContainer " + assassins[r[1]].rarity_name}>
            <div className={"hero " + assassins[r[1]].assassin_name_safe}></div>
          </div>
        </div>
        <div className="tierContainer">
          <div className="tier">{assassins[r[2]].tier}</div>
          <div className={"heroContainer " + assassins[r[2]].rarity_name}>
            <div className={"hero " + assassins[r[2]].assassin_name_safe}></div>
          </div>
        </div>
      </div>
      <Link to="/assassin-tier-list" key="link.tier">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-list"></i>
    </div>
  )
}

export default HomeTierList