import React from "react"
import { Helmet } from "react-helmet"
import AssassinCard from "../components/AssassinCard"
import Coffee from "../components/Coffee"

const EventHeroes = () => {

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Event Heroes</title>
      </Helmet>

      <h1>Event Heroes</h1>

      <div className="directory info">

        <table>
          <tbody>
            <tr>
              <th>The Art of the Heist</th>
              <td><AssassinCard id={20} key="art1" /></td>
              <td><AssassinCard id={32} key="art2" /></td>
              <td>None</td>
              <td>None</td>
              <td>None</td>
            </tr>
            <tr>
              <th>Dead Men's Gold</th>
              <td><AssassinCard id={56} key="dmg4" /></td>
              <td><AssassinCard id={54} key="dmg1" /></td>
              <td><AssassinCard id={49} key="dmg2" /></td>
              <td><AssassinCard id={72} key="dmg3" /></td>
              <td>None</td>
            </tr>
            <tr>
              <th>The Eagle's Shadow</th>
              <td><AssassinCard id={33} key="eag1" /></td>
              <td><AssassinCard id={41} key="eag2" /></td>
              <td><AssassinCard id={45} key="eag3" /></td>
              <td>None</td>
              <td>None</td>
            </tr>
            <tr>
              <th>Echoes Through the Animus</th>
              <td><AssassinCard id={40} key="ech1" /></td>
              <td><AssassinCard id={1923} key="ech2" /></td>
              <td><AssassinCard id={97} key="ech3" /></td>
              <td><AssassinCard id={70} key="ech4" /></td>
              <td><AssassinCard id={78} key="ech5" /></td>
            </tr>
            <tr>
              <th>For Democracy!</th>
              <td><AssassinCard id={35} key="for1" /></td>
              <td><AssassinCard id={38} key="for2" /></td>
              <td><AssassinCard id={96} key="for3" /></td>
              <td><AssassinCard id={2123} key="for4" /></td>
              <td>None</td>
            </tr>
            <tr>
              <th>Horacio's Retribution</th>
              <td><AssassinCard id={25} key="hor1" /></td>
              <td>None</td>
              <td>None</td>
              <td>None</td>
              <td>None</td>
            </tr>
            <tr>
              <th>The Hunter's Hounds</th>
              <td><AssassinCard id={48} key="hun1" /></td>
              <td><AssassinCard id={86} key="hun2" /></td>
              <td><AssassinCard id={52} key="hun3" /></td>
              <td><AssassinCard id={94} key="hun4" /></td>
              <td>None</td>
            </tr>
            <tr>
              <th>Kinslayer</th>
              <td><AssassinCard id={46} key="kin1" /></td>
              <td><AssassinCard id={27} key="kin2" /></td>
              <td>None</td>
              <td>None</td>
              <td>None</td>
            </tr>
            <tr>
              <th>The Mask of the Ibis</th>
              <td><AssassinCard id={44} key="mask1" /></td>
              <td><AssassinCard id={43} key="mask2" /></td>
              <td><AssassinCard id={60} key="mask3" /></td>
              <td><AssassinCard id={57} key="mask4" /></td>
              <td><AssassinCard id={42} key="mask5" /></td>
            </tr>
            <tr>
              <th>The Ottoman Connection</th>
              <td><AssassinCard id={28} key="ott1" /></td>
              <td><AssassinCard id={19} key="ott2" /></td>
              <td><AssassinCard id={30} key="ott3" /></td>
              <td>None</td>
              <td>None</td>
            </tr>
            <tr>
              <th>The Ravens' Wound</th>
              <td><AssassinCard id={723} key="rav1" /></td>
              <td><AssassinCard id={82} key="rav2" /></td>
              <td><AssassinCard id={81} key="rav3" /></td>
              <td>None</td>
              <td>None</td>
            </tr>
            <tr>
              <th>A War in the Shadows</th>
              <td><AssassinCard id={26} key="war1" /></td>
              <td><AssassinCard id={85} key="war2" /></td>
              <td><AssassinCard id={98} key="war3" /></td>
              <td><AssassinCard id={24} key="war4" /></td>
              <td>None</td>
            </tr>
          </tbody>
        </table>

      </div>

      <Coffee />

    </div>

  )
}

export default EventHeroes