import React from "react"
import { Link } from "react-router-dom"
import { getRandom } from "../Utils.js"

const HomeT5Weapons = () => {

  const weapons = [
    'marring-milanese-sword',
    'concealed-dirk',
    'anguished-hammer',
    'banner-of-the-people',
    'sweeping-spada-lunga',
    'axe-of-the-crusader',
    'extortionate-pair'
  ]

  var randomThree = getRandom(weapons, 3);

  return (
    <div className="col">
      <h3>Tier 5 Weapons</h3>
      <div className="t5weapons">
        <div className="gearContainer">
          <div className={"weapon " + randomThree[0]}></div>
        </div>
        <div className="gearContainer">
          <div className={"weapon " + randomThree[1]}></div>
        </div>
        <div className="gearContainer">
          <div className={"weapon " + randomThree[2]}></div>
        </div>
      </div>
      <Link to="/info/t5-weapons" key="link.calc">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-sword"></i>
    </div>
  )
}

export default HomeT5Weapons