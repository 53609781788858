import { combineReducers } from "redux"
import theme from "./theme"
import assassins from "./assassins"
import statrankings from "./statrankings"
import assassinstats from "./assassinstats"
import eventcalc from "./eventcalc"
import gauntletcalc from "./gauntletcalc"

export default combineReducers({
  theme,
  assassins,
  statrankings,
  assassinstats,
  eventcalc,
  gauntletcalc
})