import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { number_format } from "../Utils.js"
import AssassinCard from "../components/AssassinCard"
import Holding from "../components/Holding"
import Coffee from "../components/Coffee"
import { apiConfig } from "../Utils.js"

const axios = require('axios');

const GearDetails = () => {

  // Grab our assassin from our store
  const assassins = useSelector(
    state => state.assassins
  );

  // Get our params
  const params = useParams();
  const gearId = params.id;

  // Set up our gear object
  var preloadGear = {
    "item_id": "",
    "name": "",
    "name_safe": "",
    "type": "",
    "sub_type": "",
    "rank": 0,
    "required_level": 0,
    "required_class": 0,
    "required_subclass": 0,
    "required_hero": 0,
    "stats": {
      "health": 0,
      "attack": 0,
      "defense": 0,
      "dodge": 0,
      "crit_chance": 0,
      "crit_damage": 0,
      "lethality": 0,
      "dexterity": 0,
      "agility": 0
    }
  }

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [gear, setGear] = useState(preloadGear);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      axios.get(`${process.env.REACT_APP_API_URL}api/gear/${gearId}`, apiConfig)
        .then(function (response) {
          setGear(response.data);
          setLoading(false);
          hasFetchedData.current = true;
        });
    }
  }, [gearId])

  // Context menu
  const contextMenu = [];
  contextMenu.push(
    <Link to="/gear" key="prevEvent">
      <div className="prev">
        <i className="fas fa-dagger"></i>
      </div>
    </Link>
  );

  var typeClass = gear.type.toLowerCase();

  var stars = [];
  for (var s = 1; s <= parseInt(gear.rank); s++) {
    stars.push(<i className="fas fa-star" key={"star." + s}></i>);
  }

  var stats = [];
  Object.keys(gear.stats).forEach(function (k) {
    if (gear.stats[k] > 0) {
      var percent = "";
      if (k === "dodge" || k === "crit_chance" || k === "crit_damage") {
        percent = "%";
      }
      var gearClass = k;
      if (k === "crit_chance") {
        gearClass = 'critChance';
      }
      if (k === "crit_damage") {
        gearClass = 'critDamage';
      }
      stats.push(
        <tr key={"stat" + k}>
          <td><div className={"stat " + gearClass}></div></td>
          <td>+{number_format(gear.stats[k])}{percent}</td>
        </tr>
      );
    }
  });

  var eligibleHeroes = [];
  // Look for some eligible heroes
  for (var a = 0; a < assassins.length; a++) {

    if (assassins[a].available === 1) {

      // Does the gear type for the hero match the gear?
      if (
        (gear.type === "Weapon" && assassins[a].weapon_type === gear.sub_type) ||
        (gear.type === "Armor" && assassins[a].armor_type === gear.sub_type) ||
        gear.type === "Accessory"
      ) {

        // Does the hero match and subclass match if necessary?
        if (
          (gear.required_hero !== 0 && assassins[a].id === gear.required_hero) ||
          (gear.required_subclass !== 0 &&
            assassins[a].primaryclass === gear.required_class &&
            assassins[a].subclass === gear.required_subclass) ||
          (gear.required_hero === 0 && gear.required_subclass === 0)
        ) {

          eligibleHeroes.push(
            <AssassinCard id={assassins[a].id} key={assassins[a].id} />
          );
        }
      }
    }
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; {gear.name}</title>
      </Helmet>

      <h1>{gear.name}</h1>

      <div className="gearProfile">

        <div className="row">
          <div className="col ">
            <div className="pic">
              <div className={typeClass + " " + gear.name_safe}></div>
            </div>
          </div>
          <div className="col details">
            <table>
              <tbody>
                <tr>
                  <td>Rank:</td>
                  <td>{stars}</td>
                </tr>
                {stats}
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <div className="eligibleHeroes">

        <h2>Eligible Heroes</h2>

        <div className="directory">
          {eligibleHeroes}
        </div>

      </div>

      <div className="contextMenu">{contextMenu}</div>

      <Coffee />

    </div>
  )
}

export default GearDetails;