import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { number_format, lookup } from "../Utils.js"
import Holding from "../components/Holding"
import Coffee from "../components/Coffee"
import { apiConfig } from "../Utils.js"

const axios = require('axios');

const StatRankings = () => {

  const dispatch = useDispatch();

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );

  // Grab our stat ranking stuff from the store
  let statrankings = useSelector(
    state => state.statrankings
  );

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [classFilter, setClassFilter] = useState("All");

  // Define our gear filter categories
  const filterClassValues = [
    "1", "1-1", "1-2", "1-3",
    "2", "2-1", "2-2", "2-3",
    "3", "3-1", "3-2", "3-3"
  ]
  const filterClassNames = [
    "class1", "subclass1", "subclass2", "subclass3",
    "class2", "subclass4", "subclass5", "subclass6",
    "class3", "subclass7", "subclass8", "subclass9"
  ]

  // Create our class filter tabs
  var filterTabs = []
  for (let i = 0; i < filterClassValues.length; i++) {
    filterTabs.push(
      <div key={"tab." + i}
        onClick={() => setClassFilter(filterClassValues[i])}
        className={"subclass " + filterClassNames[i] + (classFilter === filterClassValues[i] ? " on" : "")}>
      </div>
    )
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/stats`, apiConfig)
      .then(function (response) {
        setStats(response.data);
        setLoading(false);
      });
  }, [])

  if (isLoading) {
    return (
      <Holding />
    )
  }

  function changeStat(theStat) {

    // Update our tab
    var filterOn = document.querySelector('.filterStat div.on');
    if (filterOn) {
      filterOn.classList.remove('on');
    }
    filterOn = document.querySelector('.filterStat div.' + theStat);
    if (filterOn) {
      filterOn.classList.add('on');
    }

    var statTitle = "";

    if (theStat === "health") { statTitle = "Health"; }
    if (theStat === "attack") { statTitle = "Attack"; }
    if (theStat === "defense") { statTitle = "Defense"; }
    if (theStat === "dodge") { statTitle = "Dodge"; }
    if (theStat === "crit_chance") { statTitle = "Crit Chance"; }
    if (theStat === "crit_damage") { statTitle = "Crit Damage"; }
    if (theStat === "lethality") { statTitle = "Lethality"; }
    if (theStat === "dexterity") { statTitle = "Dexterity"; }
    if (theStat === "agility") { statTitle = "Agility"; }
    if (theStat === "crafting") { statTitle = "Crafting"; }
    if (theStat === "production") { statTitle = "Production"; }
    if (theStat === "learning") { statTitle = "Learning"; }

    var statObj = {
      theStat: theStat,
      statTitle: statTitle,
    }

    dispatch({ type: "SR_CHANGE_STAT", stat: statObj })
  }

  // Change hero stars via clicking a star
  function changeStars(stars) {

    // Fill in our stars appropriately
    var allStars = document.querySelectorAll(".stars i");
    if (allStars) {
      for (var i = 0; i < allStars.length; i++) {
        if ((i + 1) <= stars) {
          allStars[i].classList.remove('far');
          allStars[i].classList.add('fas');
        } else {
          allStars[i].classList.remove('fas');
          allStars[i].classList.add('far');
        }
      }
    }

    dispatch({ type: "SR_CHANGE_STARS", stars: stars })
  }

  // Change hero level via range slider
  function changeLevel() {
    var levelSlider = document.querySelector("input[name=level]");
    if (levelSlider) {
      var level = levelSlider.value;
      dispatch({ type: "SR_CHANGE_LEVEL", level: level })
    }
  }

  // Let's calculate!
  var rankMultiplier = [0, 0, 0.75, 1.5, 2.55, 4];
  var theStatValue = 0;
  var theList = [];

  for (var s = 0; s < stats.length; s++) {

    if (statrankings.theStat === "health") {

      var health = parseFloat(stats[s].vitality_base_stat) *
        (1 + rankMultiplier[statrankings.stars]) +
        parseFloat(stats[s].vitality_per_level_gain) *
        statrankings.level;
      theStatValue = Math.floor(health);

    }

    if (statrankings.theStat === "attack") {

      var attack = parseFloat(stats[s].attack_base_stat) *
        (1 + rankMultiplier[statrankings.stars]) +
        parseFloat(stats[s].attack_per_level_gain) *
        statrankings.level;
      theStatValue = Math.floor(attack);

    }

    if (statrankings.theStat === "defense") {

      var defense = parseFloat(stats[s].defense_base_stat) *
        (1 + rankMultiplier[statrankings.stars]) +
        parseFloat(stats[s].defense_per_level_gain) *
        statrankings.level;
      theStatValue = Math.floor(defense);

    }

    if (statrankings.theStat === "dodge") {

      var dodge = parseFloat(stats[s].dodge_base_stat) *
        100;
      theStatValue = Math.floor(dodge);

    }

    if (statrankings.theStat === "crit_chance") {

      var crit_chance = parseFloat(stats[s].crit_chance_base_stat) *
        100;
      theStatValue = Math.floor(crit_chance);

    }

    if (statrankings.theStat === "crit_damage") {

      var crit_damage = parseFloat(stats[s].crit_damage_base_stat) *
        100;
      theStatValue = Math.floor(crit_damage);

    }

    if (statrankings.theStat === "lethality") {

      var lethality = parseFloat(stats[s].lethality_base_stat) *
        (1 + rankMultiplier[statrankings.stars]) +
        parseFloat(stats[s].lethality_per_level_gain) *
        statrankings.level;
      theStatValue = Math.floor(lethality);

    }

    if (statrankings.theStat === "dexterity") {

      var dexterity = parseFloat(stats[s].dexterity_base_stat) *
        (1 + rankMultiplier[statrankings.stars]) +
        parseFloat(stats[s].dexterity_per_level_gain) *
        statrankings.level;
      theStatValue = Math.floor(dexterity);

    }

    if (statrankings.theStat === "agility") {

      var agility = parseFloat(stats[s].agility_base_stat) *
        (1 + rankMultiplier[statrankings.stars]) +
        parseFloat(stats[s].agility_per_level_gain) *
        statrankings.level;
      theStatValue = Math.floor(agility);

    }

    if (statrankings.theStat === "crafting") {

      var crafting = (parseFloat(stats[s].crafting_base_stat) +
        parseFloat(stats[s].crafting_per_level_gain) *
        statrankings.level) *
        statrankings.stars;
      theStatValue = Math.floor(crafting);

    }

    if (statrankings.theStat === "production") {

      var production = (parseFloat(stats[s].production_base_stat) +
        parseFloat(stats[s].production_per_level_gain) *
        statrankings.level) *
        statrankings.stars;
      theStatValue = Math.floor(production);

    }

    if (statrankings.theStat === "learning") {

      var learning = (parseFloat(stats[s].learning_base_stat) +
        parseFloat(stats[s].learning_per_level_gain) *
        statrankings.level) *
        statrankings.stars;
      theStatValue = Math.floor(learning);

    }
    var listItem = {
      assassinId: stats[s].id,
      theStat: theStatValue
    }

    theList.push(listItem);
  }
  theList.sort((a, b) => (a['theStat'] < b['theStat']) ? 1 : ((b['theStat'] < a['theStat']) ? -1 : 0));

  var filterPrimaryClass = classFilter.substring(0, 1)
  var filterSubClass = classFilter.substring(2, 3)

  return (
    <div className="assassinStatRankingsContainer">

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Stat Rankings</title>
      </Helmet>

      <h1>Assassin Stat Rankings</h1>

      <div className="filter">
        <div data-filter="All"
          onClick={() => setClassFilter("All")}
          className={(classFilter === "All" ? "on" : "")}>All</div>
        {filterTabs}
      </div>

      <div className="row heroLevels">
        <div className="col">
          <div className="statLabel">Rank</div>
          <div className="stars">
            <i className="fas fa-star" onClick={() => changeStars(1)}></i>
            <i className="far fa-star" onClick={() => changeStars(2)}></i>
            <i className="far fa-star" onClick={() => changeStars(3)}></i>
            <i className="far fa-star" onClick={() => changeStars(4)}></i>
            <i className="far fa-star" onClick={() => changeStars(5)}></i>
          </div>
        </div>
        <div className="col level">
          <div className="statLabel">Level</div>
          <div className="levelDisplay">{statrankings.level}</div>
          <div><input type="range" name="level" min="1" max="50"
            defaultValue="1" onChange={() => changeLevel()} /></div>
        </div>
      </div>
      <div className="filter filterStat">
        <div className="stat health on" onClick={() => changeStat('health')}></div>
        <div className="stat attack" onClick={() => changeStat('attack')}></div>
        <div className="stat defense" onClick={() => changeStat('defense')}></div>
        <div className="stat dodge" onClick={() => changeStat('dodge')}></div>
        <div className="stat critChance" onClick={() => changeStat('crit_chance')}></div>
        <div className="stat critDamage" onClick={() => changeStat('crit_damage')}></div>
        <div className="stat lethality" onClick={() => changeStat('lethality')}></div>
        <div className="stat dexterity" onClick={() => changeStat('dexterity')}></div>
        <div className="stat agility" onClick={() => changeStat('agility')}></div>
        <div className="stat crafting" onClick={() => changeStat('crafting')}></div>
        <div className="stat production" onClick={() => changeStat('production')}></div>
        <div className="stat learning" onClick={() => changeStat('learning')}></div>
      </div>
      <div className="assassinStatRankings">

        <h2>{statrankings.statTitle}</h2>
        <table>
          <tbody>
            {theList.map(assassin => {

              var assassinDetails = lookup(assassins, 'id', assassin.assassinId);

              if (
                (typeof (assassinDetails) !== 'undefined' && assassinDetails.available === 1) &&
                (classFilter === "All" ||
                  (parseInt(filterPrimaryClass) === assassinDetails.primaryclass &&
                    (filterSubClass === "" || parseInt(filterSubClass) === assassinDetails.subclass)
                  )
                )
              ) {

                var heroClassName = 'hero ' + assassinDetails.rarity_name + ' ' + assassinDetails.assassin_name_safe;
                var heroContainerClassName = 'heroContainer ' + assassinDetails.rarity_name;
                var percent = "";

                if (statrankings.theStat === "dodge" || statrankings.theStat === "crit_chance") {
                  percent = "%";
                }

                return (
                  <tr key={"tr" + assassin.assassinId}>
                    <td>
                      <Link to={"/assassin/" + assassin.assassinId + "/" + assassinDetails.assassin_name_safe} key={"link" + assassin}>
                        <div className={heroContainerClassName} key={assassin.assassinId}>
                          <div className={heroClassName}></div>
                        </div>
                      </Link>
                    </td>
                    <td>{assassinDetails.assassin_name}</td>
                    <td>
                      {number_format(assassin.theStat) + percent}
                    </td>
                  </tr>
                )
              } else {
                return null
              }
            })}
          </tbody>
        </table>
      </div>
      <Coffee />
    </div>
  )
}

export default StatRankings;