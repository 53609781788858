import React, { forwardRef, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import FlipMove from "react-flip-move"
import Holding from "../components/Holding"
import { number_format, apiConfig } from "../Utils.js"

const axios = require('axios');

const CommunityRankings = () => {

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );

  const [isLoading, setLoading] = useState(true);
  const [ip, setIP] = useState('');
  const [userVotes, setUserVotes] = useState([]);
  const [timeUntilNextVote, setTimeUntilNextVote] = useState("");

  // Initialise our assassin votes
  const initialiseRankings = () => {
    for (var i = 0; i < assassins.length; i++) {
      assassins[i]['upvotes'] = 0;
      assassins[i]['downvotes'] = 0;
      assassins[i]['rating'] = 0;
      assassins[i]['rank'] = 0;
    }
    getData();
    setLoading(false);
  }

  // Populate our votes from the API
  const populateRankings = (rankingsData) => {
    for (let i = 0; i < assassins.length; i++) {

      // Get the rankings for our assassin
      const assassinRatings = rankingsData.filter(obj => {
        return obj.assassin_id === assassins[i].id
      });

      if (typeof (assassinRatings[0]) !== "undefined") {
        assassins[i]['upvotes'] = assassinRatings[0].upvotes
        assassins[i]['downvotes'] = assassinRatings[0].downvotes
        assassins[i]['rating'] = assassinRatings[0].rating
      }
    }
  }

  // Grab our data!
  const getData = async () => {

    // Get our users IP if we need it
    if (ip === "") {
      await axios.get('https://t9ulwgz8hj067apgp6pz3i7gy9i9qjxz.edns.ip-api.com/json')
        .then(function (ipResponse) {
          console.log(ipResponse, ipResponse.data.dns.ip)
          setIP(ipResponse.data.dns.ip);
          const userVotesResponse = axios.get(`${process.env.REACT_APP_API_URL}api/rankings/user/${ipResponse.data.dns.ip}`, apiConfig);
          setUserVotes(userVotesResponse.data);
        });
    }

    // Get all the votes
    const allVotesResponse = await axios.get(`${process.env.REACT_APP_API_URL}api/rankings`, apiConfig);
    populateRankings(allVotesResponse.data);

    // Get our user votes
    if (ip !== "") {
      const userVotesResponse = await axios.get(`${process.env.REACT_APP_API_URL}api/rankings/user/${ip}`, apiConfig);
      setUserVotes(userVotesResponse.data);
    }

  }

  // Get our time remaining until the next vote
  const getTimeUntilNextVote = () => {
    let date = new Date();
    let mins = date.getMinutes();
    let secs = date.getSeconds();
    let minsUntilNextVote = 59 - mins;
    let secsUntilNextVote = 59 - secs;
    let returnString = minsUntilNextVote + "m " + secsUntilNextVote + "s";
    if (minsUntilNextVote === 0 && secsUntilNextVote === 0) {
      getData();
    }
    return returnString;
  }

  // Cast our vote!
  const castVote = async (assassin_id, rating) => {

    // Disable vote buttons so no spamming
    const voteButtons = document.querySelector("#voteContainer" + assassin_id + " .voteButtons");
    const voteAgain = document.querySelector("#voteContainer" + assassin_id + " .voteAgain");
    voteButtons.style.display = 'none';
    voteAgain.style.display = 'block';

    // Send vote via API
    const json = {
      "user_id": ip,
      "assassin_id": assassin_id,
      "rating": rating
    };
    await axios.post(`${process.env.REACT_APP_API_URL}api/rankings/vote`, json, apiConfig);
    getData();
  }

  // Let's put together our rank row
  const RankRow = forwardRef((assassin, ref) => {

    var heroClassName = 'hero ' + assassin.rarity_name + ' ' + assassin.assassin_name_safe;
    var heroContainerClassName = 'heroContainer ' + assassin.rarity_name;

    // Figure out if we've voted on this assassin today
    var voteStyle = "block";
    var voteAgainStyle = "none";
    if (typeof (userVotes) !== "undefined") {
      const userVoted = userVotes.filter(obj => {
        return obj.assassin_id === assassin.id
      })
      if (typeof (userVoted[0]) !== "undefined") {
        voteStyle = "none";
        voteAgainStyle = "block";
      }
    }

    var voteDiv = (
      <div className="voteButtons" style={{ display: voteStyle }}>
        <i className="fas fa-arrow-alt-circle-up"
          onClick={() => castVote(assassin.id, 1)}></i>
        <i className="fas fa-arrow-alt-circle-down"
          onClick={() => castVote(assassin.id, -1)}></i>
      </div>
    );

    var voteAgainDiv = (
      <div className="voteAgain" style={{ display: voteAgainStyle }}>
        Vote in {timeUntilNextVote}
      </div>
    )

    return (
      <div ref={ref}
        className="rankRow"
        key={"tr" + assassin.id}>
        <div className="rank">{assassin.rank}</div>
        <div className="assassin">
          <Link to={"/assassin/" + assassin.id + "/" + assassin.assassin_name_safe} key={"link" + assassin}>
            <div className={heroContainerClassName} key={assassin.id}>
              <div className={heroClassName}></div>
            </div>
            <div className="assassinName">{assassin.assassin_name}</div>
          </Link>
        </div>
        <div className="existingVotes">
          <i className="fas fa-arrow-up"></i> {number_format(assassin.upvotes)}
          &nbsp; &nbsp;
          <i className="fas fa-arrow-down"></i> {number_format(assassin.downvotes)}
        </div>
        <div className="numVotes">
          {number_format(assassin.rating)}
        </div>
        <div className="voteContainer" id={"voteContainer" + assassin.id}>
          {voteDiv}
          {voteAgainDiv}
        </div>
        <div className="divider-h"></div>
      </div>
    )

  });

  useEffect(() => {
    initialiseRankings();
    const timer = setInterval(() => {
      setTimeUntilNextVote(getTimeUntilNextVote());
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Sort our assassins!
  assassins.sort((a, b) => (a['rating'] < b['rating']) ? 1 : ((b['rating'] < a['rating']) ? -1 : 0));
  for (var r = 0; r < assassins.length; r++) {
    const rank = r + 1;
    assassins[r]['rank'] = rank;
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Community Rankings</title>
      </Helmet>

      <h1>Community Rankings</h1>

      <div className="textBlock">
        <p>Welcome to the community hero rankings! Have your say
          by simply voting if you think a hero should be higher
          or lower on the list. You can vote every hour!</p>
      </div>

      <FlipMove className="communityRankings">
        {assassins.map(assassin => (
          <RankRow key={"row" + assassin.id} {...assassin} />
        ))}
      </FlipMove>

    </div>
  )
}

export default CommunityRankings