import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import store from "./js/store/index"
import App from "./App"
import ReactGA from "react-ga4";

const TRACKING_ID = "G-4G0HKFNWXS"

ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);