import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { lookup, safe } from "../Utils.js"
import AssassinCard from "../components/AssassinCard"
import Holding from "../components/Holding"
import Coffee from "../components/Coffee"
import { apiConfig } from "../Utils.js"

const axios = require('axios');

const SkillDetails = () => {

  // Grab our assassin from our store
  const assassins = useSelector(
    state => state.assassins
  );

  // Get our params
  const params = useParams();
  const skillId = params.id;

  // Set up our skill object
  var preloadSkill = {
    "id": "",
    "name": "",
    "type": "",
    "description": "",
    "assassins": []
  }

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [skill, setSkill] = useState(preloadSkill);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      axios.get(`${process.env.REACT_APP_API_URL}api/skill/${skillId}`, apiConfig)
        .then(function (response) {
          setSkill(response.data);
          setLoading(false);
          hasFetchedData.current = true;
        });
    }
  }, [skillId])

  // Context menu
  const contextMenu = [];
  contextMenu.push(
    <Link to="/skills" key="prevEvent">
      <div className="prev">
        <i className="fas fa-bahai"></i>
      </div>
    </Link>
  );

  var heroesWithSkill = [];

  // List heroes with this skill
  if (typeof skill.assassins !== 'undefined') {
    for (var a = 0; a < skill.assassins.length; a++) {
      var assassinDetails = lookup(assassins, 'id', skill.assassins[a]);
      if (typeof assassinDetails !== 'undefined' && assassinDetails.available === 1) {
        heroesWithSkill.push(
          <AssassinCard id={assassinDetails.id} key={assassinDetails.id} />
        );
      }
    }
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; {skill.name}</title>
      </Helmet>

      <h1>{skill.name}</h1>

      <div className="gearProfile">

        <div className="skills">
          <div className={"skill " + skill.type}>
            <div className={"icon " + safe(skill.name)}></div>
          </div>
        </div>
        <div className="col details">
          {skill.description}
        </div>

      </div>
      <div className="heroesWithSkill">

        <h2>Heroes with this Skill</h2>

        <div className="directory">
          {heroesWithSkill}
        </div>

      </div>

      <div className="contextMenu">{contextMenu}</div>

      <Coffee />

    </div>
  )
}

export default SkillDetails;