import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { compare } from "../Utils.js"
import AssassinCard from "../components/AssassinCard"
import Coffee from "../components/Coffee"

const AssassinIndex = () => {

  // Let's hook our filters up to the state
  const [classFilter, setClassFilter] = useState("All");

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );
  assassins.sort(compare);

  // Define our gear filter categories
  const filterClassValues = [
    "1", "1-1", "1-2", "1-3",
    "2", "2-1", "2-2", "2-3",
    "3", "3-1", "3-2", "3-3"
  ]
  const filterClassNames = [
    "class1", "subclass1", "subclass2", "subclass3",
    "class2", "subclass4", "subclass5", "subclass6",
    "class3", "subclass7", "subclass8", "subclass9"
  ]

  // Create our class filter tabs
  var filterTabs = []
  for (let i = 0; i < filterClassValues.length; i++) {
    filterTabs.push(
      <div key={"tab." + i}
        onClick={() => setClassFilter(filterClassValues[i])}
        className={"subclass "+filterClassNames[i]+(classFilter === filterClassValues[i] ? " on" : "")}>
      </div>
    )
  }

  var filterPrimaryClass = classFilter.substring(0, 1)
  var filterSubClass = classFilter.substring(2, 3)

  // Compose our list
  var theList = [];
  for (var i = 0; i < assassins.length; i++) {
    // Apply our filters
    if (assassins[i].available === 1 &&
      (classFilter === "All" ||
        (parseInt(filterPrimaryClass) === assassins[i].primaryclass &&
          (filterSubClass === "" || parseInt(filterSubClass) === assassins[i].subclass)
        )
      )) {
      theList.push(
        <AssassinCard id={assassins[i].id} key={assassins[i].id} />
      );
    }
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Assassin Directory</title>
      </Helmet>

      <h1>Assassins</h1>

      <div className="filter">
        <div data-filter="All" 
          onClick={() => setClassFilter("All")}
          className={(classFilter === "All" ? "on" : "")}>All</div>
        {filterTabs}
      </div>

      <div className="directory">
        {theList}
      </div>

      <Coffee />

    </div>
  );
};

export default AssassinIndex;