import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import Holding from "../components/Holding"
import Coffee from "../components/Coffee"
import { apiConfig, safe } from "../Utils.js"

const axios = require('axios');

const SkillsIndex = () => {

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [skills, setSkills] = useState({});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/skills`, apiConfig)
      .then(function (response) {
        setSkills(response.data);
        setLoading(false);
      });
  }, [])

  // Let's hook our filters up to the state
  const [skillTypeFilter, setSkillTypeFilter] = useState("All");
  const [search, setSearch] = useState("");

  // Define our gear filter categories
  const filterCategories = [
    { displayName: 'Combat Instant', safeName: 'combat-instant' },
    { displayName: 'Combat', safeName: 'combat' },
    { displayName: 'Special Action', safeName: 'special-action' },
    { displayName: 'Passive', safeName: 'passive' },
    { displayName: 'HQ', safeName: 'hq' }
  ]

  // Create our category filter tabs
  var skillCategoryTabs = []
  for (let i = 0; i < filterCategories.length; i++) {

    // Figure out if this is the active tab
    var categoryTabActive = (skillTypeFilter === filterCategories[i].safeName ? " on" : "");

    // Add it to our tab array
    skillCategoryTabs.push(
      <div key={"gc." + i} className={"tab" + categoryTabActive}
        onClick={() => setSkillTypeFilter(filterCategories[i].safeName)}>{filterCategories[i].displayName}</div>
    )
  }

  var theList = [];
  for (var i = 0; i < skills.length; i++) {

    // Apply our filters
    if ((skillTypeFilter === "All" || skillTypeFilter === skills[i].skill_type)
      && (search === "" || skills[i].skill_name.toLowerCase().indexOf(search.toLowerCase()) !== -1)) {

      var instant = "";
      if (skills[i].skill_type === "combat-instant") {
        instant = <div className="bolt"><i className="fas fa-bolt"></i></div>
      }

      theList.push(
        <Link to={"/skills/" + skills[i].id + "/" + safe(skills[i].skill_name)} key={"s." + safe(skills[i].skill_name)}>
          <div className={"skill " + skills[i].skill_type}>
            <div className={"icon " + safe(skills[i].skill_name)}></div>
            {instant}
          </div>
        </Link>
      );
    }
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Skills Directory</title>
      </Helmet>

      <h1>Skills</h1>

      <div className="tabs">
        <div className={"tab " + (skillTypeFilter === "All" ? "on" : "")}
          onClick={() => setSkillTypeFilter("All")}>All</div>
        {skillCategoryTabs}
      </div>
      <div className="search">
        <input type="text" placeholder="Search" onChange={e => setSearch(e.target.value)} />
        <i className="fas fa-search"></i>
      </div>
      <div className="directory skills">
        {theList}
      </div>
      <Coffee />
    </div>
  )
}

export default SkillsIndex;