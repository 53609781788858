import React from "react"
import { Helmet } from "react-helmet"
import Coffee from "../components/Coffee"

const Maps = () => {

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Maps</title>
      </Helmet>

      <h1>Maps</h1>

      <p>These fantastic maps are provided by Kiryl!</p>

      <div className="maps">

        <h2>Spain</h2>

        <ul>
          <li><a href="https://imgur.com/a/qNp0PQx" target="_blank"
            rel="noopener noreferrer">Story Mission Maps (Regions 1-5)
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/3PZjm1b" target="_blank"
            rel="noopener noreferrer">Region 5 Legacy, Loot, Standard Mission Maps
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
        </ul>

        <h2>Campaigns</h2>

        <ul>
          <li><a href="https://imgur.com/a/THajWmM" target="_blank"
            rel="noopener noreferrer">Norway
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/M3lekxY" target="_blank"
            rel="noopener noreferrer">Caribbean
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/3WNaKNA" target="_blank"
            rel="noopener noreferrer">Naples
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
        </ul>

        <h2>Active Events</h2>

        <ul>
          <li><a href="https://imgur.com/a/VfMZELG" target="_blank"
            rel="noopener noreferrer">The Art of the Heist
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/d3pRZys" target="_blank"
            rel="noopener noreferrer">Dead Men's Gold
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/1a80SyE" target="_blank"
            rel="noopener noreferrer">The Eagle's Shadow
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/AVXd7ub" target="_blank"
            rel="noopener noreferrer">Echoes Through the Animus
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/4zi79x0" target="_blank"
            rel="noopener noreferrer">For Democracy!
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/JT3xcWB" target="_blank"
            rel="noopener noreferrer">Horacio's Retribution
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/O5j8qMk" target="_blank"
            rel="noopener noreferrer">The Hunter's Hounds
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/YgbnqJU" target="_blank"
            rel="noopener noreferrer">Kinslayer
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/lFnE3vK" target="_blank"
            rel="noopener noreferrer">The Mask of the Ibis
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/MyO7R5D" target="_blank"
            rel="noopener noreferrer">The Ottoman Connection
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/THajWmM" target="_blank"
            rel="noopener noreferrer">The Ravens' Wound
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/uETUCTW" target="_blank"
            rel="noopener noreferrer">A War in the Shadows
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
        </ul>

        <h2>Past Events</h2>

        <ul>
          <li><a href="https://imgur.com/a/pCm1qnu" target="_blank"
            rel="noopener noreferrer">DPS All-Stars
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/kvtsUbJ" target="_blank"
            rel="noopener noreferrer">Freerunner Frenzy
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/PktaJWz" target="_blank"
            rel="noopener noreferrer">Stealthy Operations
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
          <li><a href="https://imgur.com/a/bcmYWel" target="_blank"
            rel="noopener noreferrer">Swift Assassination
            &nbsp; <i className="fas fa-external-link-alt"></i></a></li>
        </ul>

      </div>

      <Coffee />

    </div>

  )
}

export default Maps