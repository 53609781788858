import React from "react"
import { Helmet } from "react-helmet"
import Coffee from "../components/Coffee"

const T5armors = () => {

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Tier 5 Armor</title>
      </Helmet>

      <h1>Tier 5 Armor</h1>

      <div className="directory info">

        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <td><div className="subclass subclass1"></div></td>
              <td><div className="subclass subclass2"></div></td>
              <td><div className="subclass subclass3"></div></td>
              <td><div className="subclass subclass4"></div></td>
              <td><div className="subclass subclass5"></div></td>
              <td><div className="subclass subclass6"></div></td>
              <td><div className="subclass subclass7"></div></td>
              <td><div className="subclass subclass8"></div></td>
              <td><div className="subclass subclass9"></div></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th><div className="gearCategory heavy"></div></th>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><div className="armor wartooth-splintmail"></div></td>
              <td><div className="armor blighted-plate"></div></td>
              <td><div className="armor enduring-plate"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><i className="fas fa-times"></i></td>
            </tr>
            <tr>
              <th><div className="gearCategory medium"></div></th>
              <td><div className="armor baneful-scale-mail"></div></td>
              <td><div className="armor twilight-mantle"></div></td>
              <td><div className="armor lighthawk-mail"></div></td>
              <td><div className="armor tercio-cote"></div></td>
              <td><div className="armor warriors-tunic"></div></td>
              <td><div className="armor stoic-armor"></div></td>
              <td><div className="armor saboteur-mail"></div></td>
              <td><div className="armor tacticians-mail"></div></td>
              <td><i className="fas fa-times"></i></td>
            </tr>
            <tr>
              <th><div className="gearCategory light"></div></th>
              <td><div className="armor slayers-coat"></div></td>
              <td><div className="armor deceitful-gambeson"></div></td>
              <td><div className="armor swift-eagle-armor"></div></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><div className="armor viscious-eagle-armor"></div></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-exclamation-triangle"></i></td>
              <td><div className="armor sentinels-tunic"></div></td>
              <td><div className="armor rejuvenation-cloak"></div></td>
            </tr>
          </tbody>
        </table>

      </div>

      <p><i className="fas fa-exclamation-triangle"></i> Needed &nbsp;
        <i className="fas fa-times"></i> Not needed</p>

      <Coffee />

    </div>

  )
}

export default T5armors