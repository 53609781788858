import React from "react"

const Holding = () => {

  return (
    <div className="holding">
      <div className="loader">
        <svg x="0px" y="0px" height="55" width="55">
          <polygon points="0,36.9 17.4,6.7 23.4,16.9 11.9,36.8" className="one" />
          <polygon points="18.4,28.1 24.4,17.9 41.7,48.0 29.8,48.0" className="two" />
          <polygon points="31.7,28.1 37.6,17.9 55.0,48.0 43.1,48.0" className="three" />
        </svg>
      </div>
    </div>
  );
}

export default Holding