import {
    ts, lookup, compareBonuses, friendlyViewtime, sortProperly
} from "../Utils.js"

const RUN_CLOCK = "RUN_CLOCK"
const CALC_EVENT_OUTCOMES = "CALC_EVENT_OUTCOMES"

export function runClock(startTime, endTime) {

    var now = new Date();
    var nowTime = Math.round(now.getTime() / 1000);
    var secondsRemaining = endTime - startTime;
    var displayTime = "Event Inactive";

    if (nowTime >= startTime && nowTime <= endTime) {
        secondsRemaining = endTime - nowTime;
        displayTime = friendlyViewtime(secondsRemaining) + " remaining";
    }

    var clock = {
        secondsRemaining: secondsRemaining,
        displayTime: displayTime
    }
    return { type: RUN_CLOCK, payload: clock }
}

export function eventCalculate(event, assassins) {

    const endTime = ts(event.end_date);

    // Figure out our chests
    var chests = 'normal';
    if (parseInt(event.small_chests) === 1) {
        chests = 'small';
    }

    // FHC
    var fhcCost = 0;
    if (event.fhc) {
        let fhcHero = lookup(assassins, 'id', parseInt(event.fhc))
        if (fhcHero.rarity_name === "legendary") {
            fhcCost = 2500;
        } else {
            fhcCost = 2000;
        }
    }

    // Max required power
    const maxRequiredPower = parseInt(event.levels[event.levels.length - 1].power);

    // Define incrementors
    var h = 0, i = 0, m = 0, r = 0, s = 0;

    // Get the goal
    var selectedGoal = document.querySelector(".goal.selected")
    if (selectedGoal) {
        var goal = selectedGoal.getAttribute("data-goal")
    }

    // Set up our heroes
    const eventHeroes = [
        "",
        event.sync_hero_1,
        event.sync_hero_2,
        event.sync_hero_3,
        event.sync_hero_4,
        event.sync_hero_5,
        event.sync_hero_6
    ];
    var heroes = [];
    var hero = {
        id: 0,
        assassinName: 'unknown',
        assassinDisplayName: '?',
        rarity: 0,
        rarityName: "",
        primaryClass: 1,
        subClass: 1,
        hasLockpick: 0,
        stars: 5,
        bonus: 0,
        power: maxRequiredPower,
        hidden: true
    };
    heroes.push(hero);
    for (h = 1; h <= 6; h++) {
        var heroId = parseInt(eventHeroes[h]);
        if (heroId !== 0) {

            // Grab our assassin from the array
            let assassin = lookup(assassins, 'id', parseInt(heroId))

            // Grab their stars
            var stars = 0;
            var allStars = document.querySelectorAll(".syncBonusHero[data-hero='" + heroId + "'] i");
            if (allStars) {
                for (i = 0; i < allStars.length; i++) {
                    if (allStars[i].classList.contains("fas")) {
                        stars = i + 1;
                    }
                }
            }

            // Calculate their bonus
            var baseBonus = (assassin.rarity * 25);
            var starBonus = (stars - 1) * 25;
            var totalBonus = baseBonus + starBonus;
            if (stars === 0) {
                totalBonus = 0;
            }

            // Grab their power
            var powerInput = document.querySelector(".syncBonusHero[data-hero='" + heroId + "'] .power .textInput");
            if (powerInput) {
                var power = parseInt(powerInput.value);
            }

            hero = {
                id: heroId,
                assassinName: assassin.assassin_name_safe,
                assassinDisplayName: assassin.assassin_name,
                rarity: assassin.rarity,
                rarityName: assassin.rarity_name,
                primaryClass: assassin.primaryclass,
                subClass: assassin.subclass,
                hasLockpick: assassin.has_lockpick,
                stars: stars,
                bonus: totalBonus,
                power: power,
                hidden: false
            };
            heroes.push(hero);
        }
    }
    hero = {
        id: 9991,
        assassinName: 'lockpicker',
        assassinDisplayName: 'Lockpicker',
        rarity: 0,
        rarityName: "",
        primaryClass: 1,
        subClass: 1,
        hasLockpick: 1,
        stars: 5,
        bonus: 0,
        power: maxRequiredPower,
        hidden: true
    };
    heroes.push(hero);
    hero = {
        id: 9992,
        assassinName: 'lockpicker',
        assassinDisplayName: 'Lockpicker',
        rarity: 0,
        rarityName: "",
        primaryClass: 2,
        subClass: 1,
        hasLockpick: 1,
        stars: 5,
        bonus: 0,
        power: maxRequiredPower,
        hidden: true
    };
    heroes.push(hero);
    hero = {
        id: 9993,
        assassinName: 'lockpicker',
        assassinDisplayName: 'Lockpicker',
        rarity: 0,
        rarityName: "",
        primaryClass: 3,
        subClass: 1,
        hasLockpick: 1,
        stars: 5,
        bonus: 0,
        power: maxRequiredPower,
        hidden: true
    };
    heroes.push(hero);

    // Premium?
    var premiumToggle = document.querySelector(".premiumSwitch");
    var premium = false;
    if (premiumToggle.checked) {
        premium = true;
    }

    // Rushing?
    var rushToggle = document.querySelector(".rushSwitch");
    var rushing = false;
    if (rushToggle.checked) {
        rushing = true;
    }

    // Already Joined?
    var joinedToggle = document.querySelector(".joinedSwitch");
    var alreadyJoined = false;
    if (joinedToggle.checked) {
        alreadyJoined = true;
    }

    // Initial Run Completed?
    var initialRunToggle = document.querySelector(".initialRunSwitch");
    var initialRunCompleted = false;
    if (initialRunToggle.checked) {
        initialRunCompleted = true;
    }

    // Tokens in hand
    var tokensInHandInput = document.querySelector("input[name=tokens]");
    var tokensInHand = parseInt(tokensInHandInput.value);

    // Current score
    var currentScoreInput = document.querySelector("input[name=nodes]");
    var currentScore = parseInt(currentScoreInput.value);

    // Chosen Difficulty
    var tierTab = document.querySelector(".tab.on")
    var chosenDifficulty = parseInt(tierTab.getAttribute("data-tier"));

    // Join time
    var joinTimeInput = document.querySelector("input[name=joinTime]");
    var hoursRemaining = parseInt(joinTimeInput.value);
    var joinTime = endTime - (3600 * hoursRemaining) + 60;

    var milliseconds = joinTime * 1000;
    var dateObject = new Date(milliseconds);

    var joinDateTime = dateObject.toLocaleString("en-US", { weekday: "long" }) +
        " at " + dateObject.toLocaleString("en-US", { hour: "numeric" });
    var hours = "hours";
    if (hoursRemaining === 1) {
        hours = 'hour';
    }
    var joinHoursRemaining = hoursRemaining + " " + hours + " remaining";

    // Calculate max sync bonus
    var bonuses = [];
    for (h = 0; h < heroes.length; h++) {
        bonuses.push(heroes[h].bonus);
    }
    bonuses.sort(sortProperly);
    bonuses.reverse();
    var maxSyncBonus = parseInt(bonuses[0] + bonuses[1] + bonuses[2]);

    // Set up our missions
    var missions = [];
    var missionKey = "";
    var missionType = "";
    var mission = {};
    for (m = 0; m < event.levels.length; m++) {

        // Establish both high sync and lockpick missions
        for (let u = 1; u < 3; u++) {

            // Define a key upfront
            missionKey = event.levels[m].level + "." +
                event.levels[m].tier + ".";
            if (u === 1) {
                missionKey += "h";
                missionType = "highSyncTeam";
            } else {
                missionKey += "l";
                missionType = "lockpickerTeam";
            }

            // Calculate our mission variables
            mission = {
                key: missionKey,
                type: missionType,
                level: event.levels[m].level,
                tier: event.levels[m].tier,
                primaryClass1: event.levels[m].class1,
                subClass1: event.levels[m].subclass1,
                primaryClass2: event.levels[m].class2,
                subClass2: event.levels[m].subclass2,
                power: event.levels[m].power,
                tokens: event.levels[m].tokens,
                chests: event.levels[m].chests,
                base: event.levels[m].base,
                fht: event.levels[m].hero_cube_tokens,
                hero1: 0,
                hero2: 0,
                hero3: 0,
                hero1alts: [],
                hero2alts: [],
                hero3alts: [],
                syncBonus: 0,
                missionNodes: 0,
                chestNodes: 0,
                totalNodes: 0,
                eff: 0,
                rushNodes: 0,
                rushEff: 0,
                mostEfficient: 0,
                chosen: 0
            };
            missions.push(mission);
        }
    }

    // Plan our missions (planMissions)
    var outcomes = {
        nodes: {
            initialRunTokens: 0,
            initialRunNodes: 0,
            initialRunFHT: 0,
            numDailyRewards: 0,
            dailyRewardNodes: 0,
            refills: [{
                refillNum: 0,
                farmRuns: 0,
                farmTokens: 0,
                farmNodes: 0,
                farmFHT: 0,
                remainderMission: 0,
                remainderMissionTokens: 0,
                remainderMissionNodes: 0,
                remainderMissionFHT: 0,
                stars: 0
            }]
        },
        stars: []
    }

    // Define our variables
    var reqId = "Any",
        reqType = "Any",
        classId = null,
        subClassId = null,
        looking = true,
        eligible = true,
        missionSyncBonus = 0,
        bonusMultiplier = 0,
        chestValue = 0,
        chestNodes = 0,
        totalNodes = 0,
        eff = 0,
        rushNodes = 0,
        rushEff = 0;

    // Get our sync heroes
    let heroesInOrder = heroes;
    heroesInOrder.sort(compareBonuses);
    heroesInOrder.reverse();

    // For each of our missions
    for (m = 0; m < missions.length; m++) {

        // Clean down our teams first
        missions[m].hero1 = 0;
        missions[m].hero2 = 0;
        missions[m].hero3 = 0;

        // Also clean down sync bonus
        missions[m].syncBonus = 0;

        // For our high sync team...
        if (missions[m].type === "highSyncTeam") {

            // Set up our used heroes array
            const usedHeroes = [];

            // Loop through each of our 3 slots
            for (s = 1; s <= 3; s++) {

                // Determine which class/subclass we need for this slot
                reqId = "Any";
                reqType = "Any";
                classId = null;
                subClassId = null;
                reqType = "Any";
                if (s === 1) {
                    classId = missions[m].primaryClass1;
                    subClassId = missions[m].subClass1;
                }
                if (s === 2) {
                    classId = missions[m].primaryClass2;
                    subClassId = missions[m].subClass2;
                }
                if (classId !== null) {
                    reqType = "class";
                    reqId = classId;
                }
                if (subClassId !== null) {
                    reqType = "subclass";
                    reqId = subClassId;
                }

                // Loop through our heroes until we find a suitor
                looking = true;
                eligible = true;

                for (i = 0; i < heroesInOrder.length; i++) {

                    if (looking === true) {

                        // Let's look up their original ID
                        heroId = 0;
                        for (h = 0; h < heroes.length; h++) {
                            if (heroes[h].assassinName === heroesInOrder[i].assassinName) {
                                heroId = heroes[h].id;
                            }
                        }

                        eligible = true;

                        // Are they still available?
                        if (usedHeroes.indexOf(heroId) !== -1) {
                            eligible = false;
                        }

                        // Do we own them?
                        if (heroesInOrder[i].stars < 1) {
                            eligible = false;
                        }

                        // Are they powerful enough?
                        if (parseInt(heroesInOrder[i].power) < parseInt(missions[m].power)) {
                            eligible = false;
                        }

                        // Are they correct class/subclass?
                        if (reqId !== "Any") {
                            if (reqType === "class") {
                                if (parseInt(heroesInOrder[i].primaryClass) !== parseInt(reqId)) {
                                    eligible = false;
                                }
                            } else {
                                if (parseInt(heroesInOrder[i].subClass) !== parseInt(reqId)) {
                                    eligible = false;
                                }
                            }
                        }

                        // Don't use generics for high sync team
                        if (parseInt(heroesInOrder[i].bonus) === 0) {
                            eligible = false;
                        }

                        // We found one!
                        if (eligible === true) {
                            looking = false;
                            usedHeroes.push(heroId);
                        }
                    }
                }
            }
            // Assign our heroes to our slots
            missionSyncBonus = 0;
            for (i = 0; i < usedHeroes.length; i++) {
                s = i + 1;
                if (s === 1) {
                    missions[m].hero1 = usedHeroes[i];
                }
                if (s === 2) {
                    missions[m].hero2 = usedHeroes[i];
                }
                if (s === 3) {
                    missions[m].hero3 = usedHeroes[i];
                }
                if (s < 4) {
                    // Grab our assassin from the array
                    let usedHero = lookup(heroes, 'id', parseInt(usedHeroes[i]))

                    missionSyncBonus += parseInt(usedHero.bonus);
                }
            }
            missions[m].syncBonus = missionSyncBonus;
        }

        // For our lockpicker team...
        if (missions[m].type === "lockpickerTeam") {

            // Define incrementors
            var d = 0;
            var e = 0;

            // Define our variables
            var n = m - 1;
            var lockpickerOnTeam = 0;
            var maxSyncTeamComplete = 1;
            var thisHeroObj = "";
            var highestLockpicker = -1;
            var subIn = -1;
            eligible = true;
            var substituteDifference = 0;
            var thisSubObj = "";
            var smallestDrop = 250;
            var bestSub = 0;
            var bestSubSlot = 0;

            let hero1 = lookup(heroes, 'id', parseInt(missions[n].hero1))
            let hero2 = lookup(heroes, 'id', parseInt(missions[n].hero2))
            let hero3 = lookup(heroes, 'id', parseInt(missions[n].hero3))

            // If no chests then don't waste time
            if (missions[m].chests > 0) {

                const maxSyncHeroes = [missions[n].hero1, missions[n].hero2, missions[n].hero3];
                const subOptions = [];
                const lockpickTeam = [];

                // Do we currently have a lockpicker on the team?
                n = m - 1;
                lockpickerOnTeam = 0;
                if (parseInt(hero1.hasLockpick) === 1 ||
                    parseInt(hero2.hasLockpick) === 1 ||
                    parseInt(hero3.hasLockpick) === 1) {
                    lockpickerOnTeam = 1;
                }
                // Is the max sync team complete?
                maxSyncTeamComplete = 1;
                if (parseInt(hero1.bonus) < 1 ||
                    parseInt(hero2.bonus) < 1 ||
                    parseInt(hero3.bonus) < 1) {
                    maxSyncTeamComplete = 0;
                }

                // If we don't have a lockpicker let's try to get one
                if (lockpickerOnTeam === 0 && maxSyncTeamComplete === 1) {

                    // Loop through each of our 3 slots
                    for (s = 1; s <= 3; s++) {

                        // Determine which class/subclass we need for this slot
                        reqId = "Any";
                        reqType = "Any";
                        classId = null;
                        subClassId = null;
                        reqType = "Any";
                        if (s === 1) {
                            classId = missions[m].primaryClass1;
                            subClassId = missions[m].subClass1;
                        }
                        if (s === 2) {
                            classId = missions[m].primaryClass2;
                            subClassId = missions[m].subClass2;
                        }
                        if (classId !== null) {
                            reqType = "class";
                            reqId = classId;
                        }
                        if (subClassId !== null) {
                            reqType = "subclass";
                            reqId = subClassId;
                        }

                        // Find the best substitute available
                        highestLockpicker = -1;
                        subIn = -1;
                        for (i = 0; i < heroesInOrder.length; i++) {

                            // Let's look up their original ID
                            heroId = 0;
                            for (h = 0; h < heroes.length; h++) {
                                if (heroes[h].assassinName === heroesInOrder[i].assassinName) {
                                    heroId = h;
                                }
                            }

                            if (parseInt(heroesInOrder[i].hasLockpick) === 1) {

                                eligible = true;

                                // Do we own them?
                                if (heroesInOrder[i].stars < 1) {
                                    eligible = false;
                                }

                                // Are they powerful enough?
                                if (parseInt(heroesInOrder[i].power) < parseInt(missions[m].power)) {
                                    eligible = false;
                                }

                                // Are they correct class/subclass?
                                if (reqId !== "Any") {
                                    if (reqType === "class") {
                                        if (parseInt(heroesInOrder[i].primaryClass) !== parseInt(reqId)) {
                                            eligible = false;
                                        }
                                    } else {
                                        if (parseInt(heroesInOrder[i].subClass) !== parseInt(reqId)) {
                                            eligible = false;
                                        }
                                    }
                                }

                                // If they're the highest so far and they're eligible
                                if (heroesInOrder[i].bonus > highestLockpicker && eligible === true) {
                                    highestLockpicker = heroesInOrder[i].bonus;
                                    subIn = heroId;
                                }
                            }
                        }

                        if (subIn > -1) {
                            let sMinusOne = s - 1;

                            let hero = lookup(heroes, 'id', parseInt(maxSyncHeroes[sMinusOne]))

                            substituteDifference = hero.bonus - heroes[subIn].bonus;
                            thisSubObj = {
                                assassinId: heroes[subIn].id,
                                subSlot: sMinusOne,
                                bonus: heroes[subIn].bonus,
                                difference: substituteDifference,
                                hasLockpick: heroes[subIn].hasLockpick
                            };
                            subOptions.push(thisSubObj);
                        }
                    }

                    smallestDrop = 250;
                    bestSub = 0;
                    for (d = 0; d < subOptions.length; d++) {
                        if (subOptions[d].difference < smallestDrop) {
                            smallestDrop = subOptions[d].difference;
                            bestSub = d;
                            bestSubSlot = subOptions[d].subSlot;
                        }
                    }

                    for (e = 0; e < 3; e++) {
                        if (e === bestSubSlot) {
                            thisHeroObj = subOptions[bestSub].assassinId;
                        } else {
                            thisHeroObj = maxSyncHeroes[e];
                        }
                        lockpickTeam.push(thisHeroObj);
                    }

                    // Assign our heroes to our slots
                    missionSyncBonus = 0;
                    for (i = 0; i < lockpickTeam.length; i++) {
                        s = i + 1;
                        if (s === 1) {
                            missions[m].hero1 = lockpickTeam[i];
                        }
                        if (s === 2) {
                            missions[m].hero2 = lockpickTeam[i];
                        }
                        if (s === 3) {
                            missions[m].hero3 = lockpickTeam[i];
                        }
                        if (s < 4) {

                            let hero = lookup(heroes, 'id', parseInt(lockpickTeam[i]))

                            missionSyncBonus += parseInt(hero.bonus);
                        }
                    }
                    missions[m].syncBonus = missionSyncBonus;
                }
            }
        }

        // Calculate our mission nodes
        var nodes = parseInt(missions[m].base);
        var missionNodes = nodes * ((missions[m].syncBonus + 100) / 100);
        missions[m].missionNodes = missionNodes;

        // Do the chest math
        chestNodes = 0;
        if (parseInt(missions[m].chests) > 0) {

            bonusMultiplier = 100 + missions[m].syncBonus;

            lockpickerOnTeam = 0;

            let hero1 = lookup(heroes, 'id', parseInt(missions[m].hero1))
            let hero2 = lookup(heroes, 'id', parseInt(missions[m].hero2))
            let hero3 = lookup(heroes, 'id', parseInt(missions[m].hero3))

            if (parseInt(hero1.hasLockpick) === 1 ||
                parseInt(hero2.hasLockpick) === 1 ||
                parseInt(hero3.hasLockpick) === 1) {
                lockpickerOnTeam = 1;
            }
            if (chests === "small") {

                if (lockpickerOnTeam === 1) {
                    chestValue = ((((0.48 * 50) + (0.32 * 75) + (0.2 * 100)) * bonusMultiplier) * 1.2) / 100;
                } else {
                    chestValue = 0.95 * ((((0.333 * 5) + (0.333 * 8) + (0.333 * 10)) * bonusMultiplier) / 100);
                }

            }
            if (chests === "normal") {

                if (lockpickerOnTeam === 1) {
                    chestValue = ((((0.75 * 300) + (0.25 * 600) + (0.05 * 900)) * bonusMultiplier) * 1.2) / 105;
                } else {
                    chestValue = 0.95 * ((((0.75 * 300) + (0.25 * 600) + (0.05 * 900)) * bonusMultiplier) / 105);
                }
            }

            chestNodes = chestValue * missions[m].chests;
        }
        missions[m].chestNodes = chestNodes;

        // Total nodes
        totalNodes = missions[m].missionNodes + missions[m].chestNodes;
        missions[m].totalNodes = totalNodes;

        // Efficiency
        eff = totalNodes / missions[m].tokens;
        missions[m].eff = eff;

        // Rush Nodes
        rushNodes = missions[m].missionNodes;
        missions[m].rushNodes = rushNodes;

        // Rush Efficiency
        rushEff = rushNodes / missions[m].tokens;
        missions[m].rushEff = rushEff;

        // Alternates

        // Set up our used heroes array
        const usedHeroes = [missions[m].hero1, missions[m].hero2, missions[m].hero3];

        // Loop through each of our 3 slots
        for (s = 1; s <= 3; s++) {
            var alts = [];
            let sMinusOne = s - 1;

            // Determine which class/subclass we need for this slot
            reqId = "Any";
            reqType = "Any";
            classId = null;
            subClassId = null;
            reqType = "Any";
            if (s === 1) {
                classId = missions[m].primaryClass1;
                subClassId = missions[m].subClass1;
            }
            if (s === 2) {
                classId = missions[m].primaryClass2;
                subClassId = missions[m].subClass2;
            }
            if (classId !== null) {
                reqType = "class";
                reqId = classId;
            }
            if (subClassId !== null) {
                reqType = "subclass";
                reqId = subClassId;
            }

            // Loop through our heroes until we find a suitor
            eligible = true;
            for (i = 0; i < heroesInOrder.length; i++) {

                // Let's look up their original ID
                heroId = 0;
                for (h = 0; h < heroes.length; h++) {
                    if (heroes[h].assassinName === heroesInOrder[i].assassinName) {
                        heroId = heroes[h].id;
                    }
                }

                eligible = true;

                // Are they still available?
                if (usedHeroes.indexOf(heroId) !== -1) {
                    eligible = false;
                }

                // Do we own them?
                if (heroesInOrder[i].stars < 1) {
                    eligible = false;
                }

                // Are they powerful enough?
                if (parseInt(heroesInOrder[i].power) < parseInt(missions[m].power)) {
                    eligible = false;
                }

                // Are they correct class/subclass?
                if (reqId !== "Any") {
                    if (reqType === "class") {
                        if (parseInt(heroesInOrder[i].primaryClass) !== parseInt(reqId)) {
                            eligible = false;
                        }
                    } else {
                        if (parseInt(heroesInOrder[i].subClass) !== parseInt(reqId)) {
                            eligible = false;
                        }
                    }
                }

                // Don't use generics as alts
                if (heroesInOrder[i].bonus === 0) {
                    eligible = false;
                }

                // Get the sync bonus of our first choice
                for (let j = 0; j < heroesInOrder.length; j++) {

                    var usedHeroObj = heroes.filter(obj => {
                        return obj.id === parseInt(usedHeroes[sMinusOne])
                    })
                    let usedHero = usedHeroObj[0];

                    if (heroesInOrder[j].assassinName === usedHero.assassinName) {
                        var compareBonus = heroesInOrder[j].bonus;
                        var compareLockpick = heroesInOrder[j].hasLockpick;
                    }
                }

                // Do they match the power of the first choice?
                if (compareBonus > heroesInOrder[i].bonus) {
                    eligible = false;
                }

                // Do they equal the lockpick ability?
                if (missions[m].chests > 0 && compareLockpick === 1 && heroesInOrder[i].hasLockpick === 0) {
                    eligible = false;
                }

                // It's not a 0 syncer is it?
                if (compareBonus === 0) {
                    eligible = false;
                }

                // We found one!
                if (eligible === true) {
                    alts.push(heroId);
                }
            }
            if (s === 1) { missions[m].hero1alts = alts; }
            if (s === 2) { missions[m].hero2alts = alts; }
            if (s === 3) { missions[m].hero3alts = alts; }
        }
    }

    // Let's decide on which mission, level and tier is best
    var bestMission = -1,
        bestMissionEff = -1,
        bestLevel = -1,
        bestTier = -1,
        bestRushMission = -1,
        bestRushLevel = -1,
        bestRushTier = -1,
        bestRushMissionEff = -1;

    // For nodes we choose the best mission based on node efficiency
    if (goal === "nodes" || goal === "stars") {

        for (m = 0; m < missions.length; m++) {
            if (m % 2 !== 0) {
                n = m - 1;
                if (missions[n].eff >= missions[m].eff) {
                    missions[n].mostEfficient = true;
                    missions[m].mostEfficient = false;
                } else {
                    missions[n].mostEfficient = false;
                    missions[m].mostEfficient = true;
                }
            }
            if (missions[m].eff >= bestMissionEff) {
                bestMission = m;
                bestLevel = missions[m].level;
                bestTier = missions[m].tier;
                bestMissionEff = missions[m].eff;
            }
            if (missions[m].rushEff >= bestRushMissionEff) {
                bestRushMission = m;
                bestRushLevel = missions[m].level;
                bestRushTier = missions[m].tier;
                bestRushMissionEff = missions[m].rushEff;
            }
        }

        // Overwrite best mission if difficulty is chosen
        if (chosenDifficulty !== 0) {
            bestMissionEff = -1;
            for (m = 0; m < missions.length; m++) {
                if (missions[m].eff >= bestMissionEff && parseInt(missions[m].tier) === chosenDifficulty) {
                    bestMission = m;
                    bestLevel = missions[m].level;
                    bestTier = missions[m].tier;
                    bestMissionEff = missions[m].eff;
                }
                if (missions[m].rushEff >= bestRushMissionEff && missions[m].tier === chosenDifficulty) {
                    bestRushMission = m;
                    bestRushLevel = missions[m].level;
                    bestRushTier = missions[m].tier;
                    bestRushMissionEff = missions[m].rushEff;
                }
            }
        }
    }

    // For FHC we want to run as late a mission as possible
    if (goal === "fhc") {
        for (m = missions.length - 1; m >= 0; m--) {
            if (m % 2 !== 0) {
                n = m - 1;
                if (missions[n].eff >= missions[m].eff) {
                    missions[n].mostEfficient = true;
                    missions[m].mostEfficient = false;
                } else {
                    missions[n].mostEfficient = false;
                    missions[m].mostEfficient = true;
                }
            }
            if (missions[m].syncBonus > 0) {
                bestMission = m;
                bestLevel = missions[m].level;
                bestTier = missions[m].tier;
                bestMissionEff = missions[m].eff;
                bestRushMission = m;
                bestRushLevel = missions[m].level;
                bestRushTier = missions[m].tier;
                bestRushMissionEff = missions[m].rushEff;
                break;
            }
        }
        if (bestMission === -1) {
            for (m = missions.length - 1; m >= 0; m--) {
                bestMission = m;
                bestLevel = missions[m].level;
                bestTier = missions[m].tier;
                bestMissionEff = missions[m].eff;
                bestRushMission = m;
                bestRushLevel = missions[m].level;
                bestRushTier = missions[m].tier;
                bestRushMissionEff = missions[m].rushEff;
                break;
            }
        }

        // Overwrite best mission if difficulty is chosen
        if (chosenDifficulty !== 0) {
            for (m = missions.length - 1; m >= 0; m--) {
                if (missions[m].syncBonus > 0 &&
                    chosenDifficulty === parseInt(missions[m].tier)) {
                    bestMission = m;
                    bestLevel = missions[m].level;
                    bestTier = missions[m].tier;
                    bestMissionEff = missions[m].eff;
                    bestRushMission = m;
                    bestRushLevel = missions[m].level;
                    bestRushTier = missions[m].tier;
                    bestRushMissionEff = missions[m].rushEff;
                    break;
                }
            }
        }
    }

    // For stars we want to know what missions we can run
    if (goal === "stars") {
        var starMissions = [];
        var starMission = "";
        for (m = 0; m < missions.length; m++) {
            if (missions[m].type === "highSyncTeam" && missions[m].syncBonus > 0) {
                starMission = {
                    missionId: m,
                    tokens: missions[m].tokens,
                    done: false
                };
                starMissions.push(starMission);
            }
        }
        if (starMissions.length === 0) {
            for (m = 0; m < missions.length; m++) {
                if (missions[m].type === "highSyncTeam") {
                    starMission = {
                        missionId: m,
                        tokens: missions[m].tokens,
                        done: false
                    };
                    starMissions.push(starMission);
                }
            }
        }
        outcomes.stars = starMissions;
    }

    // Figure out if we're looking at best or chosen tier
    var desiredTier = parseInt(bestTier)
    if (chosenDifficulty !== 0) {
        desiredTier = chosenDifficulty;
    }

    // Let's choose all the missions which match our desired tier
    for (m = 0; m < missions.length; m++) {
        if (parseInt(missions[m].tier) === desiredTier && missions[m].mostEfficient === true) {
            missions[m].chosen = true;
        } else {
            missions[m].chosen = false;
        }
    }

    // Outcomes! (calculateOutcomes)

    if (rushing === true) {
        bestLevel = bestRushLevel;
        bestTier = bestRushTier;
        bestMission = bestRushMission;
    }

    // Calculate most efficient rush mission
    var optimalRushMissionNodes = parseInt(missions[bestRushMission].rushNodes);

    // Figure out the initial run
    var initialRunTokens = 0;
    var initialRunNodes = 0;
    var initialRunFHT = 0;

    if (initialRunCompleted === false) {
        for (m = 0; m <= bestMission; m++) {
            // Make sure we're only looking at relevant missions
            if (missions[m].chosen === true) {
                initialRunTokens += parseInt(missions[m].tokens);
                initialRunNodes += parseInt(missions[m].totalNodes);
                initialRunFHT += parseInt(missions[m].fht);
            }
        }
    }
    outcomes.nodes.initialRunTokens = initialRunTokens;
    outcomes.nodes.initialRunNodes = initialRunNodes;
    outcomes.nodes.initialRunFHT = initialRunFHT;

    // Daily objective rewards
    var numDailyRewards = 1;

    var dailyRewardAmount = 1000;
    if (premium === true) {
        dailyRewardAmount = 1200;
    }
    var dailyRewardNodes = dailyRewardAmount;

    var hourCheckpoints = {
        1: 12,
        2: 36,
        3: 60,
        4: 84,
        5: 108
    };

    var remainingTime = parseInt(endTime) - parseInt(joinTime);
    if (alreadyJoined === true) {
        remainingTime = parseInt(endTime) - Math.floor(Date.now() / 1000);
    }

    for (h = 1; h <= 4; h++) {
        if (remainingTime >= (hourCheckpoints[h] * 60 * 60)) {
            numDailyRewards++;
            dailyRewardNodes += dailyRewardAmount;
        }
    }
    outcomes.nodes.numDailyRewards = numDailyRewards;
    outcomes.nodes.dailyRewardNodes = dailyRewardNodes;

    // Figure out recharge rate
    var rechargeRatePerSec = 100 / 86400;
    if (premium === true) {
        rechargeRatePerSec = 120 / 86400;
    }

    var optimalMissionTokens = missions[bestMission].tokens;
    var optimalMissionNodes = missions[bestMission].totalNodes;
    var optimalMissionFHT = missions[bestMission].fht;

    if (rushing === true) {
        optimalMissionNodes = missions[bestMission].totalRushNodes;
    }

    // Clean down stars
    for (m = 0; m < outcomes.stars.length; m++) {
        outcomes.stars[m].done = false;
    }

    // Now for refills!
    for (r = 0; r <= 5; r++) {

        var remainderMission = 0;
        var remainderMissionTokens = 0;
        var remainderMissionNodes = 0;
        var remainderMissionFHT = 0;
        remainingTime = parseInt(endTime) - parseInt(joinTime);
        if (alreadyJoined === true) {
            remainingTime = parseInt(endTime) - Math.floor(Date.now() / 1000);
        }
        var tokensToCome = Math.floor(rechargeRatePerSec * remainingTime);
        var tokensForThisRefill = parseInt(tokensInHand) + parseInt(tokensToCome) + (r * 100);

        if (initialRunCompleted === false) {
            tokensForThisRefill -= initialRunTokens;
        } else {
            initialRunNodes = 0;
        }
        var farmRuns = Math.floor(tokensForThisRefill / optimalMissionTokens);
        var farmTokens = farmRuns * optimalMissionTokens;
        var farmNodes = farmRuns * optimalMissionNodes;
        var farmFHT = farmRuns * optimalMissionFHT;
        if (rushing === true) {
            farmNodes = farmRuns * optimalRushMissionNodes;
        }
        var remainderTokens = tokensForThisRefill - (farmRuns * optimalMissionTokens);

        // Find a remainder mission
        for (m = 0; m <= bestMission; m++) {
            if (missions[m].chosen === true && missions[m].tokens <= remainderTokens) {
                remainderMission = missions[m].level;
                remainderMissionTokens = missions[m].tokens;
                remainderMissionFHT = missions[m].fht;
                if (rushing === true) {
                    remainderMissionNodes = parseInt(missions[m].rushNodes);
                } else {
                    remainderMissionNodes = parseInt(missions[m].totalNodes);
                }
            }
        }

        // Stars!
        var starTokens = tokensForThisRefill;
        var starsThisRefill = 0;
        for (m = 0; m < outcomes.stars.length; m++) {
            if (outcomes.stars[m].done === false) {
                starTokens -= outcomes.stars[m].tokens;
                if (starTokens >= 0) {
                    starsThisRefill += 3;
                    outcomes.stars[m].done = true;
                } else {
                    break;
                }
            } else {
                starsThisRefill += 3;
            }
        }

        // Add it all up!
        var grandTotal = parseInt(currentScore) + initialRunNodes + dailyRewardNodes + farmNodes + remainderMissionNodes;
        var fhtTotal = parseInt(initialRunFHT) + parseInt(farmFHT) + parseInt(remainderMissionFHT);

        // Num cubes
        var numCubes = Math.floor(fhtTotal / fhcCost);
        var leftOver = fhtTotal - (numCubes * fhcCost);

        outcomes.nodes.refills[r] = {
            refillNum: r,
            farmRuns: farmRuns,
            farmTokens: farmTokens,
            farmNodes: farmNodes,
            farmFHT: farmFHT,
            remainderMission: remainderMission,
            remainderMissionTokens: remainderMissionTokens,
            remainderMissionNodes: remainderMissionNodes,
            remainderMissionFHT: remainderMissionFHT,
            grandTotal: grandTotal,
            numCubes: numCubes,
            leftOver: leftOver,
            stars: starsThisRefill
        }
    }

    var calc = {
        goal: goal,
        maxSyncBonus: maxSyncBonus,
        bestLevel: bestLevel,
        bestTier: bestTier,
        rushing: rushing,
        alreadyJoined: alreadyJoined,
        initialRunCompleted: initialRunCompleted,
        joinDateTime: joinDateTime,
        joinHoursRemaining: joinHoursRemaining,
        missions: missions,
        outcomes: outcomes
    }

    return { type: CALC_EVENT_OUTCOMES, payload: calc }
}