import React from "react"
import { Helmet } from "react-helmet"
// import Ad from "../components/Ad"

const VersionLog = () => {
  return (
    <>
      <div className="textBlock">

        <Helmet>
          <title>Assassin's Creed Rebellion &#47;&#47; Version Log</title>
        </Helmet>

        <h1>Version Log</h1>

        <div className="entry">
          <h3>2.14</h3>
          <h4>&#47;&#47; January 31st 2023</h4>
          <p>Updated gauntlet calculator to factor in sync bonus heroes.</p>
        </div>

        <div className="entry">
          <h3>2.13</h3>
          <h4>&#47;&#47; January 30th 2023</h4>
          <p>Updated gauntlet details page to include sync heroes.</p>
        </div>

        <div className="entry">
          <h3>2.12</h3>
          <h4>&#47;&#47; January 6th 2023</h4>
          <p>Added event leaderboard and progression heroes to the calendar
            and archive.</p>
        </div>

        <div className="entry">
          <h3>2.11</h3>
          <h4>&#47;&#47; December 12th 2022</h4>
          <p>Added a community creators page and tidied up some typos and 
            broken links.</p>
        </div>

        <div className="entry">
          <h3>2.10</h3>
          <h4>&#47;&#47; November 26th 2022</h4>
          <p>Updated the archive with sticky months and years, and resolved
            the following two bugs;</p>
            <ul>
              <li>Gauntlet links weren't working</li>
              <li>The first event was not showing</li>
            </ul>
        </div>

        <div className="entry">
          <h3>2.9</h3>
          <h4>&#47;&#47; November 14th 2022</h4>
          <p>Added all the functionality for the new Gauntlet mode.</p>
        </div>

        <div className="entry">
          <h3>2.8</h3>
          <h4>&#47;&#47; August 26th 2022</h4>
          <p>Updated the menu to include all pages and made it a full-page
            overlay. Also updated the default theme to 'Dead of Night'
          </p>
        </div>

        <div className="entry">
          <h3>2.7.3</h3>
          <h4>&#47;&#47; June 20th 2022</h4>
          <p>All things Xenia, and also tweaked the Dead of Night theme.</p>
        </div>

        <div className="entry">
          <h3>2.7.2</h3>
          <h4>&#47;&#47; May 12th 2022</h4>
          <p>Updated the event heroes page following changes to Echoes
            and Dead Men's Gold.</p>
        </div>

        <div className="entry">
          <h3>2.7.1</h3>
          <h4>&#47;&#47; May 4th 2022</h4>
          <p>Updated the assassin stat rankings to be more accurate 
            and only reflect stats with values greater than zero.
          </p>
        </div>

        <div className="entry">
          <h3>2.7</h3>
          <h4>&#47;&#47; March 30th 2022</h4>
          <p>Added Community Rankings where the community can upvote
            and downvote their favourite heroes to celebrate getting
            100 heroes in the game.</p>
        </div>

        <div className="entry">
          <h3>2.6.5</h3>
          <h4>&#47;&#47; February 9th 2022</h4>
          <p>Fixed a problem with the calculator which was resulting
            in incorrectly ignoring lockpickers.
          </p>
        </div>

        <div className="entry">
          <h3>2.6.3</h3>
          <h4>&#47;&#47; January 10th 2022</h4>
          <p>New artwork for The Art of the Heist!</p>
        </div>

        <div className="entry">
          <h3>2.6.2</h3>
          <h4>&#47;&#47; January 9th 2022</h4>
          <p>Added La Volpe and Randvi to the event heroes page.</p>
        </div>

        <div className="entry">
          <h3>2.6.1</h3>
          <h4>&#47;&#47; December 17th 2021</h4>
          <p>Fixed a bug with the calculator where toggling the rush button
            would not update the token display.</p>
        </div>

        <div className="entry">
          <h3>2.6</h3>
          <h4>&#47;&#47; November 24th 2021</h4>
          <p>Added skills to the site! This includes a new skills page
            with filters and a search, a skill details page and a new
            section on the assassin details page.</p>
        </div>

        <div className="entry">
          <h3>2.5.6</h3>
          <h4>&#47;&#47; November 21st 2021</h4>
          <p>Added a class/subclass filter to the stat rankings page.</p>
        </div>

        <div className="entry">
          <h3>2.5.5</h3>
          <h4>&#47;&#47; September 3rd 2021</h4>
          <p>Added the Skyhook and Cleric's Battle Mace to the site
            and updated the T5 weapons page accordingly.</p>
        </div>

        <div className="entry">
          <h3>2.5.4</h3>
          <h4>&#47;&#47; August 20th 2021</h4>
          <p>Added Vek's awesome artwork to the site.</p>
        </div>

        <div className="entry">
          <h3>2.5.3</h3>
          <h4>&#47;&#47; August 9th 2021</h4>
          <p>Added assassin reviews to the assassin details page.</p>
        </div>

        <div className="entry">
          <h3>2.5.2</h3>
          <h4>&#47;&#47; August 4th 2021</h4>
          <p>Added a new summer theme and replaced the Auditore theme.</p>
        </div>

        <div className="entry">
          <h3>2.5.1</h3>
          <h4>&#47;&#47; July 21st 2021</h4>
          <p>Added a couple of neat info pages that can be accessed from
              the home page.</p>
          <p>Firstly a map index page with links to all of the awesome
              maps Kiryl has put together, and secondly a new page with all
              of the stats for the bounty missions in case people want to
              use those to work towards animus challenges or achievements.
          </p>
        </div>

        <div className="entry">
          <h3>2.5</h3>
          <h4>&#47;&#47; July 19th 2021</h4>
          <p>Updated the site to gather all data from an API so the site can
            grab new event and hero data without code deployment.</p>
          <p>Also added new info pages which can be accessed from the home
            page. These include:</p>
          <ul>
            <li>Event heroes</li>
            <li>Tier 5 Weapons</li>
            <li>Tier 5 Armor</li>
          </ul>
        </div>

        <div className="entry">
          <h3>2.4.7</h3>
          <h4>&#47;&#47; April 22nd 2021</h4>
          <p>Sigurd!</p>
        </div>

        <div className="entry">
          <h3>2.4.6</h3>
          <h4>&#47;&#47; March 9th 2021</h4>
          <p>All things 3.0.0!</p>
        </div>

        <div className="entry">
          <h3>2.4.5</h3>
          <h4>&#47;&#47; February 1st 2021</h4>
          <p>A few bits and bobs...</p>
          <ul>
            <li>Updated the calculator to show the best farming mission
              respective of chosen difficulty</li>
            <li>Updated the current Dead Men's Gold to three days</li>
            <li>Blue-eyed Evie!</li>
          </ul>
        </div>

        <div className="entry">
          <h3>2.4.4</h3>
          <h4>&#47;&#47; January 23rd 2021</h4>
          <p>Fixed another couple of bugs; found where Adéwalé had been
            hiding and returned him to the assassin's directory, and
            sorted an issue the prevented the appropriate eligible
            heroes from being listed on the gear details screen.</p>
        </div>

        <div className="entry">
          <h3>2.4.3</h3>
          <h4>&#47;&#47; January 17th 2021</h4>
          <p>Fixed a couple of bugs; firstly in the calculator where
            it wasn't showing the correct farm mission when rushing.
            Secondly fixed the assassin power calculator getting
            messed up by elite training.</p>
        </div>

        <div className="entry">
          <h3>2.4.2</h3>
          <h4>&#47;&#47; January 14th 2021</h4>
          <p>All things Cleopatra added to the site.</p>
        </div>

        <div className="entry">
          <h3>2.4.1</h3>
          <h4>&#47;&#47; January 14th 2021</h4>
          <p>Updated the FAQ and beginner's guide based on some community
            feedback. Also added horizontal scrolling for tables on the FAQ
            which exceeded the width of the page on mobile views.</p>
        </div>

        <div className="entry">
          <h3>2.4</h3>
          <h4>&#47;&#47; January 13th 2021</h4>
          <p>I gave the site engine a bit of an overhaul in an
            attempt to improve performance. The menu has also
            been tweaked and de-cluttered slightly so hopefully
            the useful pages are easier to find.</p>
          <p>I've also added a new FAQ page, and a beginner's guide
            to help newer players.</p>
        </div>

        <div className="entry">
          <h3>2.3.3</h3>
          <h4>&#47;&#47; December 17th 2020</h4>
          <p>Evie!</p>
        </div>

        <div className="entry">
          <h3>2.3.2</h3>
          <h4>&#47;&#47; November 19th 2020</h4>
          <p>Added stats for the Eivors. Also updated the data
            for all events to reflect the number of chests that
            you can open, rather than the total number in the level.
            Hopefully this makes the calculator a bit more accurate
            (looking at you Raven's Wound mission 4).</p>
        </div>

        <div className="entry">
          <h3>2.3.1</h3>
          <h4>&#47;&#47; November 18th 2020</h4>
          <p>Added the two Eivors, their weapons and The Ravens' Wound.</p>
        </div>

        <div className="entry">
          <h3>2.3</h3>
          <h4>&#47;&#47; November 10th 2020</h4>
          <p>Added our new podcast "The Intel Room".</p>
        </div>

        <div className="entry">
          <h3>2.2.4</h3>
          <h4>&#47;&#47; October 16th 2020</h4>
          <p>Added Adéwalé and Liberator to the assassins and gear
            directories respectively.</p>
          <p>Fixed an issue with the background hero carousel which
            would eventually stall out at Yusuf.</p>
        </div>

        <div className="entry">
          <h3>2.2.3</h3>
          <h4>&#47;&#47; October 1st 2020</h4>
          <p>Updated the video layout on the assassin details page
            as the 2.10 reviews start heading out.</p>
        </div>

        <div className="entry">
          <h3>2.2.2</h3>
          <h4>&#47;&#47; September 22nd 2020</h4>
          <p>Added new gear from 2.10 and Apollodorus</p>
        </div>

        <div className="entry">
          <h3>2.2.1</h3>
          <h4>&#47;&#47; September 13th 2020</h4>
          <p>Updated the assassin stat rankings to include a rank and level
            filter so you can view the stats at any desired level.</p>
        </div>

        <div className="entry">
          <h3>2.2</h3>
          <h4>&#47;&#47; September 10th 2020</h4>
          <p>Added a new page for assassin stat rankings. This allows users
            to order assassins by stat, including newly added crafting, production
            and learning stats.</p>
        </div>

        <div className="entry">
          <h3>2.1.11</h3>
          <h4>&#47;&#47; August 26th 2020</h4>
          <p>Fixed a bug which prevented whiching themes once selecting the new
            Hidden Ones theme. Also replaced the old Miami Vice theme with a slightly
            modified one, titled 'Auditore'.</p>
        </div>

        <div className="entry">
          <h3>2.1.10</h3>
          <h4>&#47;&#47; August 25th 2020</h4>
          <p>Added new Hidden Ones theme!</p>
        </div>

        <div className="entry">
          <h3>2.1.9</h3>
          <h4>&#47;&#47; August 13th 2020</h4>
          <p>Mary Read!</p>
        </div>

        <div className="entry">
          <h3>2.1.8</h3>
          <h4>&#47;&#47; July 30th 2020</h4>
          <p>Fixed the current Art of the Heist event details page which was
            showing the incorrect number of chests for missions.</p>
        </div>

        <div className="entry">
          <h3>2.1.7</h3>
          <h4>&#47;&#47; July 29th 2020</h4>
          <p>Fixed the current Art of the Heist event details page which was
            showing the incorrect required classes for missions.</p>
        </div>

        <div className="entry">
          <h3>2.1.6</h3>
          <h4>&#47;&#47; July 24th 2020</h4>
          <p>Updated helix rift sequence. Fixed the warriors tunic page which
            was showing the wrong eligible heroes. Fixed a historical FHC which was
            incorrectly showing as Malik instead of Ezio.</p>
        </div>

        <div className="entry">
          <h3>2.1.5</h3>
          <h4>&#47;&#47; July 19th 2020</h4>
          <p>Fixed a bug in the calculator which was substituting lockpickers
            into the wrong spot on the team.</p>
        </div>

        <div className="entry">
          <h3>2.1.4</h3>
          <h4>&#47;&#47; July 9th 2020</h4>
          <p>A couple more bug fixes. Firstly, ties weren't displaying on power rankings -
            this has been sorted. Secondly, in the event calculator the daily
            objective reward nodes weren't reflecting the choice of join time.
            Thanks to Eyla for spotting.</p>
        </div>

        <div className="entry">
          <h3>2.1.3</h3>
          <h4>&#47;&#47; July 9th 2020</h4>
          <p>Fixed a bug where battle votes from previous days weren't displaying.</p>
        </div>

        <div className="entry">
          <h3>2.1.2</h3>
          <h4>&#47;&#47; July 7th 2020</h4>
          <p>Added Malik to the site and fixed a display issue on the assassin's
            page for Dogged Halberd users (shout out to Beariya).</p>
        </div>

        <div className="entry">
          <h3>2.1.1</h3>
          <h4>&#47;&#47; June 25th 2020</h4>
          <p>Updated the defense stats on some armor pieces to improve the accuracy
            compared to what is shown in the game. Thanks to Kiryl for the info!</p>
        </div>

        <div className="entry">
          <h3>2.1</h3>
          <h4>&#47;&#47; June 25th 2020</h4>
          <p>Power calculator and gear are here!</p>
            <p>There's a whole bunch to unpack with this one, but gear updates include...</p>
          <ul>
            <li>New gear directory page with filters for type and rank</li>
            <li>New gear details page with item stats and eligible heroes</li>
            <li>Both assassin and gear rewards on event pages now link through
              to their applicable pages</li>
          </ul>
          <p>The assassin detail page also has an overhauled stats section, including
            a brand new <strong>power calculator</strong>! Users can now select
            equipment for the hero and all stats will update accordingly.</p>
        </div>

        <div className="entry">
          <h3>2.0.7</h3>
          <h4>&#47;&#47; June 22nd 2020</h4>
          <p>Firstly I've updated the assassin images so they should load slightly faster.</p>
          <p>Secondly I've added the hero class icons to the event and calculator pages.</p>
        </div>

        <div className="entry">
          <h3>2.0.6</h3>
          <h4>&#47;&#47; June 21st 2020</h4>
          <p>Another calculator bug fix today, this time addressing changing the optimal
            mission and outcomes based on the difficulties you choose for each mission.</p>
        </div>

        <div className="entry">
          <h3>2.0.5</h3>
          <h4>&#47;&#47; June 19th 2020</h4>
          <p>Did a bunch of calculator fixes (shout-out to Psychotext for finding)...</p>
          <ul>
            <li>'Initial Run Completed' toggle wasn't correctly effecting outcomes</li>
            <li>'Rush' toggle wasn't correctly effecting outcomes</li>
            <li>Regenerated token amount was wrong on initial load of in-progress events</li>
            <li>The 'Join Time' slider was sometimes resulting in negative token amounts</li>
          </ul>
        </div>

        <div className="entry">
          <h3>2.0.4</h3>
          <h4>&#47;&#47; June 16th 2020</h4>
          <p>Added Natakas to the site, and updated some stats and gear for the newer heroes.</p>
        </div>

        <div className="entry">
          <h3>2.0.3</h3>
          <h4>&#47;&#47; June 15th 2020</h4>
          <p>Firstly, just quickly fixed an issue that prevented the current Hunters Hounds event
            from displaying.</p>
          <p>Secondly, and far more importantly I've revamped the themes for the site. The old
            light/dark toggle is gone and has been replaced by a new palette of themes, including
            the all new "Miami Vice" for the users who thought dark mode wasn't quite dark enough!
            In addition to this you can now also update your bookmarks according to your tastes.
            The following URLs should correspond to their theme:</p>
          <ul>
            <li>https://animus.mobi/?theme=AutumnDusk</li>
            <li>https://animus.mobi/?theme=MiamiVice</li>
          </ul>
          <p>Look out for more themes in the future!</p>
        </div>

        <div className="entry">
          <h3>2.0.2</h3>
          <h4>&#47;&#47; June 14th 2020</h4>
          <p>Fixed the battles and power rankings pages, and set season two to start
            on July 1st. This season includes the additions of Kenway, Blackbeard, Aya
            and Natakas.</p>
        </div>

        <div className="entry">
          <h3>2.0.1</h3>
          <h4>&#47;&#47; June 13th 2020</h4>
          <p>Updated the scrollbars which looked super ugly on Windows devices. Hopefully
            they're now more uniform across all devices.</p>
        </div>

        <div className="entry">
          <h3>2.0</h3>
          <h4>&#47;&#47; June 12th 2020</h4>
          <p>A complete rebuild of the site, designed to be snappier and more
            responsive when navigating around the site. The new features include
            the following.</p>
          <ul>
            <li>New look and feel</li>
            <li>New menu</li>
            <li>New assassin directory with hero stats, gear options and more</li>
            <li>New assassin tier list</li>
            <li>Revised event calculator with ability to set goal</li>
          </ul>
        </div>
      </div>

      {/* <Ad /> */}
    </>
  );
};

export default VersionLog;