const CALC_STATS = "CALC_STATS"

export function calculateStats(assassin, stats, gear) {

  // Get the stats for our assassin
  const statsObj = stats.filter(obj => {
    return obj.id === assassin.id
  })
  const thisAssassinStats = statsObj[0];

  // Get stars
  var stars = 1;
  var allStars = document.querySelectorAll(".stars i");
  if (allStars) {
    for (var i = 0; i < allStars.length; i++) {
      if (allStars[i].classList.contains('fas')) {
        stars = i + 1;
      }
    }
  }

  // Get level
  var levelSlider = document.querySelector("input[name=level]");
  if (levelSlider) {
    var level = parseInt(levelSlider.value);
  }

  // Get weapon
  var selectedWeapon = document.querySelector('.weapons .gearContainer.selected');
  if (selectedWeapon) {
    var weaponId = parseInt(selectedWeapon.getAttribute('data-id'));
  }
  var weapon = gear[weaponId];

  // Get armor
  var selectedArmor = document.querySelector('.armors .gearContainer.selected');
  if (selectedArmor) {
    var armorId = parseInt(selectedArmor.getAttribute('data-id'));
  }
  var armor = gear[armorId];

  // Get accessory
  var selectedAccessory = document.querySelector('.accessories .gearContainer.selected');
  if (selectedAccessory) {
    var accessoryId = parseInt(selectedAccessory.getAttribute('data-id'));
  }
  var accessory = gear[accessoryId];

  // Handle no weapon
  if (weaponId === -1) {
    weapon = {
      level: 0,
      rank: 0,
      stats: {
        health: 0,
        attack: 0,
        defense: 0,
        dodge: 0,
        crit_chance: 0,
        crit_damage: 0,
        lethality: 0,
        dexterity: 0,
        agility: 0
      }
    }
  }

  // Handle no armor
  if (armorId === -1) {
    armor = {
      level: 0,
      rank: 0,
      stats: {
        health: 0,
        attack: 0,
        defense: 0,
        dodge: 0,
        crit_chance: 0,
        crit_damage: 0,
        lethality: 0,
        dexterity: 0,
        agility: 0
      }
    }
  }

  // Handle no accessory
  if (accessoryId === -1) {
    accessory = {
      level: 0,
      rank: 0,
      stats: {
        health: 0,
        attack: 0,
        defense: 0,
        dodge: 0,
        crit_chance: 0,
        crit_damage: 0,
        lethality: 0,
        dexterity: 0,
        agility: 0
      }
    }
  }

  // Get ET
  var healthSlider = document.querySelector("input[name=health]");
  var attackSlider = document.querySelector("input[name=attack]");
  var defenseSlider = document.querySelector("input[name=defense]");
  var dodgeSlider = document.querySelector("input[name=dodge]");
  var critChanceSlider = document.querySelector("input[name=critChance]");
  var critDamageSlider = document.querySelector("input[name=critDamage]");
  var lethalitySlider = document.querySelector("input[name=lethality]");
  var dexteritySlider = document.querySelector("input[name=dexterity]");
  var agilitySlider = document.querySelector("input[name=agility]");
  if (healthSlider) { var healthUpgrades = parseInt(healthSlider.value); }
  if (attackSlider) { var attackUpgrades = parseInt(attackSlider.value); }
  if (defenseSlider) { var defenseUpgrades = parseInt(defenseSlider.value); }
  if (dodgeSlider) { var dodgeUpgrades = parseInt(dodgeSlider.value); }
  if (critChanceSlider) { var critChanceUpgrades = parseInt(critChanceSlider.value); }
  if (critDamageSlider) { var critDamageUpgrades = parseInt(critDamageSlider.value); }
  if (lethalitySlider) { var lethalityUpgrades = parseInt(lethalitySlider.value); }
  if (dexteritySlider) { var dexterityUpgrades = parseInt(dexteritySlider.value); }
  if (agilitySlider) { var agilityUpgrades = parseInt(agilitySlider.value); }

  // Let's calculate our stats
  var rankMultiplier = [0, 0, 0.75, 1.5, 2.55, 4];

  var health = thisAssassinStats.vitality_base_stat *
    (1 + rankMultiplier[stars]) +
    thisAssassinStats.vitality_per_level_gain *
    level + 150 * healthUpgrades;
  health += weapon.stats.health;
  health += armor.stats.health;
  health += accessory.stats.health;
  health = Math.floor(health);

  var attack = thisAssassinStats.attack_base_stat *
    (1 + rankMultiplier[stars]) +
    thisAssassinStats.attack_per_level_gain *
    level + 30 * attackUpgrades;
  attack += weapon.stats.attack;
  attack += armor.stats.attack;
  attack += accessory.stats.attack;
  attack = Math.floor(attack);

  var defense = thisAssassinStats.defense_base_stat *
    (1 + rankMultiplier[stars]) +
    thisAssassinStats.defense_per_level_gain *
    level + 30 * defenseUpgrades;
  defense += weapon.stats.defense;
  defense += armor.stats.defense;
  defense += accessory.stats.defense;
  defense = Math.floor(defense);

  var dodge = thisAssassinStats.dodge_base_stat *
    100 + 1 * dodgeUpgrades;
  dodge += weapon.stats.dodge;
  dodge += armor.stats.dodge;
  dodge += accessory.stats.dodge;
  dodge = Math.floor(dodge);

  var crit_chance = thisAssassinStats.crit_chance_base_stat *
    100 + 1 * critChanceUpgrades;
  crit_chance += weapon.stats.crit_chance;
  crit_chance += armor.stats.crit_chance;
  crit_chance += accessory.stats.crit_chance;
  crit_chance = Math.floor(crit_chance);

  var crit_damage = thisAssassinStats.crit_damage_base_stat *
    100 + 10 * critDamageUpgrades;
  crit_damage += weapon.stats.crit_damage;
  crit_damage += armor.stats.crit_damage;
  crit_damage += accessory.stats.crit_damage;
  crit_damage = Math.floor(crit_damage);

  var lethality = thisAssassinStats.lethality_base_stat *
    (1 + rankMultiplier[stars]) +
    thisAssassinStats.lethality_per_level_gain *
    level + 15 * lethalityUpgrades;
  lethality += weapon.stats.lethality;
  lethality += armor.stats.lethality;
  lethality += accessory.stats.lethality;
  lethality = Math.floor(lethality);

  var dexterity = thisAssassinStats.dexterity_base_stat *
    (1 + rankMultiplier[stars]) +
    thisAssassinStats.dexterity_per_level_gain *
    level + 15 * dexterityUpgrades;
  dexterity += weapon.stats.dexterity;
  dexterity += armor.stats.dexterity;
  dexterity += accessory.stats.dexterity;
  dexterity = Math.floor(dexterity);

  var agility = thisAssassinStats.agility_base_stat *
    (1 + rankMultiplier[stars]) +
    thisAssassinStats.agility_per_level_gain *
    level + 15 * agilityUpgrades;
  agility += weapon.stats.agility;
  agility += armor.stats.agility;
  agility += accessory.stats.agility;
  agility = Math.floor(agility);

  var rarityBasePower = [[],
  [0, 150, 243, 411, 542, 767],
  [0, 172, 284, 486, 643, 913],
  [0, 205, 345, 597, 794, 1131],
  [0, 260, 447, 783, 1045, 1495]
  ];

  var rarityPowerPerLevel = [[],
  [0, 10, 11, 12.5, 13.5, 15],
  [0, 12, 13.2, 15, 16.2, 18],
  [0, 15, 16.5, 18.75, 20.25, 22.5],
  [0, 20, 22, 25, 27, 30]
  ];

  var weaponPowerScore = [20, 45, 75, 105, 150, 190];

  var armorPowerScore = weaponPowerScore;

  var accessoryPowerScore = [0, 23, 38, 53, 75, 95];

  var power = rarityBasePower[assassin.rarity][stars]
    + ((level - 1) * rarityPowerPerLevel[assassin.rarity][stars])
    + weaponPowerScore[weapon.rank]
    + armorPowerScore[armor.rank]
    + accessoryPowerScore[accessory.rank]
    + (5 * (
      healthUpgrades +
      attackUpgrades +
      defenseUpgrades +
      dodgeUpgrades +
      critChanceUpgrades +
      critDamageUpgrades +
      lethalityUpgrades +
      dexterityUpgrades +
      agilityUpgrades
    ))
    - 40;

  // Put it all together
  var newassassinstats = {
    stars: stars,
    level: level,
    eliteTrainingUpgrades: {
      health: healthUpgrades,
      attack: attackUpgrades,
      defense: defenseUpgrades,
      dodge: dodgeUpgrades,
      crit_chance: critChanceUpgrades,
      crit_damage: critDamageUpgrades,
      lethality: lethalityUpgrades,
      dexterity: dexterityUpgrades,
      agility: agilityUpgrades
    },
    eliteTraining: {
      health: health,
      attack: attack,
      defense: defense,
      dodge: dodge,
      crit_chance: crit_chance,
      crit_damage: crit_damage,
      lethality: lethality,
      dexterity: dexterity,
      agility: agility
    },
    power: power
  }

  return { type: CALC_STATS, payload: newassassinstats }
}