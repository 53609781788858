import React from "react"
import { Link } from "react-router-dom"

const AssassinFiveStarGear = (props) => {

  const assassin = props.assassin;
  const gear = props.gear;

  // 5* Gear
  var weaponClass = "none";
  var weaponFill = <i className="fas fa-times"></i>;
  if (assassin.weapon !== "") {
    weaponClass = assassin.weapon;
    weaponFill = "";
  }
  var armorClass = "none";
  var armorFill = <i className="fas fa-times"></i>;
  if (assassin.armor !== "") {
    armorClass = assassin.armor;
    armorFill = "";
  }
  var accessoryClass = "none";
  var accessoryFill = <i className="fas fa-times"></i>;
  if (assassin.accessory !== "") {
    accessoryClass = assassin.accessory;
    accessoryFill = "";
  }

  var weaponLink = "", armorLink = "", accessoryLink = "";

  for (var g = 0; g < gear.length; g++) {

    if (assassin.weapon === gear[g].web_safe_name) {
      weaponLink = "/gear/" + gear[g].item_id + "/" + gear[g].web_safe_name;
    }
    if (assassin.armor === gear[g].web_safe_name) {
      armorLink = "/gear/" + gear[g].item_id + "/" + gear[g].web_safe_name;
    }
    if (assassin.accessory === gear[g].web_safe_name) {
      accessoryLink = "/gear/" + gear[g].item_id + "/" + gear[g].web_safe_name;
    }
  }

  var weaponDisplay = [];
  if (weaponLink !== "") {
    weaponDisplay.push(
      <Link to={weaponLink} key="w.link">
        <div className={"weapon " + weaponClass}>
          {weaponFill}
          <div className="label themeLabelY">5* Weapon</div>
        </div>
      </Link>
    );
  } else {
    weaponDisplay.push(
      <div className={"weapon " + weaponClass} key="weaponDisplay">
        {weaponFill}
        <div className="label themeLabelY">5* Weapon</div>
      </div>
    );
  }
  var armorDisplay = [];
  if (armorLink !== "") {
    armorDisplay.push(
      <Link to={armorLink} key="a.link">
        <div className={"armor " + armorClass}>
          {armorFill}
          <div className="label themeLabelY">5* Armor</div>
        </div>
      </Link>
    );
  } else {
    armorDisplay.push(
      <div className={"armor " + armorClass} key="armorDisplay">
        {armorFill}
        <div className="label themeLabelY">5* Armor</div>
      </div>
    );
  }
  var accessoryDisplay = [];
  if (accessoryLink !== "") {
    accessoryDisplay.push(
      <Link to={accessoryLink} key="c.link">
        <div className={"armor " + accessoryClass}>
          {accessoryFill}
          <div className="label themeLabelY">5* Accessory</div>
        </div>
      </Link>
    );
  } else {
    accessoryDisplay.push(
      <div className={"armor " + accessoryClass} key="accessoryDisplay">
        {accessoryFill}
        <div className="label themeLabelY">5* Accessory</div>
      </div>
    );
  }

  return (
    <div className="row gear">
      <div className="col weaponContainer">
        {weaponDisplay}
      </div>
      <div className="col armorContainer">
        {armorDisplay}
      </div>
      <div className="col accessoryContainer">
        {accessoryDisplay}
      </div>
    </div>
  )
}

export default AssassinFiveStarGear