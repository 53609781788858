import React from "react"
import cup from "../../img/coffee.png"

const Coffee = () => {

  return (
    <div className="coffee">
      <a href="//ko-fi.com/robpoole/commissions"
        target="_blank" rel="noopener noreferrer">
        <img src={cup} alt="Coffee" />
        <p>If you found this useful and want to support me then you can
        click here to buy me a coffee &nbsp;
          <span role="img" aria-label="kissing wink">😘</span>
        </p>
      </a>
    </div>
  )
}

export default Coffee