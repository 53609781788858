const SET_ASSASSINS = "SET_ASSASSINS"

var assassins = [];

function assassinsReducer(state = assassins, action) {
  if (action.type === SET_ASSASSINS) {
    return action.data
  }
  return state
}

export default assassinsReducer