export const apiConfig = {
  headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` }
}

export function safe(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export function lookup(array, identifier, value) {
  var theObject = array.filter(obj => {
    return obj[identifier] === value
  })
  return theObject[0];
}

export function compare(a, b) {
  const nameA = a.assassin_name_safe;
  const nameB = b.assassin_name_safe;

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export function compareBonuses(a, b) {
  if (a.bonus < b.bonus) {
    return -1;
  }
  if (a.bonus > b.bonus) {
    return 1;
  }
  return 0;
}

export function number_format(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

export function gd(dateString) {
  var theDay = parseInt(dateString.substring(8, 10));
  var theMonth = parseInt(dateString.substring(5, 7)) - 1;
  var theYear = parseInt(dateString.substring(0, 4));
  var d = new Date(theYear, theMonth, theDay);
  return d;
}

export function ts(dateString, utc = true) {
  var theHour = parseInt(dateString.substring(11, 13));
  var theMinute = parseInt(dateString.substring(14, 16));
  var theSecond = parseInt(dateString.substring(17, 19));
  var theDay = parseInt(dateString.substring(8, 10));
  var theMonth = parseInt(dateString.substring(5, 7)) - 1;
  var theYear = parseInt(dateString.substring(0, 4));
  var d = "";
  if (utc === true) {
    d = new Date(Date.UTC(theYear, theMonth, theDay, theHour, theMinute, theSecond));
  } else {
    d = new Date(theYear, theMonth, theDay, theHour, theMinute, theSecond);
  }
  var ts = d.getTime() / 1000;
  return ts;
}

export function ordinal(number) {
  var d = number % 10;
  return (~~(number % 100 / 10) === 1) ? 'th' :
    (d === 1) ? 'st' :
      (d === 2) ? 'nd' :
        (d === 3) ? 'rd' : 'th';
}

// Print out friendy time view of epoch
export function friendlyViewtime(secondsRemaining) {

  var days = Math.floor(secondsRemaining / 86400);
  var hours = Math.floor((secondsRemaining - (days * 86400)) / 3600);
  var minutes = Math.floor((secondsRemaining / 60) % 60);
  var seconds = secondsRemaining % 60;

  var timeRemaining = "";
  if (days > 0) {
    timeRemaining += days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's';
  } else {
    if (hours > 0) {
      timeRemaining += hours + 'h ' + minutes + 'm ' + seconds + 's';
    } else {
      timeRemaining += minutes + 'm ' + seconds + 's';
    }
  }
  return timeRemaining;
}

export function sortProperly(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export function copyToClipboard(theText) {
  var container = document.querySelector('.copy');
  var inputField = document.querySelector('.copy input');
  if (container) {
    container.classList.remove('alert');
    inputField.value = theText;
    inputField.select();
    document.execCommand('copy');
    container.classList.add('alert');
  }
}

export function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export function inArray(array, item) {
  return array.indexOf(item) !== -1;
}