import React from "react"
import { Link } from "react-router-dom"

const HomeMaps = () => {

  return (
    <div className="col">
      <h3>Maps</h3>
      <div className="homeMaps">
        <div className="event caribbean"></div>
        <div className="event spain"></div>
        <div className="event the-mask-of-the-ibis"></div>
      </div>
      <Link to="/info/maps" key="link.calc">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-map"></i>
    </div>
  )
}

export default HomeMaps