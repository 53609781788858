import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { gd, ts, ordinal, number_format, lookup, safe, apiConfig } from "../Utils.js"
import { runClock, eventCalculate } from "../actions/eventcalc"
import Coffee from "../components/Coffee"
import Holding from "../components/Holding"

const axios = require('axios');

const EventCalculator = () => {

  const dispatch = useDispatch();

  // Grab our calc stuff from the store
  let calc = useSelector(
    state => state.eventcalc
  );

  // Grab our assassins from the store
  let assassins = useSelector(
    state => state.assassins
  );

  // Get our params
  const params = useParams();
  var eventId = parseInt(params.id);

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});

  // Sort out our date range
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Set up our dates
  var startTime = 0;
  var endTime = 0;
  var secondsRemaining = endTime - startTime;
  var dateRange = "";

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_API_URL}api/events`, apiConfig)
      .then(function (response) {
        setEvents(response.data);

        if (!eventId) {
          for (var e = response.data.length - 1; e > 0; e--) {
            if (ts(response.data[e].end_date) > Math.floor(Date.now() / 1000)) {
              var newEventId = response.data[e].id;
            } else {
              break;
            }
          }
        }
        var lookupEventId = (newEventId ? newEventId : eventId);

        return axios.get(`${process.env.REACT_APP_API_URL}api/event/${lookupEventId}`, apiConfig)
      })
      .then(function (response) {
        setEvent(response.data);
        setLoading(false);
        dispatch(eventCalculate(response.data, assassins))
      });

    // Start the clock
    if (event.id) {

      var now = new Date();
      var nowTime = Math.round(now.getTime() / 1000);
      if (nowTime < endTime) {
        setTimer()
      }

      // Run calculator once
      dispatch(eventCalculate(event, assassins))
    }

  }, [])

  // Figure out our current event
  var currentEventId = 0;
  if (events.length > 0) {
    for (var e = events.length - 1; e > 0; e--) {
      if (ts(events[e].end_date) > Math.floor(Date.now() / 1000)) {
        currentEventId = events[e].id;
      } else {
        break;
      }
    }
  }

  // Get our event details
  if (event && typeof event.start_date !== 'undefined') {

    startTime = ts(event.start_date);
    endTime = ts(event.end_date);
    secondsRemaining = endTime - startTime;

    const dStart = gd(event.start_date);
    const dEnd = gd(event.end_date);
    dateRange = monthNames[dStart.getMonth()] + " " +
      dStart.getDate() + ordinal(dStart.getDate()) + " - " +
      monthNames[dEnd.getMonth()] + " " +
      dEnd.getDate() + ordinal(dEnd.getDate());
  }

  // Max required power
  var maxRequiredPower = 0;
  if (event.levels) {
    maxRequiredPower = event.levels[event.levels.length - 1].power;
  }

  // Set up our join time stuff
  if (event) {
    // var milliseconds = startTime * 1000;
    // var dateObject = new Date(milliseconds);
    var now = new Date();
    var nowTime = Math.round(now.getTime() / 1000);
    // var joinTime = startTime;
    if (nowTime > startTime && nowTime < endTime) {
      secondsRemaining = endTime - nowTime;
      var maxHours = Math.ceil(secondsRemaining / 3600);
      // joinTime = endTime - (maxHours * 3600);
      // dateObject = new Date(joinTime * 1000);
    }
  }

  // Tokens in hand
  var tokensInHand = 100;
  if (!event.fhc) {
    tokensInHand = 250;
  }
  if ((endTime - startTime) > (86400 * 3)) {
    tokensInHand = 400;
  }

  // Set the timer
  function setTimer() {
    setInterval(() => {
      dispatch(runClock(startTime, endTime))
    }, 100)
  }

  function changeGoal(goal) {

    // Clear down the selected option
    var allGoals = document.querySelectorAll(".goal");
    if (allGoals) {
      for (var i = 0; i < allGoals.length; i++) {
        allGoals[i].classList.remove("selected");
        var allGoalsRadios = allGoals[i].querySelector(".checked");
        allGoalsRadios.classList.add("hidden");
      }
    }

    // Check our desired goal
    var goalContainer = document.querySelector(".goal." + goal);
    if (goalContainer) {
      goalContainer.classList.add("selected");
      var goalRadio = goalContainer.querySelector(".checked");
      goalRadio.classList.remove("hidden");
    }

    dispatch(eventCalculate(event, assassins))
  }

  function changeStars(heroId, stars) {

    // How many did we have before?
    var oldStars = 0;
    var allStars = document.querySelectorAll(".syncBonusHero[data-hero='" + heroId + "'] i");
    if (allStars) {
      for (var i = 0; i < allStars.length; i++) {
        if (allStars[i].classList.contains("fas")) {
          oldStars = i + 1;
        }
      }
    }

    // If we're reclicking our star then reset to 0
    if (parseInt(stars) === oldStars) {
      stars = 0;
    }

    // Fill our stars appropriately
    if (allStars) {
      for (i = 0; i < allStars.length; i++) {
        if ((i + 1) <= stars) {
          allStars[i].classList.remove('far');
          allStars[i].classList.add('fas');
        } else {
          allStars[i].classList.remove('fas');
          allStars[i].classList.add('far');
        }
      }
    }

    dispatch(eventCalculate(event, assassins))
  }

  function changeTier(tier) {
    var allTiers = document.querySelectorAll(".tabs .tab");
    if (allTiers) {
      for (var i = 0; i < allTiers.length; i++) {
        allTiers[i].classList.remove("on");
        if (i === parseInt(tier)) {
          allTiers[i].classList.add("on");
        }
      }
    }
    dispatch(eventCalculate(event, assassins))
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  if (eventId > currentEventId) {

    return (
      <div className="eventContainer">

        <Helmet>
          <title>Assassin's Creed Rebellion &#47;&#47; Helix Rift Event Calculator</title>
        </Helmet>

        <h1>Data available soon</h1>

        <p>Check back soon for event calculator!</p>

      </div>
    );

  } else {

    maxHours = Math.ceil(secondsRemaining / 3600);

    var joinTimeClass = "";
    var initialRunCompletedClass = " hidden";
    if (calc.alreadyJoined === true) {
      joinTimeClass = " hidden";
      initialRunCompletedClass = "";
    }

    // Initial run
    var initialRunMissions = "";
    var initialRunStyle = " hidden";
    if (calc.initialRunCompleted === false) {
      initialRunStyle = "";
    }
    if (parseInt(calc.bestLevel) === 1) {
      initialRunMissions = 'mission 1';
    } else {
      initialRunMissions = 'missions 1 - ' + calc.bestLevel;
    }

    // Goals
    var goals = [];
    goals.push(
      <div className="col goal selected nodes" key="goalnodes"
        data-goal="nodes" onClick={() => changeGoal('nodes')}>
        <div className="item nodes"></div>
        <div className="goalName">Nodes</div>
        <div className="radio"><div className="checked"></div></div>
      </div>
    );
    if (event && event.fhc) {
      goals.push(
        <div className="col goal fhc" key="goalfhc"
          data-goal="fhc" onClick={() => changeGoal('fhc')}>
          <div className="item hero-cube-tokens"></div>
          <div className="goalName">FHC</div>
          <div className="radio"><div className="checked hidden"></div></div>
        </div>
      );
    }
    goals.push(
      <div className="col goal stars" key="goalstars"
        data-goal="stars" onClick={() => changeGoal('stars')}>
        <div><i className="fas fa-star"></i></div>
        <div className="goalName">Stars</div>
        <div className="radio"><div className="checked hidden"></div></div>
      </div>
    );
    var maximise = 'score';
    if (calc.goal === 'fhc') {
      maximise = 'featured hero cubes';
    }
    if (calc.goal === 'stars') {
      maximise = 'stars';
    }

    // Heroes
    var syncBonusHeroes = []
    for (let h = 1; h <= 6; h++) {

      let thisSyncHeroNum = 'sync_hero_' + h;

      // Only continue if we have a sync hero (think rift challenges)
      if (parseInt(event[thisSyncHeroNum]) !== 0) {

        let hero = lookup(assassins, 'id', parseInt(event[thisSyncHeroNum]))

        var hasLockpickIcon = '';
        if (hero.has_lockpick === 1) {
          hasLockpickIcon = <div className="itemContainer"><div className="item lockpick"></div></div>;
        }

        // Class icon
        var subclassContainer = "subclassContainer class" + hero.primaryclass;
        var subclassName = "subclass class" + hero.primaryclass;// + "sub"+hero.subClass;

        syncBonusHeroes.push(
          <div className="syncBonusHero row" key={"hero" + hero.id} data-hero={hero.id}>
            <div className={"heroContainer " + hero.rarity_name}>
              <div className={"hero " + hero.assassin_name_safe}></div>
              <div className="assassinName themeLabelX">{hero.assassin_name}</div>
              <div className={subclassContainer}><div className={subclassName}></div></div>
              {hasLockpickIcon}
            </div>
            <div className="break-xs"></div>
            <div className="stars">
              <i className="far fa-star" onClick={() => changeStars(hero.id, 1)}></i>
              <i className="far fa-star" onClick={() => changeStars(hero.id, 2)}></i>
              <i className="far fa-star" onClick={() => changeStars(hero.id, 3)}></i>
              <i className="far fa-star" onClick={() => changeStars(hero.id, 4)}></i>
              <i className="far fa-star" onClick={() => changeStars(hero.id, 5)}></i>
            </div>
            <div className="break-xs"></div>
            <div className="power">
              <label>PWR</label>
              <input type="text" className="capped textInput" size="5"
                defaultValue={maxRequiredPower}
                onChange={() => dispatch(eventCalculate(event, assassins))} />
            </div>
          </div>
        )
      }
    }

    // Mission tier tabs
    var theTabs = [];
    var tierNames = ["Auto", "Novice", "Adept", "Expert", "Master", "Grandmaster"];
    for (let i = 0; i < 6; i++) {
      var theClassName = "levelTab tab";
      if (i === 0) {
        theClassName += " on";
      }
      /*if (i === parseInt(calc.bestTier)) {
          theClassName += " chosen";
      }*/
      theTabs.push(
        <div className={theClassName} key={"tab." + i} data-tier={i}
          onClick={() => changeTier(i)}>{tierNames[i]}</div>
      );
    }
    var tierTabs = (
      <div className="tabs" key="tabs">
        {theTabs}
      </div>
    );

    // Outcome classes
    var nodesClass = "";
    if (calc.goal !== 'nodes') {
      nodesClass = " hidden";
    }
    var fhtClass = "";
    if (calc.goal !== 'fhc') {
      fhtClass = " hidden";
    }
    var starsClass = " hidden";
    var notStarsClass = "";
    if (calc.goal === 'stars') {
      starsClass = "";
      notStarsClass = " hidden";
    }

    // Context menu
    const contextMenu = [];
    var eventLink = "/helix-rift-event/" + event.id + "/" + safe(event.event_name);
    contextMenu.push(
      <Link to={eventLink} key="calc">
        <div className="calc">
          <i className="fas fa-trophy"></i>
        </div>
      </Link>
    );

    return (
      <div className="calculator">

        <Helmet>
          <title>Assassin's Creed Rebellion &#47;&#47; Helix Rift Event Calculator &#47;&#47; {event.event_name}</title>
        </Helmet>

        <h1>Helix Rift Event Calculator</h1>

        <h3>{event.event_name} &#47;&#47; {dateRange}</h3>
        <div className="timeRemaining placeHold">{calc.displayTime}</div>

        <h2>Your Goal</h2>

        <div className="goals row">
          {goals}
        </div>

        <h2>Your Heroes</h2>

        <div className="syncHeroes">
          {syncBonusHeroes}
        </div>

        <div className="syncBonus">
          You have a max sync bonus of <div id="syncBonus">{calc.maxSyncBonus}%</div>
        </div>

        <h2>The Missions</h2>

        <div className="missions">
          {tierTabs}
          {calc.missions.map(mission => {

            if (mission.chosen === true) {

              var req1 = "subclass class" + mission.primaryClass1 + mission.subClass1;
              var req2 = "subclass class" + mission.primaryClass2 + mission.subClass2;

              if (mission.chests === null) {
                mission.chests = 0;
              }

              var fhtEff = mission.fht / mission.tokens;
              var fhtStuff = [];
              var fhtKey = 'fht.' + mission.id;
              var breakerKey = 'breaker.' + mission.id;
              var fhtEffKey = 'fht.eff.' + mission.id;
              if (mission.fht > 0) {

                fhtStuff.push(
                  <div className="break-xs spacer" key={breakerKey}></div>
                );
                fhtStuff.push(
                  <div className="col" key={fhtKey}>
                    <div className="label text">FHT</div>
                    <div className="fht" data-fht={mission.fht}>
                      {number_format(mission.fht)}
                    </div>
                  </div>
                );
                fhtStuff.push(
                  <div className="col" key={fhtEffKey}>
                    <div className="label text">FHT / Token</div>
                    <div className="fhtEff" data-fhteff={fhtEff}>
                      {number_format(fhtEff, 2)}
                    </div>
                  </div>
                );
              }
              var hero1alts = [];
              var hero2alts = [];
              var hero3alts = [];
              var hero1style = "";
              var hero2style = "";
              var hero3style = "";
              var heroObj = {}
              var hero1 = {}
              var hero2 = {}
              var hero3 = {}

              heroObj = assassins.filter(obj => {
                return obj.id === parseInt(mission.hero1)
              })
              if (typeof heroObj[0] !== "undefined") {
                hero1 = heroObj[0];
              } else {
                hero1 = {
                  rarity_name: "",
                  assassin_name: "?",
                  assassin_name_safe: "unknown"
                }
              }
              heroObj = assassins.filter(obj => {
                return obj.id === parseInt(mission.hero2)
              })
              if (typeof heroObj[0] !== "undefined") {
                hero2 = heroObj[0];
              } else {
                hero2 = {
                  rarity_name: "",
                  assassin_name: "?",
                  assassin_name_safe: "unknown"
                }
              }
              heroObj = assassins.filter(obj => {
                return obj.id === parseInt(mission.hero3)
              })
              if (typeof heroObj[0] !== "undefined") {
                hero3 = heroObj[0];
              } else {
                hero3 = {
                  rarity_name: "",
                  assassin_name: "?",
                  assassin_name_safe: "unknown"
                }
              }

              if (mission.hero1alts.length > 0) {
                for (var h = 0; h < mission.hero1alts.length; h++) {

                  var theKey1 = 'hero.alt.1.' + h;
                  let hero = lookup(assassins, 'id', parseInt(mission.hero1alts[h]))

                  hero1alts.push(
                    <div className={"col heroContainer alt " + hero.rarity_name} key={theKey1}>
                      <div className={"hero " + hero.assassin_name_safe}></div>
                      <div className="assassinName themeLabelY">{hero.assassin_name}</div>
                    </div>
                  );
                }
                hero1style = " alt";
              }
              if (mission.hero2alts.length > 0) {
                for (h = 0; h < mission.hero2alts.length; h++) {

                  var theKey2 = 'hero.alt.2.' + h;
                  let hero = lookup(assassins, 'id', parseInt(mission.hero2alts[h]))

                  hero2alts.push(
                    <div className={"col heroContainer alt " + hero.rarity_name} key={theKey2}>
                      <div className={"hero " + hero.assassin_name_safe}></div>
                      <div className="assassinName themeLabelY">{hero.assassin_name}</div>
                    </div>
                  );
                }
                hero2style = " alt";
              }
              if (mission.hero3alts.length > 0) {
                for (h = 0; h < mission.hero3alts.length; h++) {

                  var theKey3 = 'hero.alt.3.' + h;
                  let hero = lookup(assassins, 'id', parseInt(mission.hero3alts[h]))

                  hero3alts.push(
                    <div className={"col heroContainer alt " + hero.rarity_name} key={theKey3}>
                      <div className={"hero " + hero.assassin_name_safe}></div>
                      <div className="assassinName themeLabelY">{hero.assassin_name}</div>
                    </div>
                  );
                }
                hero3style = " alt";
              }

              return (
                <div className="mission" key={mission.key}>
                  <div className="missionDetail">
                    <div className="item level-num">
                      {mission.level}
                      <div className="tier">{tierNames[mission.tier]}</div>
                    </div>
                    <div className="row">
                      <div className="col classes">
                        <div className={req1}></div>
                        <div className={req2}></div>
                      </div>
                      <div className="col power">
                        <div className="label text">PWR</div>
                        {number_format(mission.power)}
                      </div>
                      <div className="col tokens">
                        <div className="label"><div className="item rift-tokens"></div></div>
                        {number_format(mission.tokens)}
                      </div>
                      <div className="col chests">
                        <div className="label"><div className="subclass chest"></div></div>
                        {number_format(mission.chests)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="heroGroup">
                        <div className={"col heroContainer " + hero1.rarity_name + hero1style}>
                          <div className={"hero " + hero1.assassin_name_safe}></div>
                          <div className="assassinName themeLabelY">{hero1.assassin_name}</div>
                        </div>
                        {hero1alts}
                      </div>
                      <div className="heroGroup">
                        <div className={"col heroContainer " + hero2.rarity_name + hero2style}>
                          <div className={"hero " + hero2.assassin_name_safe}></div>
                          <div className="assassinName themeLabelY">{hero2.assassin_name}</div>
                        </div>
                        {hero2alts}
                      </div>
                      <div className="heroGroup">
                        <div className={"col heroContainer " + hero3.rarity_name + hero3style}>
                          <div className={"hero " + hero3.assassin_name_safe}></div>
                          <div className="assassinName themeLabelY">{hero3.assassin_name}</div>
                        </div>
                        {hero3alts}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="label text">Sync Bonus</div>
                        <div className="syncBonus">
                          {mission.syncBonus}%
                        </div>
                      </div>
                      <div className="col">
                        <div className="label text">Mission Nodes</div>
                        <div className="missionNodes">
                          {number_format(mission.missionNodes, 2)}
                        </div>
                      </div>
                      <div className="break-xs spacer"></div>
                      <div className="col">
                        <div className="label text">Avg From Chests</div>
                        <div className="avgChests">
                          {number_format(mission.chestNodes, 2)}
                        </div>
                      </div>
                      <div className="col">
                        <div className="label text">Total Nodes</div>
                        <div className="totalNodes">
                          {number_format(mission.totalNodes, 2)}
                        </div>
                      </div>
                      <div className="break-xs spacer"></div>
                      <div className="col">
                        <div className="label text">Nodes / Token</div>
                        <div className="eff">
                          {number_format(mission.eff, 2)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col rush">
                        <div className="label text">Rush Nodes</div>
                        <div className="rushTotalNodes">
                          {number_format(mission.rushNodes, 2)}
                        </div>
                      </div>
                      <div className="col rush">
                        <div className="label text">Rush Nodes / Token</div>
                        <div className="rushEff">
                          {number_format(mission.rushEff, 2)}
                        </div>
                      </div>
                      {fhtStuff}
                    </div>
                  </div>
                </div>
              );
            } else {
              return null
            }
          })}
        </div>

        <div className={"farmMissionContainer " + notStarsClass}>

          To maximise your {maximise} you should farm mission

          <div className="runMission">
            <div className="item level-num" id="runMission">{calc.bestLevel}</div>
          </div>
          <div id="runMissionTier">{tierNames[calc.bestTier]}</div>

        </div>

        <h2>Your Scenario</h2>

        <div className="scenario">

          <div className="row">

            <div className="premium col">

              <div className="toggle">
                <div className="label">Premium?</div>
                <div className="switchBox">
                  <input type="checkbox" className="switch premiumSwitch" value="1"
                    onChange={() => dispatch(eventCalculate(event, assassins))} />
                </div>
              </div>

            </div>

            <div className="rushing col">

              <div className="toggle">
                <div className="label">Rushing?</div>
                <div className="switchBox">
                  <input type="checkbox" className="switch rushSwitch" value="1"
                    onChange={() => dispatch(eventCalculate(event, assassins))} />
                </div>
              </div>

            </div>

            <div className="joined col">

              <div className="toggle">
                <div className="label">Already Joined?</div>
                <div className="switchBox">
                  <input type="checkbox" className="switch joinedSwitch" value="1"
                    onChange={() => dispatch(eventCalculate(event, assassins))} />
                </div>
              </div>

            </div>

            <div className={"initialRun col" + initialRunCompletedClass}>

              <div className="toggle">
                <div className="label">Initial Run Completed?</div>
                <div className="switchBox">
                  <input type="checkbox" className="switch initialRunSwitch" value="1"
                    onChange={() => dispatch(eventCalculate(event, assassins))} />
                </div>
              </div>

            </div>

          </div>

          <div className="row">

            <div className="tokensInHand col">
              <div className="label">Tokens in hand</div>
              <input className="textInput" type="text" name="tokens" size="4"
                defaultValue={tokensInHand}
                onChange={() => dispatch(eventCalculate(event, assassins))} />
            </div>

            <div className={"currentScore col" + initialRunCompletedClass}>
              <div className="label">Current Score</div>
              <input className="textInput" type="text" name="nodes"
                defaultValue="0" size="10"
                onChange={() => dispatch(eventCalculate(event, assassins))} />
            </div>

          </div>

          <div className={"join" + joinTimeClass}>

            <h2>When do you plan on joining?</h2>

            <input type="range" name="joinTime" min="1" max={maxHours}
              defaultValue={maxHours} onChange={() => dispatch(eventCalculate(event, assassins))} />

            <div className="joinTimeDisplay">
              <div className="time">{calc.joinDateTime}</div>
              <div className="rem">{calc.joinHoursRemaining}</div>
            </div>

          </div>

        </div>

        <h2>Outcomes</h2>

        <div id="outcome">
          <div className={"outcome" + notStarsClass}>
            <div className={"row" + initialRunStyle}>
              <div className="col description">
                Initial run of {initialRunMissions}
              </div>
              <div className="break-xs"></div>
              <div className="col tokens">
                <div className="label">
                  <div className="item rift-tokens"></div>
                </div>
                <div className="value">{calc.outcomes.nodes.initialRunTokens}</div>
              </div>
              <div className={"col nodes" + nodesClass}>
                <div className="label">
                  <div className="item nodes"></div>
                </div>
                <div className="value">{number_format(calc.outcomes.nodes.initialRunNodes)}</div>
              </div>
              <div className={"col fhc" + fhtClass}>
                <div className="label">
                  <div className="item hero-cube-tokens"></div>
                </div>
                <div className="value">{number_format(calc.outcomes.nodes.initialRunFHT)}</div>
              </div>
            </div>
            <div className={"row" + nodesClass}>
              <div className="col description">
                Claim {calc.outcomes.nodes.numDailyRewards}x daily rewards
              </div>
              <div className="break-xs"></div>
              <div className="col tokens"></div>
              <div className="col nodes">
                <div className="label">
                  <div className="item nodes"></div>
                </div>
                <div className="value">{number_format(calc.outcomes.nodes.dailyRewardNodes)}</div>
              </div>
            </div>
          </div>

          {calc.outcomes.nodes.refills.map(outcome => {
            var plural = "s";
            if (outcome.refillNum === 1) {
              plural = "";
            }
            var remainderStyle = "";
            if (outcome.remainderMission === 0) {
              remainderStyle = " hidden";
            }
            var verb = 'Run';
            if (calc.rushing === true) {
              verb = 'Rush';
            }
            return (
              <div className="outcome" key={"outcome." + outcome.refillNum}>
                <h2>{outcome.refillNum} Refill{plural}</h2>
                <div className={"row" + notStarsClass}>
                  <div className="col description">
                    {verb} mission {calc.bestLevel} {outcome.farmRuns}x
                  </div>
                  <div className="break-xs"></div>
                  <div className="col tokens">
                    <div className="label">
                      <div className="item rift-tokens"></div>
                    </div>
                    <div className="value">{outcome.farmTokens}</div>
                  </div>
                  <div className={"col nodes" + nodesClass}>
                    <div className="label">
                      <div className="item nodes"></div>
                    </div>
                    <div className="value">{number_format(outcome.farmNodes)}</div>
                  </div>
                  <div className={"col fhc" + fhtClass}>
                    <div className="label">
                      <div className="item hero-cube-tokens"></div>
                    </div>
                    <div className="value">{number_format(outcome.farmFHT)}</div>
                  </div>
                </div>
                <div className={"row" + remainderStyle + notStarsClass}>
                  <div className="break-xs"></div>
                  <div className="col description">
                    Run mission {outcome.remainderMission} once
                  </div>
                  <div className="col tokens">
                    <div className="label">
                      <div className="item rift-tokens"></div>
                    </div>
                    <div className="value">{outcome.remainderMissionTokens}</div>
                  </div>
                  <div className={"col nodes" + nodesClass}>
                    <div className="label">
                      <div className="item nodes"></div>
                    </div>
                    <div className="value">{number_format(outcome.remainderMissionNodes)}</div>
                  </div>
                  <div className={"col fhc" + fhtClass}>
                    <div className="label">
                      <div className="item hero-cube-tokens"></div>
                    </div>
                    <div className="value">{number_format(outcome.remainderMissionFHT)}</div>
                  </div>
                </div>
                <div className={"refillScore" + nodesClass}>
                  <div className="item nodes"></div>
                  <div className="value">{number_format(outcome.grandTotal)}</div>
                </div>
                <div className={"refillScore fht" + fhtClass}>
                  <div className="item hero-cube-tokens"></div>
                  <div className="value">{outcome.numCubes} cubes</div>
                  <div className="leftover">{number_format(outcome.leftOver)} leftover FHT</div>
                </div>
                <div className={"refillScore stars" + starsClass}>
                  <div><i className="fas fa-star"></i></div>
                  <div className="value">{outcome.stars}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="contextMenu">{contextMenu}</div>

        <Coffee />

      </div>
    )
  }
}

export default EventCalculator;