const CHANGE_THEME = "CHANGE_THEME"

const initialState = {
  theme: "OceanVista"
}

function themeReducer(state = initialState, action) {
  if (action.type === CHANGE_THEME) {

    // Figure out our current theme
    const body = document.querySelector(".App");

    body.classList.remove("OceanVista");
    body.classList.remove("AutumnDusk");
    body.classList.remove("DeadOfNight");
    body.classList.remove("HiddenOnes");
    body.classList.remove("LateSummer");

    body.classList.add(action.theme);

    return {
      ...state,
      theme: action.theme
    }
  }
  return state
}

export default themeReducer