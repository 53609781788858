import React from "react"
import { useSelector } from "react-redux"
import { lookup } from "../Utils.js"

const AssassinStatBlock = (props) => {

  // Grab our assassin from our store
  const assassins = useSelector(
    state => state.assassins
  );

  // Grab our stats from our props
  const stats = props.stats;

  // Make a copy of our stats for sorting
  let healthRankings = [...stats];
  let attackRankings = [...stats];
  let defenseRankings = [...stats];
  let dodgeRankings = [...stats];
  let critChanceRankings = [...stats];
  let critDamageRankings = [...stats];
  let lethalityRankings = [...stats];
  let dexterityRankings = [...stats];
  let agilityRankings = [...stats];
  let craftingRankings = [...stats];
  let productionRankings = [...stats];
  let learningRankings = [...stats];

  // Sort all our stat rankings outside of our loop
  healthRankings.sort((a, b) => (parseFloat(a['vitality_base_stat']) < parseFloat(b['vitality_base_stat'])) ? 1 : ((parseFloat(b['vitality_base_stat']) < parseFloat(a['vitality_base_stat'])) ? -1 : 0));
  attackRankings.sort((a, b) => (parseFloat(a['attack_base_stat']) < parseFloat(b['attack_base_stat'])) ? 1 : ((parseFloat(b['attack_base_stat']) < parseFloat(a['attack_base_stat'])) ? -1 : 0));
  defenseRankings.sort((a, b) => (parseFloat(a['defense_base_stat']) < parseFloat(b['defense_base_stat'])) ? 1 : ((parseFloat(b['defense_base_stat']) < parseFloat(a['defense_base_stat'])) ? -1 : 0));
  dodgeRankings.sort((a, b) => (parseFloat(a['dodge_base_stat']) < parseFloat(b['dodge_base_stat'])) ? 1 : ((parseFloat(b['dodge_base_stat']) < parseFloat(a['dodge_base_stat'])) ? -1 : 0));
  critChanceRankings.sort((a, b) => (parseFloat(a['crit_chance_base_stat']) < parseFloat(b['crit_chance_base_stat'])) ? 1 : ((parseFloat(b['crit_chance_base_stat']) < parseFloat(a['crit_chance_base_stat'])) ? -1 : 0));
  critDamageRankings.sort((a, b) => (parseFloat(a['crit_damage_base_stat']) < parseFloat(b['crit_damage_base_stat'])) ? 1 : ((parseFloat(b['crit_damage_base_stat']) < parseFloat(a['crit_damage_base_stat'])) ? -1 : 0));
  lethalityRankings.sort((a, b) => (parseFloat(a['lethality_base_stat']) < parseFloat(b['lethality_base_stat'])) ? 1 : ((parseFloat(b['lethality_base_stat']) < parseFloat(a['lethality_base_stat'])) ? -1 : 0));
  dexterityRankings.sort((a, b) => (parseFloat(a['dexterity_base_stat']) < parseFloat(b['dexterity_base_stat'])) ? 1 : ((parseFloat(b['dexterity_base_stat']) < parseFloat(a['dexterity_base_stat'])) ? -1 : 0));
  agilityRankings.sort((a, b) => (parseFloat(a['agility_base_stat']) < parseFloat(b['agility_base_stat'])) ? 1 : ((parseFloat(b['agility_base_stat']) < parseFloat(a['agility_base_stat'])) ? -1 : 0));
  craftingRankings.sort((a, b) => (parseFloat(a['crafting_base_stat']) < parseFloat(b['crafting_base_stat'])) ? 1 : ((parseFloat(b['crafting_base_stat']) < parseFloat(a['crafting_base_stat'])) ? -1 : 0));
  productionRankings.sort((a, b) => (parseFloat(a['production_base_stat']) < parseFloat(b['production_base_stat'])) ? 1 : ((parseFloat(b['production_base_stat']) < parseFloat(a['production_base_stat'])) ? -1 : 0));
  learningRankings.sort((a, b) => (parseFloat(a['learning_base_stat']) < parseFloat(b['learning_base_stat'])) ? 1 : ((parseFloat(b['learning_base_stat']) < parseFloat(a['learning_base_stat'])) ? -1 : 0));

  // Put our stat categories in an array so we can loop through them
  var statRanks = [
    { "categoryName": "health", "statName": "vitality_base_stat", "stats": healthRankings, "rank": 0, "style": "common" },
    { "categoryName": "attack", "statName": "attack_base_stat", "stats": attackRankings, "rank": 0, "style": "common" },
    { "categoryName": "defense", "statName": "defense_base_stat", "stats": defenseRankings, "rank": 0, "style": "common" },
    { "categoryName": "dodge", "statName": "dodge_base_stat", "stats": dodgeRankings, "rank": 0, "style": "common" },
    { "categoryName": "critChance", "statName": "crit_chance_base_stat", "stats": critChanceRankings, "rank": 0, "style": "common" },
    { "categoryName": "critDamage", "statName": "crit_damage_base_stat", "stats": critDamageRankings, "rank": 0, "style": "common" },
    { "categoryName": "lethality", "statName": "lethality_base_stat", "stats": lethalityRankings, "rank": 0, "style": "common" },
    { "categoryName": "dexterity", "statName": "dexterity_base_stat", "stats": dexterityRankings, "rank": 0, "style": "common" },
    { "categoryName": "agility", "statName": "agility_base_stat", "stats": agilityRankings, "rank": 0, "style": "common" },
    { "categoryName": "crafting", "statName": "crafting_base_stat", "stats": craftingRankings, "rank": 0, "style": "common" },
    { "categoryName": "production", "statName": "production_base_stat", "stats": productionRankings, "rank": 0, "style": "common" },
    { "categoryName": "learning", "statName": "learning_base_stat", "stats": learningRankings, "rank": 0, "style": "common" }
  ];
  var statBlocks = [];

  for (let r = 0; r < statRanks.length; r++) {

    let sortedStats = statRanks[r].stats;
    var thisRank, thisStat, loopStat, statMax, totalAboveZero = 0;
    var statMin = 99999;
    var thisStyle = "common";
    var loopRank = 0;

    // Loop through all of our stats
    for (let s = 0; s < sortedStats.length; s++) {

      let assassin = lookup(assassins, 'id', parseInt(sortedStats[s].id));

      // Only grab the stat we're interested in
      if (loopStat !== sortedStats[s][statRanks[r].statName]) {
        loopStat = parseFloat(sortedStats[s][statRanks[r].statName]);
        if (typeof (assassin) !== "undefined") {
          loopRank++;
        }
        if (loopStat > statMax) {
          statMax = loopStat;
        }
        if (loopStat > 0 && loopStat < statMin) {
          statMin = loopStat;
        }
        if (loopStat > 0 && typeof (assassin) !== "undefined") {
          totalAboveZero++;
        }
      }

      // When we hit grab the rank we're at
      if (parseInt(sortedStats[s].id) === parseInt(props.id)) {
        thisRank = loopRank;
        thisStat = loopStat;
      }
    }

    var rankStep = totalAboveZero / 4;
    if (thisRank < (rankStep)) { thisStyle = "legendary"; }
    if (thisRank >= ((rankStep * 1))) { thisStyle = "epic"; }
    if (thisRank >= ((rankStep * 2))) { thisStyle = "rare"; }
    if (thisRank >= ((rankStep * 3))) { thisStyle = "common"; }

    var outOfStyle = "";
    if (thisStat === 0) {
      thisRank = '-';
      outOfStyle = ' hidden'
    }

    statBlocks.push(
      <div className={"statContainer td " + thisStyle}>
        <div className={"stat " + statRanks[r].categoryName}></div>
        <div className="rank">{thisRank}</div>
        <div className={"outOf"+outOfStyle}>of {totalAboveZero}</div>
      </div>
    );

  }

  return (
    <div className="statsContainer">
      <div className="tr">
        {statBlocks[0]}
        {statBlocks[1]}
        {statBlocks[2]}
        {statBlocks[3]}
      </div>
      <div className="tr">
        {statBlocks[4]}
        {statBlocks[5]}
        {statBlocks[6]}
        {statBlocks[7]}
      </div>
      <div className="tr">
        {statBlocks[8]}
        {statBlocks[9]}
        {statBlocks[10]}
        {statBlocks[11]}
      </div>
    </div>
  )
}

export default AssassinStatBlock