import React from "react"
import { Helmet } from "react-helmet"
import AssassinCard from "../components/AssassinCard"
import Coffee from "../components/Coffee"

const BeginnersGuide = () => {

  return (
    <div>

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Beginner's Guide</title>
      </Helmet>

      <div className="beginnersGuide">

        <h1>Beginner's Guide</h1>

        <p>Welcome to Assassin's Creed Rebellion! This game has a story to beat,
          side missions to complete, DNA to collect, achievements to unlock and more,
          so I'll aim to give you an idea of how best to approach the challenges infront
          of you, depending on what stage of the game you're at.</p>

        <h2>The Early Game</h2>

        <p>After you make your way through the tutorial missions you'll have a plethora
          of options of where to invest your time, intel and helix. One of the first
          things you'll need to decide is if you want to get to level 50 as soon as you
          can, or if you're willing to be a bit more patient in your journey. This is
          important because when you reach level 50 the competition level in events will
          spike dramatically and event-exclusive DNA will be a lot harder to compete for,
          so keep this in mind.</p>

        <p>As far as helix goes, you may want to buy one or two of the 1,800 helix DNA
          cube packs to help you get started, but it's not necessary. Otherwise I would
          hold onto it until you can make use of it in events, by buying token refills.</p>

        <p>In terms of missions feel free to make your way through as many of the story
          missions as you can with the starting heroes. Early on you'll be able to level
          up these heroes in the training room at a low cost, but as soon as you unlock
          a few more heroes and level up your brotherhood a few times you'll find you
          won't have the resources to level all of your heroes up to your max. You'll need
          to focus on your core group of heroes to take you forwards.</p>

        <div className="directory">
          <AssassinCard id={1} key="assassin1" />
          <AssassinCard id={67} key="assassin2" />
          <AssassinCard id={66} key="assassin3" />
          <AssassinCard id={29} key="assassin4" />
          <AssassinCard id={61} key="assassin5" />
        </div>

        <p>This is the team I would suggest you focus on. Aguilar DNA will be given to you
          as you complete story missions, but the others will all come from farming
          legacy missions in regions 1 and 2; from legacy missions 4, 10, 11 and 13
          specifically. If you run these missions every day you'll find these guys
          ranking up quickly, and once they're 5-star they'll be ready to carry you
          through the first four regions.</p>

        <p>As you progress through the regions also keep an eye on the achievements
          and be aware of what you're needed to do to grab more Claudia DNA. She's a
          great hero to have.</p>

        <p>Use any remaining intel you have to complete the daily objectives if you're
          rushing to level 50. Otherwise use them to farm codex/materials for your
          core team, or for other heroes you like the look of.
          Maybe take a look at the <a href="/assassin-tier-list">tier guide</a> to
          see who the best heroes available to you are. It's also worth seeing if
          you're able to rank up any heroes who are sync bonus heroes for events.</p>

        <p>Make sure you try to complete any animus challenges you can, and also
          participate in all events - even if you're unable to achieve a decent
          ranking you'll still get some DNA and other nice rewards. There's no
          point in sitting out an event you need DNA from.</p>

        <p>I would also advise running any animus bounty missions you are able to
          complete, as these are free to attempt. In particular, remember to take
          on the helix bounties on Saturdays and Sundays.</p>

        <h2>The Mid-Game</h2>

        <p>Once your initial core team has got you through the first four regions
          it's time to start thinking about who to farm next in order to get through
          region 5. If your initial core team is fully geared with tier-4 items
          and levelled then they might be strong enough to get you through the
          first couple of region 5 story missions, which will each give you a
          rare material which will let you craft an item of 5-star gear.</p>

        <div className="rareMats">
          <div className="itemContainer" key="item1">
            <div className="item cinnabar"></div>
          </div>
          <div className="itemContainer" key="item2">
            <div className="item ruby"></div>
          </div>
          <div className="itemContainer" key="item3">
            <div className="item soapstone"></div>
          </div>
          <div className="itemContainer" key="item4">
            <div className="item malachite"></div>
          </div>
          <div className="itemContainer" key="item5">
            <div className="item amber"></div>
          </div>
          <div className="itemContainer" key="item6">
            <div className="item emerald"></div>
          </div>
          <div className="itemContainer" key="item7">
            <div className="item jet"></div>
          </div>
          <div className="itemContainer" key="item8">
            <div className="item obsidian"></div>
          </div>
          <div className="itemContainer" key="item9">
            <div className="item coral"></div>
          </div>
          <div className="itemContainer" key="item10">
            <div className="item ancient-tablet"></div>
          </div>
        </div>

        <p>5-star gear makes a world of difference, for instance a 4-star Milanese
          Sword does 320-350 damage, whereas a 5-star Marring Milanese Sword does
          1,700-1,800. This means you'll have to rely on whichever heroes can
          make use of a 5-star piece of gear that you have a rare material for.
          You may be lucky and get a material for a strong hero, or you may be left
          farming region five loot missions until you do. The odds of getting
          a rare material from a region five loot mission is about 1-in-25 so
          you'll need to be patient, but I promise you'll get some eventually!</p>

        <p>Once you can craft tier-5 gear for epic heroes or legendaries you'll find
          yourself able to take on the games hardest content.
          Again, refer to the tier guide to figure out which are the best heroes to farm
          from regions 3-4 to give your new gear to. You'll now be position to take
          on story mission 28 and farm all the heroes available outside of events.</p>

        <h2>The End-Game</h2>

        <p>Once you've beaten (and 3-starred) all of the missions the game has to offer
          you'll find yourself in the endgame. At first you can finish off all non-event
          heroes by making use of database credits given from excess DNA to buy
          remaining DNA from the shop. DNA cube tokens are also useful to finish-off
          these heroes. You'll probably also need to farm low-chance drops from
          legacy missions.</p>

        <p>Other than that the endgame is focused around events. Both in terms of
          collecting helix to enable your ability to be competitive in events,
          and competing in events themselves to earn event-exlusive DNA.</p>

        <p>Helix is readily available from daily objectives, animus challenges,
          animus bounties, daily login rewards etc. Be sure to grab as much as you can -
          you'll find that doing so generally only takes around 5-minutes a day
          of playing time.</p>

        <p>Lot's of level 50 players will spend all of their helix on buying token refills,
          and will sit out events they no longer need DNA from to "stack" tokens
          for events they need. So you'll probably have to do the same (or lighten
          your wallet) to compete... unless you were patient and didn't hit level 50
          yet!</p>

        <p>Finally, the best and most important advise I can give is to get involved
          with the community! Namely, joining the Discord servers. You'll learn
          a lot there and hopefully make some great friends along the way!</p>

        <p>Thanks for reading!</p>

      </div>

      <Coffee />

    </div>

  )
}

export default BeginnersGuide