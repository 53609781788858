import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ts, safe, apiConfig } from "../Utils.js"
import Loading from "../components/Loading"

const axios = require('axios');

const HomeCalculator = () => {

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/events`, apiConfig)
      .then(function (response) {
        setEvents(response.data);
        setLoading(false);
      });
  }, [])

  var eventClass = "";
  if (events.length > 0) {
    for (var e = events.length - 1; e > 0; e--) {
      if (ts(events[e].end_date) > Math.floor(Date.now() / 1000)) {
        eventClass = safe(events[e].event_name);
      } else {
        break;
      }
    }
  }

  if (isLoading) {
    return (
      <Loading />
    )
  }

  var link = '/helix-rift-event-calculator'
  if (eventClass === 'abstergo-gauntlet') {
    link = '/abstergo-gauntlet-calculator'
  }

  return (
    <div className="col">
      <h3>Calculator</h3>
      <div className="calculator">
        <div className={"event " + eventClass}>
          <div className="item nodes"></div>
          <div className="item hero-cube-tokens"></div>
          <div><i className="fas fa-star"></i></div>
        </div>
      </div>
      <Link to={link} key="link.calc">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-calculator"></i>
    </div>
  )
}

export default HomeCalculator