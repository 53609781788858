import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { number_format, ts, gd, ordinal, lookup, safe, apiConfig } from "../Utils.js"
import AssassinCard from "../components/AssassinCard"
import Coffee from "../components/Coffee"
import Holding from "../components/Holding"

const axios = require('axios');

const Event = () => {

  // Get our params
  const params = useParams();
  const eventId = parseInt(params.id);

  // Grab our assassin from our store
  const assassins = useSelector(
    state => state.assassins
  );

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});

  useEffect(() => {
    const apiEvents = axios.get(`${process.env.REACT_APP_API_URL}api/events`, apiConfig);
    const apiEvent = axios.get(`${process.env.REACT_APP_API_URL}api/event/${eventId}`, apiConfig);
    setLoading(true);
    axios.all([apiEvents, apiEvent])
      .then(axios.spread((...responses) => {
        setEvents(responses[0].data);
        setEvent(responses[1].data);
        setLoading(false);
      })).catch(errors => {
        // react on errors.
      });
  }, [eventId]);

  // Figure out our current event
  var currentEventId = 0;
  if (events.length > 0) {
    for (var e = events.length - 1; e > 0; e--) {
      if (ts(events[e].end_date) > Math.floor(Date.now() / 1000)) {
        currentEventId = events[e].id;
      } else {
        break;
      }
    }
  }

  // Sort out our date range
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Sync heroes
  const syncHeroes = [];
  if (typeof event.start_date !== 'undefined') {

    var dStart = gd(event.start_date);
    var dEnd = gd(event.end_date);
    var dateRange = monthNames[dStart.getMonth()] + " " +
      dStart.getDate() + ordinal(dStart.getDate()) + " - " +
      monthNames[dEnd.getMonth()] + " " +
      dEnd.getDate() + ordinal(dEnd.getDate());

    // if (eventId <= currentEventId) {
      for (let h = 1; h <= 6; h++) {

        var thisSyncHeroNum = 'sync_hero_' + h;

        if (parseInt(event[thisSyncHeroNum]) !== 0) {

          syncHeroes.push(
            <AssassinCard id={parseInt(event[thisSyncHeroNum])} key={"sh" + event[thisSyncHeroNum]} />
          )
        }
      }
    // }
  }

  // FHC
  var fhc = [];
  var fhcStyle = " hidden";
  if (event.fhc) {

    try {
      let fhcHero = lookup(assassins, 'id', parseInt(event.fhc))
      let fhcImg = require("../../img/fhc/" + fhcHero.assassin_name_safe + ".png")

      fhcStyle = "";
      fhc.push(
        <div className="fhc" key="fhc">

          <h2>Featured Hero Cube</h2>

          <img src={fhcImg.default} alt="Featured Hero Cube" />

        </div>
      );
    } catch (e) {
      //console.log(e)
    }
  }

  // Levels
  const levels = [];

  levels.push(
    <div className="key" key="levelsKey">
      <div className="spacer"></div>
      <div className="chests"><div className="subclass chest"></div></div>
      <div className="power">PWR</div>
      <div className="nodes"><div className="item nodes"></div></div>
      <div className="rift-tokens"><div className="item rift-tokens"></div></div>
      <div className={"hero-cube-tokens" + fhcStyle}><div className="item hero-cube-tokens"></div></div>
    </div>
  );

  if (typeof event.levels !== 'undefined') {
    for (var i = 0; i < event.levels.length; i++) {

      var thisKey = event.levels[i].level + "." + event.levels[i].tier;
      var levelClassName = "level";

      if (event.levels[i].tier !== 5 && event.levels[i].tier !== null) {
        levelClassName += " hidden";
      }

      var classIcons = [];
      var classOne = "";
      var classTwo = "";
      var classesKey = "";

      if (event.levels[i].class1 !== null) {
        classesKey = "classes." + i + event.levels[i].tier;
        classOne = "subclass class" + event.levels[i].class1;
        classTwo = "subclass class" + event.levels[i].class2;
        classIcons.push(
          <div className="subclasses" key={classesKey}>
            <div className={classOne}></div>
            <div className={classTwo}></div>
          </div>
        );
      } else {
        classOne = "subclass subclass" + event.levels[i].subclass1;
        classesKey = "classes." + i + event.levels[i].tier;
        if (event.levels[i].subclass2 !== null) {
          classTwo = "subclass subclass" + event.levels[i].subclass2;
          classIcons.push(
            <div className="subclasses" key={classesKey}>
              <div className={classOne}></div>
              <div className={classTwo}></div>
            </div>
          );
        } else {
          classIcons.push(
            <div className="subclasses" key={classesKey}>
              <div className={classOne}></div>
            </div>
          );
        }
      }

      levels.push(
        <div className={levelClassName} key={thisKey} data-tier={event.levels[i].tier}>
          <div className="item level-num">{event.levels[i].level}</div>
          {classIcons}
          <div className="chests">{event.levels[i].chests}</div>
          <div className="power">{number_format(event.levels[i].power)}</div>
          <div className="nodes">{number_format(event.levels[i].base)}</div>
          <div className="rift-tokens">{event.levels[i].tokens}</div>
          <div className={"hero-cube-tokens" + fhcStyle}>{event.levels[i].hero_cube_tokens}</div>
        </div>
      );
    }
  }

  // Level tabs
  const levelTabs = [];

  if (typeof event.levels !== 'undefined') {
    if (event.levels[event.levels.length - 1].tier !== null
      && event.levels[event.levels.length - 1].tier !== 1) {
      levelTabs.push(
        <div className="tabs" key="levelTabs">
          <div className="levelTab tab" data-tier="1">Novice</div>
          <div className="levelTab tab" data-tier="2">Adept</div>
          <div className="levelTab tab" data-tier="3">Expert</div>
          <div className="levelTab tab" data-tier="4">Master</div>
          <div className="levelTab tab on" data-tier="5">Grandmaster</div>
        </div>
      );
    }
  }

  // Level tab clicks
  document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.levelTab')) return;

    // Get the tier of the clicked button
    var thisTier = event.target.getAttribute('data-tier');

    // De-active all the tabs
    var tabOn = document.querySelector('.levelTab.on');
    if (tabOn) {
      tabOn.classList.remove('on');
    }

    // Active our current tab
    event.target.classList.add('on');

    // Hide all level tiers
    var levels = document.querySelectorAll('.level');
    if (levels) {
      for (var i = 0; i < levels.length; i++) {
        levels[i].classList.add('hidden');
      }
    }

    // Show tiers that match our button tier
    var showLevels = document.querySelectorAll(".level[data-tier='" + thisTier + "']");
    if (showLevels) {
      for (i = 0; i < showLevels.length; i++) {
        showLevels[i].classList.remove('hidden');
      }
    }

  }, false);

  // Leaderboard Rewards
  const leaderboard = [];

  var maxBracket = "";
  if (typeof event.leaderboard !== 'undefined') {
    Object.keys(event.leaderboard).forEach(function (k) {
      maxBracket = k;
    });

    Object.keys(event.leaderboard).forEach(function (k) {

      Object.keys(event.leaderboard[k]).forEach(function (l) {

        var rewards = [];

        for (var i = 0; i < event.leaderboard[k][l].length; i++) {

          var itemClassName = "item " + event.leaderboard[k][l][i].item_name_safe;
          var theLink = "";

          if (event.leaderboard[k][l][i].item_id.substring(0, 2) === "DA") {
            itemClassName = "hero dna " + event.leaderboard[k][l][i].item_name_safe;
            theLink = "/assassin/" + event.leaderboard[k][l][i].item_id.substring(2) + "/" + event.leaderboard[k][l][i].item_name_safe;
          }
          if (event.leaderboard[k][l][i].item_id.substring(0, 2) === "WI") {
            itemClassName = "weapon " + event.leaderboard[k][l][i].item_name_safe;
            theLink = "/gear/" + event.leaderboard[k][l][i].item_id + "/" + event.leaderboard[k][l][i].item_name_safe;
          }
          if (event.leaderboard[k][l][i].item_id.substring(0, 2) === "GI") {
            itemClassName = "armor " + event.leaderboard[k][l][i].item_name_safe;
            theLink = "/gear/" + event.leaderboard[k][l][i].item_id + "/" + event.leaderboard[k][l][i].item_name_safe;
          }
          var thisKey = k + l + i;

          var thisQuantity = parseInt(event.leaderboard[k][l][i].quantity);
          if (thisQuantity > 999) {
            thisQuantity = (thisQuantity / 1000) + 'k';
          }

          if (theLink !== "") {
            rewards.push(
              <Link to={theLink} key={"link." + thisKey}>
                <div className="itemContainer" key={thisKey}>
                  <div className={itemClassName}></div>
                  <div className="quantity themeLabelX">{thisQuantity}</div>
                </div>
              </Link>
            );
          } else {
            rewards.push(
              <div className="itemContainer" key={thisKey}>
                <div className={itemClassName}></div>
                <div className="quantity themeLabelX">{thisQuantity}</div>
              </div>
            );
          }
        }

        var leaderboardTabClass = "leaderboardTier";
        leaderboardTabClass += (k === maxBracket ? "" : " hidden");

        leaderboard.push(
          <tr className={leaderboardTabClass} key={thisKey} data-bracket={k}>
            <td>Rank {l}</td>
            <td>{rewards}</td>
          </tr>
        );
      });
    });
  }

  // Leaderboard tabs
  const leaderboardTabs = [];
  if (typeof event.leaderboard !== 'undefined') {
    Object.keys(event.leaderboard).forEach(function (k) {
      var leaderboardTabClass = "tab leaderboardTab";
      leaderboardTabClass += (k === maxBracket ? " on" : "");
      var thisKey = "leaderboardTab" + k;
      leaderboardTabs.push(
        <div className={leaderboardTabClass} data-bracket={k} key={thisKey}>{k}</div>
      );
    });
  }

  // Leaderboard tab clicks
  document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.leaderboardTab')) return;

    // Get the tier of the clicked button
    var thisBracket = event.target.getAttribute('data-bracket');

    // De-active all the tabs
    var tabOn = document.querySelector('.leaderboardTab.on');
    if (tabOn) {
      tabOn.classList.remove('on');
    }

    // Active our current tab
    event.target.classList.add('on');

    // Hide all level tiers
    var leaderboardTiers = document.querySelectorAll('.leaderboardTier');
    if (leaderboardTiers) {
      for (var i = 0; i < leaderboardTiers.length; i++) {
        leaderboardTiers[i].classList.add('hidden');
      }
    }

    // Show tiers that match our button tier
    var showTiers = document.querySelectorAll(".leaderboardTier[data-bracket='" + thisBracket + "']");
    if (showTiers) {
      for (i = 0; i < showTiers.length; i++) {
        showTiers[i].classList.remove('hidden');
      }
    }

  }, false);

  // Progression Rewards
  const progression = [];

  if (typeof event.progression !== 'undefined') {
    Object.keys(event.progression).forEach(function (k) {

      var rewards = [];

      for (var l = 0; l < event.progression[k].length; l++) {

        var itemClassName = "item " + event.progression[k][l].item_name_safe;
        var theLink = "";

        if (event.progression[k][l].item_id.substring(0, 2) === "DA") {
          itemClassName = "hero dna " + event.progression[k][l].item_name_safe;
          theLink = "/assassin/" + event.progression[k][l].item_id.substring(2) + "/" + event.progression[k][l].item_name_safe;
        }
        if (event.progression[k][l].item_id.substring(0, 2) === "WI") {
          itemClassName = "weapon " + event.progression[k][l].item_name_safe;
          theLink = "/gear/" + event.progression[k][l].item_id + "/" + event.progression[k][l].item_name_safe;
        }
        if (event.progression[k][l].item_id.substring(0, 2) === "GI") {
          itemClassName = "armor " + event.progression[k][l].item_name_safe;
          theLink = "/gear/" + event.progression[k][l].item_id + "/" + event.progression[k][l].item_name_safe;
        }
        var thisKey = k + l + i;
        var thisQuantity = parseInt(event.progression[k][l].quantity);
        if (thisQuantity > 999) {
          thisQuantity = (thisQuantity / 1000) + 'k';
        }

        if (theLink !== "") {
          rewards.push(
            <Link to={theLink} key={"link." + thisKey}>
              <div className="itemContainer" key={thisKey}>
                <div className={itemClassName}></div>
                <div className="quantity themeLabelX">{thisQuantity}</div>
              </div>
            </Link>
          );
        } else {
          rewards.push(
            <div className="itemContainer" key={thisKey}>
              <div className={itemClassName}></div>
              <div className="quantity themeLabelX">{thisQuantity}</div>
            </div>
          );
        }
      }

      progression.push(
        <div className="progressionTier" key={thisKey} data-bracket={k}>
          <div className="target">{number_format(k)}</div>
          <div className="rewards">{rewards}</div>
        </div>
      );
    });
  }

  // Star Rewards
  const starRewards = [];

  if (typeof event.star_rewards != 'undefined') {

    starRewards.push(<h2 key="starRewards">Star Rewards</h2>);

    Object.keys(event.star_rewards).forEach(function (k) {

      var rewards = [];

      for (var l = 0; l < event.star_rewards[k].length; l++) {

        var itemClassName = "item " + event.star_rewards[k][l].item_name_safe;

        if (event.star_rewards[k][l].item_id.substring(0, 2) === "DA") {
          itemClassName = "hero dna " + event.star_rewards[k][l].item_name_safe;
        }
        if (event.star_rewards[k][l].item_id.substring(0, 2) === "WI") {
          itemClassName = "weapon " + event.star_rewards[k][l].item_name_safe;
        }
        if (event.star_rewards[k][l].item_id.substring(0, 2) === "GI") {
          itemClassName = "armor " + event.star_rewards[k][l].item_name_safe;
        }
        var thisKey = k + l + i;
        var thisQuantity = parseInt(event.star_rewards[k][l].quantity);
        if (thisQuantity > 999) {
          thisQuantity = (thisQuantity / 1000) + 'k';
        }

        rewards.push(
          <div className="itemContainer" key={thisKey}>
            <div className={itemClassName}></div>
            <div className="quantity themeLabelX">{thisQuantity}</div>
          </div>
        );
      }

      starRewards.push(
        <div className="starRewardsTier" key={thisKey} data-bracket={k}>
          <div className="target">{number_format(k)}</div>
          <div className="rewards">{rewards}</div>
        </div>
      );
    });
  }

  var map = [];
  if (event.map_url !== "") {
    map.push(
      <div className="map" key="map"><a href={event.map_url}
        target="_blank" rel="noopener noreferrer">Kiryl's Maps</a></div>
    );
  }

  // Context menu
  const contextMenu = [];
  var prevEventId = parseInt(eventId) - 1;
  var nextEventId = parseInt(eventId) + 1;

  if (typeof events[prevEventId] != 'undefined' && prevEventId > 0) {
    var prevLink = "/helix-rift-event/" + prevEventId + "/" + safe(events[prevEventId - 1].event_name);
    if (safe(events[prevEventId - 1].event_name) === 'abstergo-gauntlet') {
      prevLink = "/abstergo-gauntlet/" + prevEventId;
    }
    contextMenu.push(
      <Link to={prevLink} key="prevEvent">
        <div className="prev">
          <i className="fas fa-angle-double-left"></i>
        </div>
      </Link>
    );
  }
  if (eventId <= currentEventId) {
    var calcLink = "/helix-rift-event-calculator/" + eventId + "/" + safe(events[eventId - 1].event_name);
    contextMenu.push(
      <Link to={calcLink} key="calc">
        <div className="calc">
          <i className="fas fa-calculator"></i>
        </div>
      </Link>
    );
  }
  if (nextEventId <= events.length) {
    var nextLink = "/helix-rift-event/" + nextEventId + "/" + safe(events[nextEventId - 1].event_name);
    if (safe(events[nextEventId - 1].event_name) === 'abstergo-gauntlet') {
      nextLink = "/abstergo-gauntlet/" + nextEventId;
    }
    contextMenu.push(
      <Link to={nextLink} key="nextEvent">
        <div className="next">
          <i className="fas fa-angle-double-right"></i>
        </div>
      </Link>
    );
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div className="eventContainer">

      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Helix Rift Event &#47;&#47; {event.event_name}</title>
      </Helmet>

      <h1>{event.event_name}</h1>
      <h3>{dateRange}</h3>

      <div className="heroes">
        {syncHeroes}
      </div>

      {fhc}

      {levelTabs}

      <div className="levelsContainer">
        {levels}
      </div>

      {map}

      <h2>Leaderboard Rewards</h2>

      <div className="tabs">
        {leaderboardTabs}
      </div>

      <div className="leaderboardContainer">
        <table>
          <tbody>
            {leaderboard}
          </tbody>
        </table>
      </div>

      <h2>Progression Rewards</h2>

      <div className="progressionContainer">
        {progression}
      </div>

      <div className="starRewardsContainer">
        {starRewards}
      </div>

      <div className="contextMenu">
        {contextMenu}
      </div>

      <Coffee />

    </div>
  )
}

export default Event;