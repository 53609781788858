import React from "react"
import { Link } from "react-router-dom"

const HomeArchive = () => {

  return (
    <div className="col">
      <h3>Archive</h3>
      <div className="archive">
        <div className="event freerunner-frenzy"></div>
        <div className="event dps-all-stars"></div>
        <div className="event stealthy-operations"></div>
        <div className="event swift-assassination"></div>
        <div className="event spears-for-hire"></div>
      </div>
      <Link to="/helix-rift-event-archive" key="link.arch">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-archive"></i>
    </div>
  )
}

export default HomeArchive