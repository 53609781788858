const CALC_STATS = "CALC_STATS"

var assassinstats = {
  stars: 1,
  level: 1,
  eliteTrainingUpgrades: {
    health: 0,
    attack: 0,
    defense: 0,
    dodge: 0,
    critChance: 0,
    critDamage: 0,
    lethality: 0,
    dexterity: 0,
    agility: 0
  },
  eliteTraining: {
    health: 0,
    attack: 0,
    defense: 0,
    dodge: 0,
    critChance: 0,
    critDamage: 0,
    lethality: 0,
    dexterity: 0,
    agility: 0
  },
  power: 0
}

function assassinstatsReducer(state = assassinstats, action) {
  if (action.type === CALC_STATS) {
    return {
      ...state,
      level: action.payload.level,
      stars: action.payload.stars,
      eliteTraining: action.payload.eliteTraining,
      eliteTrainingUpgrades: action.payload.eliteTrainingUpgrades,
      power: action.payload.power
    }
  }
  return state
}

export default assassinstatsReducer