import React from "react"
import { Link } from "react-router-dom"

const HomeMaps = () => {

  return (
    <div className="col">
      <h3>Bounty Mission Stats</h3>
      <div className="homeBounties">
        <div className="event spears-for-hire">
          <div className="row">
            <div className="item helix-credits"></div>
            <div className="item invigorating-medicine"></div>
            <div className="item soft-currency"></div>
          </div>
        </div>
      </div>
      <Link to="/info/bounties" key="link.calc">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-chart-bar"></i>
    </div>
  )
}

export default HomeMaps