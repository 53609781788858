import React from "react"
import { Link } from "react-router-dom"
import { getRandom } from "../Utils.js"

const HomeT5Armor = () => {

  const armor = [
    'enduring-plate',
    'wartooth-splintmail',
    'lighthawk-mail',
    'stoic-armor',
    'slayers-coat',
    'swift-eagle-armor'
  ]

  var randomThree = getRandom(armor, 3);

  return (
    <div className="col">
      <h3>Tier 5 Armor</h3>
      <div className="t5armor">
        <div className="gearContainer">
          <div className={"armor " + randomThree[0]}></div>
        </div>
        <div className="gearContainer">
          <div className={"armor " + randomThree[1]}></div>
        </div>
        <div className="gearContainer">
          <div className={"armor " + randomThree[2]}></div>
        </div>
      </div>
      <Link to="/info/t5-armor" key="link.calc">
        <div className="button">View <i className="fas fa-angle-double-right"></i></div>
      </Link>
      <i className="bgi fas fa-helmet-battle"></i>
    </div>
  )
}

export default HomeT5Armor