import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
import { gd, ordinal } from "../Utils.js"
import Coffee from "../components/Coffee"
import Holding from "../components/Holding"
import { apiConfig, safe } from "../Utils.js"

const axios = require('axios');

const Challenge = () => {

  // Get our params
  const params = useParams();
  const challengeId = parseInt(params.id);

  // Set up our loading
  const [isLoading, setLoading] = useState(true);
  const [challenges, setChallenges] = useState([]);
  const [challenge, setChallenge] = useState({});

  useEffect(() => {
    const apiChallenges = axios.get(`${process.env.REACT_APP_API_URL}api/challenges`, apiConfig);
    const apiChallenge = axios.get(`${process.env.REACT_APP_API_URL}api/challenge/${challengeId}`, apiConfig);
    setLoading(true);
    axios.all([apiChallenges, apiChallenge])
      .then(axios.spread((...responses) => {
        setChallenges(responses[0].data);
        setChallenge(responses[1].data);
        setLoading(false);
      })).catch(errors => {
        // react on errors.
      });
  }, [challengeId]);

  // Sort out our date range
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Challenge Tiers
  const tiers = [];

  if (typeof challenge.start_date !== 'undefined') {

    var dStart = gd(challenge.start_date);
    var dEnd = gd(challenge.end_date);
    var dateRange = monthNames[dStart.getMonth()] + " " +
      dStart.getDate() + ordinal(dStart.getDate()) + " - " +
      monthNames[dEnd.getMonth()] + " " +
      dEnd.getDate() + ordinal(dEnd.getDate());

    Object.keys(challenge.tiers).forEach(function (k) {

      Object.keys(challenge.tiers[k]).forEach(function (l) {

        var rewards = [];

        for (var i = 0; i < challenge.tiers[k][l]['rewards'].length; i++) {

          var itemClassName = "item " + safe(challenge.tiers[k][l]['rewards'][i].item_name);

          if (challenge.tiers[k][l]['rewards'][i].item_id.substring(0, 2) === "DA") {
            itemClassName = "hero dna " + safe(challenge.tiers[k][l]['rewards'][i].item_name);
          }
          if (challenge.tiers[k][l]['rewards'][i].item_id.substring(0, 2) === "WI") {
            itemClassName = "weapon " + safe(challenge.tiers[k][l]['rewards'][i].item_name);
          }
          if (challenge.tiers[k][l]['rewards'][i].item_id.substring(0, 2) === "GI") {
            itemClassName = "armor " + safe(challenge.tiers[k][l]['rewards'][i].item_name);
          }
          var thisKey = k + l + i;

          var thisQuantity = parseInt(challenge.tiers[k][l]['rewards'][i].quantity);
          if (thisQuantity > 999) {
            thisQuantity = (thisQuantity / 1000) + 'k';
          }

          rewards.push(
            <div className="itemContainer" key={thisKey}>
              <div className={itemClassName}></div>
              <div className="quantity themeLabelX">{thisQuantity}</div>
            </div>
          );
        }

        var tierTabClass = "";
        if (k === "41 - 50" || k === "31 - 39") {
          tierTabClass = "challengeTier";
        } else {
          tierTabClass = "challengeTier hidden";
        }

        tiers.push(
          <tr className={tierTabClass} key={thisKey} data-bracket={k}>
            <td>{challenge.tiers[k][l]['description']}</td>
            <td>{rewards}</td>
          </tr>
        );
      });
    });
  }

  // Tier tabs
  const tierTabs = [];

  if (typeof challenge !== 'undefined' && typeof challenge.tiers !== 'undefined') {
    Object.keys(challenge.tiers).forEach(function (k) {
      var tierTabClass = "";
      if (k === "41 - 50" || k === "31 - 39") {
        tierTabClass = "tab tierTab on";
      } else {
        tierTabClass = "tab tierTab";
      }
      var thisKey = "tierTab" + k;
      tierTabs.push(
        <div className={tierTabClass} data-bracket={k} key={thisKey}>{k}</div>
      );
    });

    // Tier tab clicks
    document.addEventListener('click', function (event) {

      // If the clicked element doesn't have the right selector, bail
      if (!event.target.matches('.tierTab')) return;

      // Get the tier of the clicked button
      var thisBracket = event.target.getAttribute('data-bracket');

      // De-active all the tabs
      var tabOn = document.querySelector('.tierTab.on');
      if (tabOn) {
        tabOn.classList.remove('on');
      }

      // Active our current tab
      event.target.classList.add('on');

      // Hide all level tiers
      var challengeTiers = document.querySelectorAll('.challengeTier');
      if (challengeTiers) {
        for (var i = 0; i < challengeTiers.length; i++) {
          challengeTiers[i].classList.add('hidden');
        }
      }

      // Show tiers that match our button tier
      var showTiers = document.querySelectorAll(".challengeTier[data-bracket='" + thisBracket + "']");
      if (showTiers) {
        for (i = 0; i < showTiers.length; i++) {
          showTiers[i].classList.remove('hidden');
        }
      }

    }, false);

  }

  // Context menu
  const contextMenu = [];
  var prevChallengeId = parseInt(challengeId) - 1;
  var nextChallengeId = parseInt(challengeId) + 1;

  if (challenges !== false && typeof challenges[0] !== 'undefined') {
    if (challengeId > 1) {
      var prevLink = "/animus-challenge/" + prevChallengeId + "/" + challenges[prevChallengeId - 1].challenge_title_safe;
      contextMenu.push(
        <Link to={prevLink} key="prevEvent">
          <div className="prev">
            <i className="fas fa-angle-double-left"></i>
          </div>
        </Link>
      );
    }
    if (nextChallengeId <= challenges.length) {
      var nextLink = "/animus-challenge/" + nextChallengeId + "/" + challenges[nextChallengeId - 1].challenge_title_safe;
      contextMenu.push(
        <Link to={nextLink} key="nextEvent">
          <div className="next">
            <i className="fas fa-angle-double-right"></i>
          </div>
        </Link>
      );
    }
  }

  if (isLoading) {
    return (
      <Holding />
    )
  }

  return (
    <div className="challengeContainer">
      <Helmet>
        <title>Assassin's Creed Rebellion &#47;&#47; Animus Challenge &#47;&#47; {challenge.challenge_title}</title>
      </Helmet>

      <h1>{challenge.challenge_title}</h1>
      <h3>{dateRange}</h3>

      <div className="tabs">
        {tierTabs}
      </div>

      <div className="challengeTiersContainer">
        <table>
          <tbody>
            {tiers}
          </tbody>
        </table>
      </div>

      <div className="contextMenu">
        {contextMenu}
      </div>
      <Coffee />
    </div>
  );
  //}
}

export default Challenge;