import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { copyToClipboard } from "../Utils.js"
import Coffee from "../components/Coffee"

const FAQ = () => {

    const fullUrl = "https://animus.mobi/faq/"
    var params = useParams();

    if (!params.ref) {
        params.ref = "top";
    }

    const myRef = useRef(null);
    const noRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();

    useEffect(executeScroll);

    return (
        <div className="faqContainer">

            <Helmet>
                <title>Assassin's Creed Rebellion &#47;&#47; Frequently Asked Questions</title>
            </Helmet>

            <h1 ref={params.ref === "top" ? myRef : noRef}>Frequently Asked Questions</h1>

            <h2>HQ</h2>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "what-happens-to-level-up-objective" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "what-happens-to-level-up-objective")}>
                    If I fully level up all my heroes what happens to the daily objective?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>The objective will no longer show up for you, but it won't impede
                        your ability to "Complete All Objectives". You'll simply need
                        to complete 10 tasks instead of 11 to claim the reward.</p>

                </div>
            </div>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "what-can-i-do-with-empty-rooms" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "what-can-i-do-with-empty-rooms")}>
                    What can I do with the empty rooms in the HQ?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>Not a lot. Currently if you have all three wings unlocked and you've
                        built every room you'll have 5 remaining empty rooms. Let's hope
                        they will find a purpose in a future update!</p>

                </div>
            </div>

            <h2>Heroes</h2>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "dna-needed-to-rank-up-heroes" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "dna-needed-to-rank-up-heroes")}>
                    How much DNA does it take to rank up a hero?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>Heroes take different amounts of DNA to rank up, based on their
                        rarity. The amounts are as follows:</p>

                    <div className="wideBox">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>1</th>
                                    <th>2</th>
                                    <th>3</th>
                                    <th>4</th>
                                    <th>5</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Common</td>
                                    <td>10</td>
                                    <td>25</td>
                                    <td>45</td>
                                    <td>80</td>
                                    <td>115</td>
                                    <td>275</td>
                                </tr>
                                <tr>
                                    <td>Rare</td>
                                    <td>15</td>
                                    <td>30</td>
                                    <td>50</td>
                                    <td>85</td>
                                    <td>120</td>
                                    <td>300</td>
                                </tr>
                                <tr>
                                    <td>Epic</td>
                                    <td>20</td>
                                    <td>35</td>
                                    <td>55</td>
                                    <td>90</td>
                                    <td>125</td>
                                    <td>325</td>
                                </tr>
                                <tr>
                                    <td>Legendary</td>
                                    <td>25</td>
                                    <td>40</td>
                                    <td>60</td>
                                    <td>95</td>
                                    <td>130</td>
                                    <td>350</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "how-much-does-elite-training-cost" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "how-much-does-elite-training-cost")}>
                    How much does elite training cost?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>Elite training costs the following for each upgrade to one skill:</p>

                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>50,000</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>85,000</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>150,000</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>275,000</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>500,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>1,000,000</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>4,000,000</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>8,500,000</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>15,000,000</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>31,560,000</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>So only 284,040,000 coins to fully upgrade a hero! Bargain!</p>
                </div>
            </div>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "who-are-the-best-heroes" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "who-are-the-best-heroes")}>
                    Who are the best heroes?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>This one is of course subjective, but I've put
                        together a tier list which should give you a
                        good idea: <a href="/assassin-tier-list">assassin tier list</a>.</p>

                </div>
            </div>

            <h2>Helix</h2>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "what-should-i-spend-helix-on" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "what-should-i-spend-helix-on")}>
                    What should I spend my helix on?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>If you're new to the game then you may want to buy one or two
                        of the 1,800 helix DNA cube packs to jump-start the building of
                        your roster. From that point on it's generally considered wiser
                        to save your helix for buying refills to improve your position
                        in helix rift events. There is also the option of spending your helix
                        on helix-exclusive heroes like Male Eivor, but this will be a
                        very long grind.</p>

                </div>
            </div>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "should-i-buy-event-dna-packs" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "should-i-buy-event-dna-packs")}>
                    Should I buy the event DNA packs?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>I would generally advise against it. It can be tempting if 3-4 of the
                        legendaries/epics in the cubes are event-exclusive, but you have to
                        remember that the majority of the DNA you'll receive will be for
                        farmable rare heroes.</p>

                </div>
            </div>

            <h2>Events</h2>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "how-does-sync-bonus-work" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "how-does-sync-bonus-work")}>
                    How does sync bonus work?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>Heroes give you a big score boost based on their rarity, and rank.
                        The percentage of bonus you get from a hero is as follows:</p>

                    <div className="wideBox">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>1</th>
                                    <th>2</th>
                                    <th>3</th>
                                    <th>4</th>
                                    <th>5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Common</td>
                                    <td>25%</td>
                                    <td>50%</td>
                                    <td>75%</td>
                                    <td>100%</td>
                                    <td>125%</td>
                                </tr>
                                <tr>
                                    <td>Rare</td>
                                    <td>50%</td>
                                    <td>75%</td>
                                    <td>100%</td>
                                    <td>125%</td>
                                    <td>150%</td>
                                </tr>
                                <tr>
                                    <td>Epic</td>
                                    <td>75%</td>
                                    <td>100%</td>
                                    <td>125%</td>
                                    <td>150%</td>
                                    <td>175%</td>
                                </tr>
                                <tr>
                                    <td>Legendary</td>
                                    <td>100%</td>
                                    <td>125%</td>
                                    <td>150%</td>
                                    <td>175%</td>
                                    <td>200%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "which-mission-should-i-run" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "which-mission-should-i-run")}>
                    Which mission is the best to run?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>The most efficent mission to run based on nodes/token will depend
                        on your roster. I suggest heading over to
                        the <a href="/helix-rift-event-calculator">calculator</a> and
                        putting your heroes in to get an idea of what mission is best to farm.</p>

                </div>
            </div>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "are-star-rewards-worth-it" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "are-star-rewards-worth-it")}>
                    Are star rewards worth going for?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>If you need a rare material or an extra ancient tablet then you
                        may want to go for all of the star rewards. If you're interested
                        in featured hero tokens it's also slightly more beneficial to grab
                        the star rewards. However, if you're
                        only interested in the helix rewards then you'll probably spend
                        more trying to get them then you'll actually be rewarded. Going
                        for star rewards will also lower your node score compared to
                        farming the most efficient mission.</p>

                </div>
            </div>

            <div className="faq">
                <div className="q"
                    ref={params.ref === "what-happens-to-unused-fht" ? myRef : noRef}
                    onClick={() => copyToClipboard(fullUrl + "what-happens-to-unused-fht")}>
                    What happens to my unused featured hero tokens?
                    <i className="fas fa-link"></i>
                </div>
                <div className="a">

                    <p>Featured hero tokens are bound to the hero featured in the cube.
                        This means you won't be able to use them on other heroes, but you'll
                        be able to use them the next time the same hero cube rolls around.</p>

                </div>
            </div>

            <div className="copy">
                Copied to clipboard!
                <input type="text" />
            </div>

            <Coffee />

        </div>

    )
}

export default FAQ